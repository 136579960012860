import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { BrowserRouter, Route, Switch, Redirect,Link } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import { withSnackbar  } from 'notistack';
import InputError from '../../elements/input_error';
import Loader from '../../elements/loader';
import Config from '../../config/index';
import moment from 'moment';

const PaymentStatus = [
    { value: '1', label: 'In Progress' },
    { value: '2', label: 'Paid' },
    { value: '3', label: 'UnPaid' },
]
const paidThrough = [
    { value: 'paypal', label: 'Paypal'},
    { value: 'eway', label: 'Eway'},
    { value: 'stripe', label: 'Stripe' },
]
const axios = require('axios');
axios.defaults.headers.common['Authorization'] = localStorage.getItem('user_token');

class SubscriptionEdit extends Component {
    constructor(props) {
        super(props); 
        this.state={ 
            data:[], 
            isLoading:true,
            validationErrors: {},
            isSaveInProgress: false, 
            redirect: false,
            SubscriptionData:{},
            selectedStatus:"",
        };
        this.studentLimitInputRef = React.createRef();
        this.startDateInputRef = React.createRef();
        this.endDateInputRef = React.createRef();
        this.amountInputRef = React.createRef();
        this.paidOnInputRef = React.createRef();
        this.transactionIdInputRef = React.createRef();
        this.submitForm = this.submitForm.bind(this);
        this.cancelForm = this.cancelForm.bind(this); 
       
    }
    componentDidMount = () => {
        let search = window.location.search;
        let getValue = new URLSearchParams(search);
        this.state.keyWord = getValue.get('keyword');
        this.state.SubscriptionData=this.props.location.data;
        this.setState({
            selectedStatus:this.state.SubscriptionData.payment_status,
            isLoading:false
        });
        if(getValue.get('keyword') !== "core"){
            this.state.freeType=<MenuItem value="free">Free</MenuItem>
        }
    }
    handleSubscribedTo = selectedSubscriptionTo => {
        this.setState({ selectedSubscriptionTo });
    };
    cancelForm = () => {
        this.setState({   
            redirect: true,
        });
    }
    handleStatus=(event)=>{
        this.setState({
            selectedStatus:event.target.value
        })
    }
    render(){
        this.state.SubscriptionData.start_date=moment(this.state.SubscriptionData.start_date).format("YYYY-MM-DD");
        this.state.SubscriptionData.end_date=moment(this.state.SubscriptionData.end_date).format("YYYY-MM-DD");
        const { selectedStatus } = this.state;
        const { redirect } = this.state;
        if (redirect) {
            let url="/admin/subscription_details/"+ this.props.match.params.id
            return <Redirect to={url} />;
        }
        let overlayLoader;
        if(this.state.isSaveInProgress == true){
            overlayLoader=  <div className="loading"> 
                                <Loader type='block' /> 
                            </div>
        }
        let dataTable;                                     
        if ( this.state.isLoading ) {
            dataTable = <Loader type='block'/>;
        }else{
            dataTable=<div style={{maxWidth:600}}>
                        <form method="POST" onSubmit={this.submitForm}> 
                            <TextField
                                style={{marginTop:6}} 
                                inputProps={{
                                    ref: this.startDateInputRef
                                }}
                                id="date"
                                label="Start Date"
                                type="date"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                defaultValue={this.state.SubscriptionData.start_date}
                            ></TextField>
                            <InputError field="start_date" errors={((this.state.validationErrors['start_date'] != undefined) ? ((this.state.validationErrors['start_date'] == "required") ? {"start_date":"Start date is required."} : {"start_date":"Start date is invalid."}) : (this.state.validationErrors))} />
                            <TextField 
                                inputProps={{
                                    ref: this.endDateInputRef
                                }}
                                id="date"
                                label="End Date"
                                type="date"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                defaultValue={this.state.SubscriptionData.end_date}
                            ></TextField>
                            <InputError field="end_date" errors={((this.state.validationErrors['end_date'] != undefined) ? ((this.state.validationErrors['end_date'] == "required") ? {"end_date":"End date is required."} : {"end_date":"End date is invalid."}) : (this.state.validationErrors))} />
                            {
                            (this.state.keyWord == "core") 
                                ? 
                                    (<div>
                                        <TextField
                                            inputProps={{
                                                ref: this.studentLimitInputRef
                                            }}
                                            id="studentLimit"
                                            label="Student Limit"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            defaultValue={this.state.SubscriptionData.student_limit}
                                        ></TextField> 
                                        <InputError field="max" errors={(this.state.validationErrors['max'] != undefined) ? {"max":"Student limit is required."}: this.state.validationErrors} />
                                    </div>)
                                :
                                    (<div></div>)
                            }
                            <TextField
                                inputProps={{
                                    ref: this.amountInputRef
                                }}
                                id="amount"
                                label="Amount"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                defaultValue={this.state.SubscriptionData.payment_amount}
                            />
                            <InputError field="payment_amount" errors={(this.state.validationErrors['payment_amount'] != undefined) ? {"payment_amount":"Payment amount is required."}: this.state.validationErrors} />
                            <FormControl variant="outlined" fullWidth style={{marginTop:14}}>
                                <InputLabel id="payment-outlined-label">Payment Status</InputLabel>
                                <Select
                                    labelId="payment-select-label"
                                    id="demo-simple-select"
                                    value={selectedStatus}
                                    onChange={this.handleStatus}
                                    label="Payment Status"
                                    >
                                    <MenuItem value="1">In Progress</MenuItem>
                                    <MenuItem value="2">Paid</MenuItem>
                                    <MenuItem value="3">UnPaid</MenuItem>
                                </Select>
                            </FormControl>
                            <InputError field="payment_status" errors={(this.state.validationErrors['payment_status'] != undefined) ? {"payment_status":"Payment status is required."}: this.state.validationErrors} /><br/>
                            <div>
                                <Button variant="contained" size="medium" color="primary" type="submit" >
                                    Submit
                                </Button>&nbsp;&nbsp;
                                <Button variant="contained" size="medium" color="defualt" type="Cancel" onClick={this.cancelForm} >
                                    Cancel
                                </Button>
                                {overlayLoader}
                            </div>
                        </form>
                    </div>
        }
        return(
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="h5" gutterBottom>
                            Edit Subscription Details
                        </Typography>        
                    </Grid>
                </Grid>
                {dataTable}
            </div>
        );
    }
    submitForm(e){
        e.preventDefault();
        const selfObj=this;    
        if(this.startDateInputRef.current.value > this.endDateInputRef.current.value){
            selfObj.props.enqueueSnackbar('Please select end date larger than start date.', {
                variant: 'error',
            });
        }else{
            selfObj.setState({
                isSaveInProgress: true
            });
            let startDate,endDate;
            if(this.startDateInputRef.current.value < this.endDateInputRef.current.value){
                startDate = this.startDateInputRef.current.value;
                endDate=this.endDateInputRef.current.value;
                this.state.date=false
            }
        
            const status= this.state.selectedStatus;
            const amount= this.amountInputRef.current.value;
            let data={};
            if(this.state.keyWord == "core"){
                const studentLimit=this.studentLimitInputRef.current.value;
                data={
                    "module_type":this.state.keyWord,
                    "student_limit":studentLimit,
                    "rec_id":this.state.SubscriptionData.id,
                    "start_date": startDate,
                    "end_date": endDate,
                    "amount": amount,
                    "payment_status": status,
                }
            }else{
                data={
                    "module_type":"addon",
                    "keyword": this.state.keyWord,
                    "rec_id":this.state.SubscriptionData.id,
                    "start_date": startDate,
                    "end_date": endDate,
                    "amount": amount,
                    "payment_status": status,
                }
            }
            axios.post(Config.apiBaseURL +"/tenant/save_module_subscription_details/" +this.props.match.params.id, data)
            .then( response => {
                if(response.status === 200){
                    if(response.data.success == 0){
                        selfObj.setState({
                            validationErrors:response.data.field_validations,
                        });
                        selfObj.props.enqueueSnackbar('Please double check all form entries.', {
                            variant: 'error',
                        });
                    }
                    if(response.data.success == 1){
                        selfObj.props.enqueueSnackbar('Subscription record updated successfully.', {
                            variant: 'success',
                        });
                        selfObj.setState({
                            validationErrors:[],
                            redirect:true,
                        });
                    }
                } 
            })
            .catch(function (error){
                if (error.response && error.response.status === 400) {
                    selfObj.props.enqueueSnackbar('Please double check all form entries.', {
                        variant: 'error',
                    });
                    selfObj.setState({
                        validationErrors: error.response.data.errors
                    }); 
                }
            
            })
            .then(function () {
                selfObj.setState({
                    isSaveInProgress: false
                });
            });
        }
    } 
}
export default withSnackbar(SubscriptionEdit);