import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Loader from '../../elements/loader';
import Config from '../../config/index';
import {Link} from "react-router-dom";
import { withSnackbar  } from 'notistack';
import Typography from '@material-ui/core/Typography';
import './dashboard.css';
import PeopleIcon from '@material-ui/icons/People';
import Chart from "react-apexcharts";
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import { withRouter } from "react-router";
import { Box, FormControl, MenuItem, Select } from '@material-ui/core';
const axios = require('axios');
axios.defaults.headers.common['Authorization'] = localStorage.getItem('user_token');

class dashboard extends Component {
    constructor(props) {
        super(props); 
        this.state={
            count:[],
            isLoading:true,
            monthName:[],
            studentsData:{},
            studentsDataLoading:true,
            modulesSubDataLoading:true,
            modulesData:{},
            upcomingRenewalsLoading:true,
            upcomingRenewalsData:true,
            failedRenewalsLoading:true,
            failedRenewalsData:true,
            selectedRegGraphType:"week",
            selectedCancelGraphType:"week",
            regGraphData : [],
            cancelGraphData : [],
        };
    }    
    noOfStudents = () => {
        let self = this;
        self.setState({
            studentsDataLoading:true
        })
        axios.get(Config.apiBaseURL + '/no_of_students')
        .then( response => {
            this.setState({
                studentsData:response.data,
            });
        })
        .catch( function (error) {
            if(error.response && error.response.status === 500) {
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }else{ // any other error 
                self.props.enqueueSnackbar('Error! While displaying students data.', {
                    variant: 'error',
                });
            }
        })
        .then(function (){
            self.setState({
                studentsDataLoading:false
            })
        })
    }
    modulesData = () => {
        let self = this;
        self.setState({
            modulesSubDataLoading:true
        })
        axios.get(Config.apiBaseURL + '/modules_data')
        .then( response => {
            this.setState({
                modulesData:response.data,
            });
        })
        .catch( function (error) {
            if(error.response && error.response.status === 500) {
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }else{ // any other error 
                self.props.enqueueSnackbar('Error! While loading modules data.', {
                    variant: 'error',
                });
            }
        })
        .then(function (){
            self.setState({
                modulesSubDataLoading:false
            })
        })
    }
    loadUpcomingRenewals = () => {
        let self = this;
        self.setState({
            upcomingRenewalsLoading:true
        })
        axios.get(Config.apiBaseURL + '/tenants/upcoming_renewals')
        .then( response => {
            this.setState({
                upcomingRenewalsData:response.data,
            });
        })
        .catch( function (error) {
            if(error.response && error.response.status === 500) {
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }else{ // any other error
                self.props.enqueueSnackbar('Error! While loading upcoming renewals data.', {
                    variant: 'error',
                });
            }
        })
        .then(function (){
            self.setState({
                upcomingRenewalsLoading:false
            })
        })
    }
    loadFailedRenewals = () => {
        let self = this;
        self.setState({
            failedRenewalsLoading:true
        })
        axios.get(Config.apiBaseURL + '/tenants/failed_renewals')
        .then( response => {
            this.setState({
                failedRenewalsData:response.data,
            });
        })
        .catch( function (error) {
            if(error.response && error.response.status === 500) {
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }else{ // any other error
                self.props.enqueueSnackbar('Error! While loading failed renewals data.', {
                    variant: 'error',
                });
            }
        })
        .then(function (){
            self.setState({
                failedRenewalsLoading:false
            })
        })
    }
    loadStatisticsData = (type,statFor) => {
        let self=this;
        axios.post(Config.apiBaseURL + '/tenants/statistics',{"type":type})
        .then( response => {
            if(statFor == null){
                this.setState({                
                    count:response.data,
                    regGraphData:response.data,
                    cancelGraphData:response.data,
                    selectedRegGraphType:type,
                    selectedCancelGraphType:type,
                });
            }else if(statFor == 'reg'){
                this.setState({                
                    regGraphData:response.data,
                    selectedRegGraphType:type
                });
            }else if(statFor == 'cancel'){
                this.setState({                
                    cancelGraphData:response.data,
                    selectedCancelGraphType:type
                });
            }
        })
        .catch(function (error) {
            if(error.response && error.response.status === 500) {                   
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }
            else { // any other error 
                    self.props.enqueueSnackbar('Error! While loading count data.', {
                    variant: 'error',
                });
            }
        })
        .then(function () {
                self.setState({
                   isLoading:false
               })
			});
    }
    viewMoreUpcomingRenewals = () => {
        localStorage.setItem('requested_for', 'upcoming_renewals');
        window.location = '/admin/customers';
    }
    viewMoreFailedRenewals = () => {
        localStorage.setItem('requested_for', 'failed_renewals');
        window.location = '/admin/customers';
    }
    componentDidMount(){       
        this.loadStatisticsData(this.state.selectedRegGraphType,null);
        this.noOfStudents();
        this.modulesData();
        this.loadUpcomingRenewals();
        this.loadFailedRenewals();
    }
    regGraphTypeHandle = (event) => {        
        this.loadStatisticsData(event.target.value,'reg');
    }
    cancelGraphTypeHandle = (event) => {        
        this.loadStatisticsData(event.target.value,'cancel');
    }
    render(){
        let monthName=[],regCount=[],paidCustomersCount=[],freeTrialCustomersCount=[];
        let graphData = [];
        if(this.state.selectedRegGraphType == 'week'){
            if(this.state.regGraphData.thisWeeklyCount != undefined){
                this.state.regGraphData.thisWeeklyCount.sort((a, b) => b.day > a.day ? -1 : 1);
            }
            if(this.state.regGraphData.thisWeeklyCount != undefined){
                this.state.regGraphData.thisWeeklyCount.sort((a, b) => b.month > a.month ? -1 : 1);
            }
            if(this.state.regGraphData.thisWeeklyCount != undefined){
                this.state.regGraphData.thisWeeklyCount.sort((a, b) => b.year > a.year ? -1 : 1);
            }
            graphData = this.state.regGraphData.thisWeeklyCount
        }else if(this.state.selectedRegGraphType == 'month'){
            if(this.state.regGraphData.thisMonthlyCount != undefined){
                this.state.regGraphData.thisMonthlyCount.sort((a, b) => b.day > a.day ? -1 : 1);
            }
            if(this.state.regGraphData.thisMonthlyCount != undefined){
                this.state.regGraphData.thisMonthlyCount.sort((a, b) => b.month > a.month ? -1 : 1);
            }
            if(this.state.regGraphData.thisMonthlyCount != undefined){
                this.state.regGraphData.thisMonthlyCount.sort((a, b) => b.year > a.year ? -1 : 1);
            }            
            graphData = this.state.regGraphData.thisMonthlyCount
        }else if(this.state.selectedRegGraphType == 'half-year' || this.state.selectedRegGraphType == 'year'){
            if(this.state.regGraphData.thisYearRegCount != undefined){
                this.state.regGraphData.thisYearRegCount.sort((a, b) => b.month > a.month ? -1 : 1);
            }
            if(this.state.regGraphData.thisYearRegCount != undefined){
                this.state.regGraphData.thisYearRegCount.sort((a, b) => b.year > a.year ? -1 : 1);
            }
            graphData = this.state.regGraphData.thisYearRegCount
        }                
        for(var key in graphData){
            if(this.state.selectedRegGraphType == 'week'){
                let data = (moment().date(graphData[key].day).format('DD'))+" "+(moment().month(graphData[key].month-1).format('MMM'))
                monthName.push(data);
            }else if(this.state.selectedRegGraphType == 'month'){
                let startDay = moment().date(graphData[key].day - 7).date()
                let startMonth = moment().month(graphData[key].month - 1).format("MMM")
                if(startDay > graphData[key].day){
                    startMonth = moment().month(graphData[key].month - 2).format("MMM")
                }
                let data = startDay+" "+startMonth+"-"+(moment().date(graphData[key].day).format('D'))+" "+(moment().month(graphData[key].month-1).format('MMM'))
                monthName.push(data);
            }else if(this.state.selectedRegGraphType == 'half-year' || this.state.selectedRegGraphType == 'year'){
                let monthNumber=graphData[key].month - 1
                let data=moment().month(monthNumber).format("MMM")
                monthName.push(data);
            } 
            regCount.push(graphData[key].count)
            paidCustomersCount.push(graphData[key].paid_customers_count)
            freeTrialCustomersCount.push(graphData[key].free_trial_customers_count)           
        }
        let series= [{
            name: "Registrations",
            data: regCount
        },
        {
            name: "Free Trial Customers",
            data: freeTrialCustomersCount
        },
        {
            name: "Paid Customers",
            data: paidCustomersCount
        }
        ];
        let options= {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            title: {
                align: 'left'
            },
            grid: {
                row: {},
            },
            xaxis: {                
                categories: monthName,
            },
        };        
        let cancelledCustomersCount=[],cancelMonthName=[];
        if(this.state.selectedCancelGraphType == 'week'){
            if(this.state.cancelGraphData.thisWeeklyCount != undefined){
                this.state.cancelGraphData.thisWeeklyCount.sort((a, b) => b.day > a.day ? -1 : 1);
            }
            if(this.state.cancelGraphData.thisWeeklyCount != undefined){
                this.state.cancelGraphData.thisWeeklyCount.sort((a, b) => b.month > a.month ? -1 : 1);
            }
            if(this.state.cancelGraphData.thisWeeklyCount != undefined){
                this.state.cancelGraphData.thisWeeklyCount.sort((a, b) => b.year > a.year ? -1 : 1);
            }
            graphData = this.state.cancelGraphData.thisWeeklyCount
        }else if(this.state.selectedCancelGraphType == 'month'){
            if(this.state.cancelGraphData.thisMonthlyCount != undefined){
                this.state.cancelGraphData.thisMonthlyCount.sort((a, b) => b.day > a.day ? -1 : 1);
            }
            if(this.state.cancelGraphData.thisMonthlyCount != undefined){
                this.state.cancelGraphData.thisMonthlyCount.sort((a, b) => b.month > a.month ? -1 : 1);
            }  
            if(this.state.cancelGraphData.thisMonthlyCount != undefined){
                this.state.cancelGraphData.thisMonthlyCount.sort((a, b) => b.year > a.year ? -1 : 1);
            }          
            graphData = this.state.cancelGraphData.thisMonthlyCount
        }else if(this.state.selectedCancelGraphType == 'half-year' || this.state.selectedCancelGraphType == 'year'){
            if(this.state.cancelGraphData.thisYearRegCount != undefined){
                this.state.cancelGraphData.thisYearRegCount.sort((a, b) => b.month > a.month ? -1 : 1);
            }
            if(this.state.cancelGraphData.thisYearRegCount != undefined){
                this.state.cancelGraphData.thisYearRegCount.sort((a, b) => b.year > a.year ? -1 : 1);
            }
            graphData = this.state.cancelGraphData.thisYearRegCount
        }                
        for(var key in graphData){
            if(this.state.selectedCancelGraphType == 'week'){
                let data = (moment().date(graphData[key].day).format('DD'))+" "+(moment().month(graphData[key].month-1).format('MMM'))
                cancelMonthName.push(data);
            }else if(this.state.selectedCancelGraphType == 'month'){
                let startDay = moment().date(graphData[key].day - 7).date()
                let startMonth = moment().month(graphData[key].month - 1).format("MMM")
                if(startDay > graphData[key].day){
                    startMonth = moment().month(graphData[key].month - 2).format("MMM")
                }
                let data = startDay+" "+startMonth+"-"+(moment().date(graphData[key].day).format('D'))+" "+(moment().month(graphData[key].month-1).format('MMM'))
                cancelMonthName.push(data);
            }else if(this.state.selectedCancelGraphType == 'half-year' || this.state.selectedCancelGraphType == 'year'){
                let monthNumber=graphData[key].month - 1
                let data=moment().month(monthNumber).format("MMM")
                cancelMonthName.push(data);
            }             
            cancelledCustomersCount.push(graphData[key].cancel_customers_count)         
        }
        let canceledCusGraph = [{
            name: "Cancelled Customers",
            data: cancelledCustomersCount
        }];
        let cancelOptions= {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            title: {
                align: 'left'
            },
            grid: {
                row: {},
            },
            xaxis: {
                categories: cancelMonthName,
            },
        };
        
        let pieChartSeries = this.state.studentsData;
        let pieChartOptions = {
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: ['0-100', '101-250', '251-500', '501-1000', '1000+'],
            dataLabels: {
                formatter: function (val, opts) {
                    return opts.w.config.series[opts.seriesIndex]
                },
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            colors:['#6772e5','#A5FDCA','#FFE92C','#f25757','#7c7c7c'],
            tooltip: {
                custom: function({ series, seriesIndex, dataPointIndex, w }) {
                    if(seriesIndex == 0){
                        return "<div>" + pieChartSeries[0]+" Tenants with students range "+ w.config.labels[0] + "</div>";
                    }else if(seriesIndex == 1){
                        return "<div>" + pieChartSeries[1]+" Tenants with students range "+ w.config.labels[1] + "</div>";
                    }else if(seriesIndex == 2){
                        return "<div>" + pieChartSeries[2]+" Tenants with students range "+ w.config.labels[2] + "</div>";
                    }else if(seriesIndex == 3){
                        return "<div>" + pieChartSeries[3]+" Tenants with students range "+ w.config.labels[3] + "</div>";
                    }else if(seriesIndex == 4){
                        return "<div>" + pieChartSeries[4]+" Tenants with students range "+ w.config.labels[4] + "</div>";
                    }
                }
            },
        };

        let dashboardData;
        let studentsData;
        let modulesData;
        let newRegistrationData;
        let upcomingRenewalsData = [];
        let failedRenewalsData = [];
        let cancelledData;
        if(this.state.isLoading){
            dashboardData=<Loader type='block' /> 
        }else{ 
            dashboardData=<Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <Link to="/admin/customers" className="Link">
                                        <Paper className='paper'>
                                            <div style={{color:"#1976d2",fontWeight:"bold"}}>This Week Registrations</div> 
                                            <div>
                                                <div><PeopleIcon className='icon' style={{color:"#1976d2"}}></PeopleIcon></div>  
                                                <div className="countData">
                                                    <div style={{fontSize:23}}>{this.state.count.thisWeekRegCount}</div>  
                                                </div>
                                            </div> 
                                        </Paper>
                                    </Link>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <Link to="/admin/customers" className="Link">
                                        <Paper className='paper'>
                                            <div style={{color:"#34ebb4",fontWeight:"bold"}}>This Month Registrations</div>
                                            <div>
                                                <div><PeopleIcon className='icon' style={{color:"#34ebb4"}}></PeopleIcon></div>
                                                <div className="countData">
                                                    <div style={{fontSize:23}}>{this.state.count.thisMonthRegCount}</div>  
                                                </div>
                                            </div>
                                        </Paper>
                                    </Link>
                                </Grid>
                            </Grid>
        }
        if(this.state.isLoading){
            newRegistrationData=<Loader type='block' /> 
        }else{
            newRegistrationData =<Paper>
                                    <div id="chart" style={{margin:"5px"}}>
                                        <Chart options={options} series={series} type="line" height={350} />
                                    </div>
                                </Paper>;
                                   
        }
        if(this.state.isLoading){
            cancelledData=<Loader type='block' /> 
        }else{
            cancelledData = <Paper>
                                <div id="chart">
                                    <Chart options={cancelOptions} series={canceledCusGraph} type="line" height={350} />
                                </div>
                            </Paper>;
                                   
        }
        if(this.state.studentsDataLoading){
            studentsData = <Loader type='block' />;
        }else{
            studentsData = <Paper>
                            <div id="noOfStudentsChart">
                                <Chart options={pieChartOptions} series={pieChartSeries} type="pie" height={350} />
                            </div>
                        </Paper>;
        }

        if(this.state.modulesSubDataLoading){
            modulesData = <Loader type='block' />;
        }else{
            let module = this.state.modulesData;
            let lmsP = ((module.lms_count*100)/module.core_count).toFixed(2);
            let feesP = ((module.fees_count*100)/module.core_count).toFixed(2);
            let crmP = ((module.crm_count*100)/module.core_count).toFixed(2);
            let financeP = ((module.finance_count*100)/module.core_count).toFixed(2);
            let alumniP = ((module.alumni_count*100)/module.core_count).toFixed(2);
            let ecommerceP = ((module.ecommerce_count*100)/module.core_count).toFixed(2);
            let whitelabelP = ((module.whitelabel_count*100)/module.core_count).toFixed(2);
            let fundraisingP = ((module.fundraising_count*100)/module.core_count).toFixed(2);
            let degreeauditP = ((module.degreeaudit_count*100)/module.core_count).toFixed(2);

            modulesData = <Paper>
                            <div style={{margin:5,padding:5,paddingTop:10}}>
                                <div style={{display:"flex", fontSize:12}}>
                                    <div>Alumni</div>
                                    <div style={{textAlign:'right',flex:1}}>{module.alumni_count}</div>
                                </div>
                                <div style={{height:8,width:'100%',backgroundColor:'#ddd',borderRadius:6}}>
                                    <div style={{height:'100%',width:`${alumniP}%`,backgroundColor:'#E96353',borderRadius:6,textAlign:'right'}}></div>
                                </div>
                            </div>
                            <div style={{margin:5,padding:5}}>
                                <div style={{display:"flex", fontSize:12}}>
                                    <div>LMS</div>
                                    <div style={{textAlign:'right',flex:1}}>{module.lms_count}</div>
                                </div>
                                <div style={{height:8,width:'100%',backgroundColor:'#ddd',borderRadius:6}}>
                                    <div style={{height:'100%',width:`${lmsP}%`,backgroundColor:'#6772e5',borderRadius:6,textAlign:'right'}}></div>
                                </div>
                            </div>
                            <div style={{margin:5,padding:5}}>
                                <div style={{display:"flex", fontSize:12}}>
                                    <div>Degree Audit</div>
                                    <div style={{textAlign:'right',flex:1}}>{module.degreeaudit_count}</div>
                                </div>
                                <div style={{height:8,width:'100%',backgroundColor:'#ddd',borderRadius:6}}>
                                    <div style={{height:'100%',width:`${degreeauditP}%`,backgroundColor:'#57b7e2',borderRadius:6,textAlign:'right'}}></div>
                                </div>
                            </div>
                            <div style={{margin:5,padding:5}}>
                                <div style={{display:"flex", fontSize:12}}>
                                    <div>CRM</div>
                                    <div style={{textAlign:'right',flex:1}}>{module.crm_count}</div>
                                </div>
                                <div style={{height:8,width:'100%',backgroundColor:'#ddd',borderRadius:6}}>
                                    <div style={{height:'100%',width:`${crmP}%`,backgroundColor:'#A5FDCA',borderRadius:6,textAlign:'right'}}></div>
                                </div>
                            </div>
                            <div style={{margin:5,padding:5}}>
                                <div style={{display:"flex", fontSize:12}}>
                                    <div>Fees</div>
                                    <div style={{textAlign:'right',flex:1}}>{module.fees_count}</div>
                                </div>
                                <div style={{height:8,width:'100%',backgroundColor:'#ddd',borderRadius:6}}>
                                    <div style={{height:'100%',width:`${feesP}%`,backgroundColor:'#13b9ff',borderRadius:6,textAlign:'right'}}></div>
                                </div>
                            </div>
                            <div style={{margin:5,padding:5}}>
                                <div style={{display:"flex", fontSize:12}}>
                                    <div>Finance</div>
                                    <div style={{textAlign:'right',flex:1}}>{module.finance_count}</div>
                                </div>
                                <div style={{height:8,width:'100%',backgroundColor:'#ddd',borderRadius:6}}>
                                    <div style={{height:'100%',width:`${financeP}%`,backgroundColor:'#4DE7E7',borderRadius:6,textAlign:'right'}}></div>
                                </div>
                            </div>
                            <div style={{margin:5,padding:5}}>
                                <div style={{display:"flex", fontSize:12}}>
                                    <div>E-Commerce</div>
                                    <div style={{textAlign:'right',flex:1}}>{module.ecommerce_count}</div>
                                </div>
                                <div style={{height:8,width:'100%',backgroundColor:'#ddd',borderRadius:6}}>
                                    <div style={{height:'100%',width:`${ecommerceP}%`,backgroundColor:'#FFE92C',borderRadius:6,textAlign:'right'}}></div>
                                </div>
                            </div>
                            <div style={{margin:5,padding:5}}>
                                <div style={{display:"flex", fontSize:12}}>
                                    <div>Fund Raising</div>
                                    <div style={{textAlign:'right',flex:1}}>{module.fundraising_count}</div>
                                </div>
                                <div style={{height:8,width:'100%',backgroundColor:'#ddd',borderRadius:6}}>
                                    <div style={{height:'100%',width:`${fundraisingP}%`,backgroundColor:'#e5412d',borderRadius:6,textAlign:'right'}}></div>
                                </div>
                            </div>
                            <div style={{margin:5,padding:5,paddingBottom:20}}>
                                <div style={{display:"flex", fontSize:12}}>
                                    <div>White Label</div>
                                    <div style={{textAlign:'right',flex:1}}>{module.whitelabel_count}</div>
                                </div>
                                <div style={{height:8,width:'100%',backgroundColor:'#ddd',borderRadius:6}}>
                                    <div style={{height:'100%',width:`${whitelabelP}%`,backgroundColor:'#7ea7f1',borderRadius:6,textAlign:'right'}}></div>
                                </div>
                            </div>
                        </Paper>
        }

        if(this.state.upcomingRenewalsLoading){
            upcomingRenewalsData = <Loader type='block' /> 
        }else{
            if(this.state.upcomingRenewalsData.length > 0){
                let tempUpcomingRenewals = [];
                for(var i in this.state.upcomingRenewalsData){
                    var date = new Date(this.state.upcomingRenewalsData[i].sub_renew_date);
                    var year = date.getFullYear();
                    var month = date.getMonth() + 1;
                    var day = date.getDate();
                    if(month < 10){
                        month = '0' + month;
                    }
                    if(day < 10){
                        day = '0' + day;
                    }
                    var lDate = new Date(this.state.upcomingRenewalsData[i].last_visit_on);
                    var lYear = lDate.getFullYear();
                    var lMonth = lDate.getMonth() + 1;
                    var lDay = lDate.getDate();
                    var lHours = lDate.getHours();
                    var lMinutes = lDate.getMinutes();
                    var lSeconds = lDate.getSeconds();
                    if(lMonth < 10){
                        lMonth = '0' + lMonth;
                    }
                    if(lDay < 10){
                        lDay = '0' + lDay;
                    }
                    if(lHours < 10){
                        lHours = '0' + lHours;
                    }
                    if(lMinutes < 10){
                        lMinutes = '0' + lMinutes;
                    }
                    if(lSeconds < 10){
                        lSeconds = '0' + lSeconds;
                    }
                    tempUpcomingRenewals.push(
                        <TableRow>
                            <TableCell><Link to={"/admin/profile/" + this.state.upcomingRenewalsData[i].id} className="Link" >{this.state.upcomingRenewalsData[i].domain_url}</Link></TableCell>
                            <TableCell>{year+"-"+month+"-"+day}</TableCell>
                            <TableCell>{this.state.upcomingRenewalsData[i].students_count}</TableCell>
                            <TableCell>{this.state.upcomingRenewalsData[i].billing_cycle}</TableCell>
                            <TableCell>{lYear+"-"+lMonth+"-"+lDay+" "+lHours+":"+lMinutes+":"+lSeconds}</TableCell>
                        </TableRow>
                    );
                }
                upcomingRenewalsData.push(
                    <>
                        <TableBody>{tempUpcomingRenewals}</TableBody>
                        <TableFooter>
                            <td class="MuiTableCell-root MuiTablePagination-root" colspan="1000">
                                <div class="MuiToolbar-regular MuiTablePagination-toolbar MuiToolbar-gutters" style={{float:'right',minHeight:0}}>
                                    <p class="MuiTypography-root MuiTablePagination-caption MuiTypography-body2 MuiTypography-colorInherit"><Link onClick={this.viewMoreUpcomingRenewals.bind()} className="Link">View More</Link></p>
                                </div>
                            </td>
                        </TableFooter>
                    </>
                );
            }else{
                upcomingRenewalsData.push(
                    <TableBody><div style={{padding:20}}>No Record Found.</div></TableBody>
                );
            }
        }

        if(this.state.failedRenewalsLoading){
            failedRenewalsData = <Loader type='block' />
        }else{
            if(this.state.failedRenewalsData.length > 0){
                let tempFailedRenewals = [];
                for(var j in this.state.failedRenewalsData){
                    let renewDate = "";
                    if(this.state.failedRenewalsData[j].sub_renew_date != null){
                        var date = new Date(this.state.failedRenewalsData[j].sub_renew_date);
                        var year = date.getFullYear();
                        var month = date.getMonth() + 1;
                        var day = date.getDate();
                        if(month < 10){
                            month = '0' + month;
                        }
                        if(day < 10){
                            day = '0' + day;
                        }
                        renewDate = year+"-"+month+"-"+day;
                    }
                    
                    var lDate = new Date(this.state.failedRenewalsData[j].last_visit_on);
                    var lYear = lDate.getFullYear();
                    var lMonth = lDate.getMonth() + 1;
                    var lDay = lDate.getDate();
                    var lHours = lDate.getHours();
                    var lMinutes = lDate.getMinutes();
                    var lSeconds = lDate.getSeconds();
                    if(lMonth < 10){
                        lMonth = '0' + lMonth;
                    }
                    if(lDay < 10){
                        lDay = '0' + lDay;
                    }
                    if(lHours < 10){
                        lHours = '0' + lHours;
                    }
                    if(lMinutes < 10){
                        lMinutes = '0' + lMinutes;
                    }
                    if(lSeconds < 10){
                        lSeconds = '0' + lSeconds;
                    }
                    tempFailedRenewals.push(
                        <TableRow>
                            <TableCell><Link to={"/admin/profile/" + this.state.failedRenewalsData[j].id} className="Link" >{this.state.failedRenewalsData[j].domain_url}</Link></TableCell>
                            <TableCell>{renewDate}</TableCell>
                            <TableCell>{this.state.failedRenewalsData[j].students_count}</TableCell>
                            <TableCell>{lYear+"-"+lMonth+"-"+lDay+" "+lHours+":"+lMinutes+":"+lSeconds}</TableCell>
                        </TableRow>
                    );
                }
                failedRenewalsData.push(
                    <>
                        <TableBody>{tempFailedRenewals}</TableBody>
                        <TableFooter>
                            <td class="MuiTableCell-root MuiTablePagination-root" colspan="1000">
                                <div class="MuiToolbar-regular MuiTablePagination-toolbar MuiToolbar-gutters" style={{float:'right',minHeight:0}}>
                                    <p class="MuiTypography-root MuiTablePagination-caption MuiTypography-body2 MuiTypography-colorInherit"><Link onClick={this.viewMoreFailedRenewals.bind()} className="Link">View More</Link></p>
                                </div>
                            </td>
                        </TableFooter>
                    </>
                );
            }else{
                failedRenewalsData.push(
                    <TableBody><div style={{padding:20}}>No Record Found.</div></TableBody>
                );
            }
        }
        return (
            <div>
                <Grid container spacing={3} lg={12}>
                    <Grid item xs={6}>
                        <Typography variant="h5" gutterBottom >
                            Dashboard
                        </Typography>       
                    </Grid>
                </Grid>
                {dashboardData}
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} md={6} sm={12}>
                        <div style={{paddingTop:30}}> 
                            <div style={{fontSize:19,marginBottom:10}}>
                                Tenant - Students Overview
                            </div>
                            {studentsData}
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6} sm={12}>
                        <div style={{paddingTop:30}}> 
                            <div style={{fontSize:19,marginBottom:10}}>
                                Subscriptions Overview
                            </div>
                            {modulesData}
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>                   
                    <Grid item xs={12} lg={6} md={6} sm={12}>
                        <div>
                            <Box style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'10px'}}>
                                <div style={{fontSize:19}}>
                                    Registrations vs Paid Customers
                                </div>
                                <FormControl variant="outlined">
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="graph-type-select"
                                        value={this.state.selectedRegGraphType}
                                        onChange={this.regGraphTypeHandle}
                                        style={{minWidth:'130px',fontSize:'12px'}}
                                        >
                                        <MenuItem value="week" style={{fontSize:'12px'}}>Last Week</MenuItem>
                                        <MenuItem value="month" style={{fontSize:'12px'}}>Last Month</MenuItem>
                                        <MenuItem value="half-year" style={{fontSize:'12px'}}>Last Six Months</MenuItem>                                
                                        <MenuItem value="year" style={{fontSize:'12px'}}>Last Year</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box> 
                            {newRegistrationData}
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6} sm={12}>
                        <div> 
                            <Box style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'10px'}}>
                                <div style={{fontSize:19}}>
                                    Churn Statistics
                                </div>
                                <FormControl variant="outlined">
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="graph-type-select"
                                        value={this.state.selectedCancelGraphType}
                                        onChange={this.cancelGraphTypeHandle}
                                        style={{minWidth:'130px',fontSize:'12px'}}
                                        >
                                        <MenuItem value="week" style={{fontSize:'12px'}}>Last Week</MenuItem>
                                        <MenuItem value="month" style={{fontSize:'12px'}}>Last Month</MenuItem>
                                        <MenuItem value="half-year" style={{fontSize:'12px'}}>Last Six Months</MenuItem>
                                        <MenuItem value="year" style={{fontSize:'12px'}}>Last Year</MenuItem>                                
                                    </Select>
                                </FormControl>
                            </Box>
                            {cancelledData}
                        </div>
                    </Grid>
                </Grid> 
                <div style={{paddingTop:30}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div style={{fontSize:19,marginBottom:10}}>
                                Upcoming Renewals (Manual Billing)
                            </div>
                            <Paper style={{overflowX: "auto"}}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Sub Domain</TableCell>
                                            <TableCell>Renewal Date</TableCell>
                                            <TableCell>No. Of Students</TableCell>
                                            <TableCell>Billing Type</TableCell>
                                            <TableCell>Last Visit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {upcomingRenewalsData}
                                </Table>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div style={{fontSize:19,marginBottom:10}}>
                                Failed Renewals
                            </div>
                            <Paper style={{overflowX: "auto"}}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Sub Domain</TableCell>
                                            <TableCell>Renewal Date</TableCell>
                                            <TableCell>No. Of Students</TableCell>
                                            <TableCell>Last Visit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {failedRenewalsData}
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}
				
//export default withSnackbar(dashboard);
export default withRouter((withSnackbar(dashboard)))