import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

class Message extends Component {  

    render() {
        if ( this.props.text === undefined) {
            return ( null );
        }

        let bgColor = 'primary-main'

        switch ( this.props.type ) {
            case 'error': bgColor = 'error.main'; break;
            case 'success': bgColor = 'green'; break;
            default: break;
        }

        return (
        <Box ref={this.messageBoxRef} className="messageCont" borderRadius={4} bgcolor={bgColor} p={2} mt={1} mb={1}>
            <Typography className="messageText">
            {this.props.text}
            </Typography>
        </Box> 
        );
    }
}

export default Message;
