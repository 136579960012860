import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Loader from '../../elements/loader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import  {InputBase, Drawer} from '@material-ui/core';
import { withSnackbar  } from 'notistack';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Config from '../../config/index';
import moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import {Link} from "react-router-dom";
import InfoIcon from '@material-ui/icons/Info';

const axios = require('axios');
axios.defaults.headers.common['Authorization'] = localStorage.getItem('user_token');

class DeleteCustomers extends Component {
    constructor(props) {
        super(props); 
        this.state={
            tenants:[],
            isLoading:true,
            isSaveInProgress:false,
            userDetails:"",
            userData:"",
            params: {  
                sort: {"created":"desc"},
                filter: {},
                page: 0
            }, 
            open:false,
            more_filters_open: false,
            total_deleted_users: 50,
            records_per_page:10,
            copied:false,
            selectedInstituteType:"",
        };
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.loadTenant = this.loadTenant.bind(this);
        this.searchtitleInputRef = React.createRef();
        this.instituteNameInputRef = React.createRef();
        this.emailInputRef = React.createRef();
        this.registerFromInputRef = React.createRef();
        this.registerToInputRef = React.createRef();
        this.phoneInputRef = React.createRef();
        this.subDomainInputRef = React.createRef();
        this.contactInputRef = React.createRef();
        this.instStudentSizeInputRef = React.createRef();
    }
    componentDidMount(){
        this.loadTenant(this.state.params);
    }
    loadTenant= (params, preReset = false) => {
        if ( preReset === true ) {  
            params.page = 0;
            this.setState({
                isLoading: true,
                tenants:[],
                more_filters_open: false
            });            
        }
        let self=this;
        axios.get( Config.apiBaseURL + '/deleted_tenants',{params:params})
        .then( response => {
            this.setState({
                deleted_users:response.data.deleted_users, 
                records_per_page: response.data.records_per_page,
                total_deleted_users: response.data.total_deleted_users,
                params: params,
            });
        })
        .catch(function (error) {
            if(error.response && error.response.status === 500) {                   
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }
            else { // any other error 
                    self.props.enqueueSnackbar('Error! While loading deleted users data.', {
                    variant: 'error',
                });
            }
        })
        .then(function () {
            self.setState({
                isLoading:false
            });
        });
    }
    instituteTypeHandle = (event) => {
        this.setState({
            selectedInstituteType:event.target.value
        })
    }
    detectEnter = (e) => {
        if(e.keyCode == 13){
            this.triggerBasicFilters();
        }
    }
    triggerBasicFilters = (e) => {
        const title = this.searchtitleInputRef.current.value;
        let params = this.state.params;
        params.filter['search_keyword'] = ( title !== '' ) ? title : undefined;
        params.filter['domain_name'] = ( title !== '' ) ? title : undefined;
        this.loadTenant(params, true);
    } 
    handleSortChange = (sortAttr, sortDir) => event => {
        let params = this.state.params;
        params.sort = {}; 
        params.sort[sortAttr] = sortDir;
        this.loadTenant(params);
    };
    handleChangePage = (event, newPage) => {
        let params = this.state.params;
        params.page = newPage;
        this.loadTenant(params);
    }; 
    handleFiltersToggle = () => this.setState({more_filters_open: !this.state.more_filters_open});
    triggerMoreFilters = (e) => {
        e.preventDefault();

        let params = this.state.params;
        const inst_name = this.instituteNameInputRef.current.value;
        const email = this.emailInputRef.current.value;
        const registerFrom = this.registerFromInputRef.current.value;
        const registerTo = this.registerToInputRef.current.value;
        const domain_name =this.subDomainInputRef.current.value;
        const contact=this.contactInputRef.current.value;
        const institute_type = this.state.selectedInstituteType;
        const inst_student_size = this.instStudentSizeInputRef.current.value;
        params.filter['search_keyword'] = ( domain_name !== '' ) ? domain_name : undefined;
        params.filter['inst_name'] = ( inst_name !== '' ) ? inst_name : undefined;
        params.filter['domain_name'] = ( domain_name !== '' ) ? domain_name : undefined;
        params.filter['contact'] = ( contact !== '' ) ? contact : undefined;
        params.filter['email'] = ( email !== '' ) ? email : undefined;  
        params.filter['created_from'] = ( registerFrom !== '' ) ? registerFrom : undefined;
        params.filter['created_to'] = ( registerTo !== '' ) ? registerTo : undefined;
        params.filter['inst_type'] = ( institute_type !== '' ) ? institute_type : undefined;
        params.filter['inst_student_size'] = ( inst_student_size !== '' ) ? inst_student_size : undefined;
        this.loadTenant(params, true);
    }
    resetFilters = () => {
        let params = this.state.params;
        params.filter = {};
        this.searchtitleInputRef.current.value=null;
        this.instituteNameInputRef.current.value=null;
        this.emailInputRef.current.value=null;
        this.subDomainInputRef.current.value=null;
        this.contactInputRef.current.value=null;  
        this.registerFromInputRef.current.value=null; 
        this.registerToInputRef.current.value=null;     
        this.instStudentSizeInputRef.current.value=null;
        this.setState({
            selectedInstituteType:"",
        });
        this.loadTenant(params, true);
    }
    render(){
        const { selectedInstituteType } = this.state;
        let basicFilter;
        let more_filters =  <Drawer
                                anchor="right"
                                open={this.state.more_filters_open}
                                width={500}>
                                <div>
                                    <div className="moreFilterTitle">Filters</div>
                                    <CancelIcon onClick={this.handleFiltersToggle} className="cancelIcon"/>
                                </div>
                                <div  style={{minWidth:400}}>
                                    <form method="POST" onSubmit={this.triggerMoreFilters} 
                                        style={{margin:30,marginTop:20,maxWidth:400}}>
                                        <TextField style={{marginTop:20}}
                                            inputProps={{
                                                ref: this.instituteNameInputRef
                                            }}
                                            id="title"
                                            label="Institute Name"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            defaultValue={(this.state.params.filter['inst_name']) ? this.state.params.filter['inst_name'] : null}
                                        />
                                        <TextField style={{marginTop:20}}
                                            inputProps={{
                                                ref: this.subDomainInputRef
                                            }}
                                            id="subDomain"
                                            label="Sub Domain"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            defaultValue={(this.state.params.filter['domain_name']) ? this.state.params.filter['domain_name'] : null}
                                        />
                                        <Grid container spacing={3} lg={12}>
                                            <Grid item xs={6}>
                                                <div style={{marginTop:16}}></div>
                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="demo-simple-select-outlined-label">Institute Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Institute Type"
                                                        value={selectedInstituteType}
                                                        onChange={this.instituteTypeHandle}
                                                        >
                                                        <MenuItem value="">Select</MenuItem>
                                                        <MenuItem value="Academy">Academy</MenuItem>
                                                        <MenuItem value="Schools / K-12">Schools / K-12</MenuItem>
                                                        <MenuItem value="Higher Education / Colleges">Higher Education / Colleges</MenuItem>
                                                        <MenuItem value="University">University</MenuItem>
                                                        <MenuItem value="Other">Other</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    inputProps={{
                                                        ref: this.instStudentSizeInputRef
                                                    }}
                                                    id="instStudentSize"
                                                    label="Student Size"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    defaultValue={(this.state.params.filter['inst_student_size']) ? this.state.params.filter['inst_student_size'] : null}
                                                />
                                            </Grid>
                                        </Grid>
                                        <TextField style={{marginTop:20}}
                                            inputProps={{
                                                ref: this.emailInputRef
                                            }}
                                            id="email"
                                            label="Email"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            defaultValue={(this.state.params.filter['email']) ? this.state.params.filter['email'] : null}
                                        />
                                        <TextField style={{marginTop:20}}
                                            inputProps={{
                                                ref: this.contactInputRef
                                            }}
                                            id="contact"
                                            label="Contact "
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            defaultValue={(this.state.params.filter['contact']) ? this.state.params.filter['contact'] : null}
                                        />
                                        <Grid container spacing={3} lg={12}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    inputProps={{
                                                        ref: this.registerFromInputRef
                                                    }}
                                                    style={{marginTop:20}}
                                                    id="date"
                                                    label="Deleted Date (From)"
                                                    type="date"
                                                    fullWidth
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                    shrink: true,
                                                    }}
                                                    defaultValue={(this.state.params.filter['created_from']) ? this.state.params.filter['created_from'] : null}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    inputProps={{
                                                        ref: this.registerToInputRef
                                                    }}
                                                    style={{marginTop:20}}
                                                    id="date"
                                                    label="Deleted Date (To)"
                                                    type="date"
                                                    fullWidth
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                    shrink: true,
                                                    }}
                                                    defaultValue={(this.state.params.filter['created_to']) ? this.state.params.filter['created_to'] : null}
                                                />
                                            </Grid>
                                        </Grid>
                                        <div style={{marginTop:20,marginBottom:20}} >
                                            <Button variant="contained" size="medium" color="primary" type="submit">
                                                Apply
                                            </Button>&nbsp;&nbsp;
                                            <Button variant="contained" size="medium" onClick={this.resetFilters} type="submit" >
                                                Reset
                                            </Button>
                                        </div> 
                                    </form>  
                                </div> 
                            </Drawer>;  
        let overlayLoader;
        if(this.state.isSaveInProgress == true){
             overlayLoader=  <div className="loading"> 
                                 <Loader type='block' /> 
                             </div>
         }
        let data_table;                                     
        if ( this.state.isLoading ) {
            data_table = <Loader type='block'/>;
        }else{
            let deleteData = [];
                for(const i in this.state.deleted_users){ 
                    var created = this.state.deleted_users[i].created.split('T')
                    let date = created[0];
                    var Date= moment(date).format("DD MMM YYYY");
                    deleteData.push(
                        <TableRow>
                            <TableCell>{this.state.deleted_users[i].inst_name}</TableCell>
                            <TableCell>{this.state.deleted_users[i].domain_name}</TableCell>
                            <TableCell>{this.state.deleted_users[i].inst_type}</TableCell>
                            <TableCell>{this.state.deleted_users[i].inst_student_size}</TableCell>
                            <TableCell>{this.state.deleted_users[i].email}</TableCell>
                            <TableCell>{this.state.deleted_users[i].contact}</TableCell>
                            <TableCell style={{maxWidth:70}}>{Date}</TableCell>
                            <TableCell>
                                <Tooltip title="View"><Link  to={"/admin/deletedCustomerView/"+this.state.deleted_users[i].id } className="actionIcon" ><InfoIcon></InfoIcon></Link></Tooltip>
                            </TableCell> 
                        </TableRow>  
                    );
                }
                basicFilter= <div className="adminSearchBox">
                            <div>
                                <SearchIcon className="searchIcon" onClick={this.triggerBasicFilters}/>
                            </div>   
                            <div>
                                <InputBase
                                    style={{width: "calc(100% - 10px)"}}
                                    placeholder="Search Customers"
                                    inputProps={{
                                        ref: this.searchtitleInputRef
                                    }}
                                    onKeyUp={this.detectEnter}
                                    defaultValue={(this.state.params.filter['search_keyword']) ? this.state.params.filter['search_keyword'] : null} 
                                />
                            </div>
                            <div style={{width:100}}>
                                <div onClick={this.handleFiltersToggle} className="moreFilterLabel" >More Filters</div>
                            </div>
                        </div>  
                data_table=<div> 
                                <Paper>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <TableSortLabel 
                                                        active={ (this.state.params.sort.inst_name) ? true : false} 
                                                        direction={ (this.state.params.sort.inst_name) ? this.state.params.sort.inst_name.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('inst_name', (this.state.params.sort.inst_name && this.state.params.sort.inst_name.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        Institute Name
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={ (this.state.params.sort.domain_name) ? true : false} 
                                                        direction={ (this.state.params.sort.domain_name) ? this.state.params.sort.domain_name.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('domain_name', (this.state.params.sort.domain_name && this.state.params.sort.domain_name.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        Sub Domain
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>Institute Type</TableCell>
                                                <TableCell>Student Size</TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={ (this.state.params.sort.email) ? true : false} 
                                                        direction={ (this.state.params.sort.email) ? this.state.params.sort.email.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('email', (this.state.params.sort.email && this.state.params.sort.email.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        Email
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={ (this.state.params.sort.contact) ? true : false} 
                                                        direction={ (this.state.params.sort.contact) ? this.state.params.sort.contact.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('contact', (this.state.params.sort.contact && this.state.params.sort.contact.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        Contact
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={ (this.state.params.sort.created) ? true : false} 
                                                        direction={ (this.state.params.sort.created) ? this.state.params.sort.created.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('created', (this.state.params.sort.created && this.state.params.sort.created.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        Deleted On
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell style={{maxWidth:70}}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {deleteData}    
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[]}
                                                    count={this.state.total_deleted_users}
                                                    rowsPerPage={this.state.records_per_page}
                                                    page={this.state.params.page ? this.state.params.page : 0}
                                                    onChangePage={this.handleChangePage}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </Paper>
                            </div>
            }  
            return (
                <div>
                    <Grid container spacing={3} lg={12}>
                        <Grid item xs={6}>
                            <Typography variant="h5" gutterBottom >
                                Deleted Customers 
                            </Typography>       
                        </Grid>
                    </Grid>
                    {overlayLoader}
                    {more_filters}
                    {basicFilter}
                    {data_table}
                </div>
            );
		}
	}
				
export default withSnackbar(DeleteCustomers);
