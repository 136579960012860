import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Link } from 'react-router-dom';
import { withSnackbar  } from 'notistack';
import Loader from '../elements/loader';
import Config from '../config/index';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Linkify from 'react-linkify';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import InfoIcon from '@material-ui/icons/Info';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {withStyles } from '@material-ui/core/styles';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import moment from 'moment';
import RefreshIcon from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, DialogContentText, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import config from '../config/index';
import DeleteIcon from '@material-ui/icons/Delete';

const axios = require('axios');
axios.defaults.headers.common['Authorization'] = localStorage.getItem('user_token');
const role = localStorage.getItem('role');  
function TabPanel(props) {
    const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
                >
                {value === index && <Box p={3}>{children}</Box>}
            </Typography>
        );
}
const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#3f51b5',
    },
})(Tabs);
const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#3f51b5',
            opacity: 1,
        },
        '&$selected': {
            color: '#3f51b5',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#3f51b5',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

class profile extends Component {
    constructor(props) {
        super(props); 
        this.state={ 
            isLoading:false,
            data:[],
            params: {
                page: 0,
                data_for :"",
                invoice_id:"",
                invoice_for:"",
            },
            userDetails:[],
            useridPass:"", 
            value:0,
            validationErrors: {},
            isSaveInProgress: false, // overlay loader
            des:"",
            pcopied:false,
            ucopied:false,
            redirect: false,
            total_payments:2,
            records_per_page:10,
            creditCardPayment:"activePayment",
            bankPayment:"disablePayment",
            institute_name:'',
            admin_name:'',
            domain_url:'',
            inst_type:'',
            inst_student_size:'',
            user_email_id:'',
            contact_no:'',
            showCreditInput:false,
            selectedSmsCreditType:'',
            open:false,
            wantToDelete : 0,
            deletePassError:'',
            deleteKeyError:'',
            deleteOpen:false
        }; 
        this.titleInputRef = React.createRef();
        this.descriptionInputRef = React.createRef();
        this.submitForm = this.submitForm.bind(this);
        this.cancelForm = this.cancelForm.bind(this);
        this.paymentDisplay = this.paymentDisplay.bind(this);
        this.smsCreditInputRef = React.createRef();
        this.deletePasswordRef = React.createRef();
        this.deleteKeywordRef = React.createRef();        
    }
    componentDidMount(){
        this.loadUserdetails();
        this.subscriptionDetails();
        this.paymentData();
        this.safeNotsafeData();
        this.credentialsData();
        this.loadSmsLogDetails();
    }
    loadUserdetails= () => {
        let self=this;
        self.setState({
            isLoading:true
        });
        axios.get( Config.apiBaseURL + '/tenant/view/'+this.props.match.params.id)
        .then( response => {
            this.setState({
                domain_url:response.data.domain_url,
                institute_name:response.data.institute_name,
                admin_name:response.data.admin_name,
                inst_type:response.data.inst_type,
                inst_student_size:response.data.inst_student_size,
                user_email_id:response.data.user_email_id,
                contact_no:response.data.contact_no,
                userDetails:response.data,
            });
        })
        .catch(function (error) {
            if(error.response && error.response.status === 500) {                   
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }
            else { // any other error 
                    self.props.enqueueSnackbar('Error! While loading tenants data.', {
                    variant: 'error',
                });
            }
        })
        .then(function () {
            self.setState({
                //
            })
        });
    }
    subscriptionDetails= ()=>{
        this.setState({
            isLoading:true
        })
        let self=this;
        axios.get( Config.apiBaseURL + '/tenant/subscription/'+this.props.match.params.id)
        .then( response => {
            this.setState({
                subscriptionDetails:response.data,
            });
        })
        .catch(function (error) {
            if(error.response && error.response.status === 500) {                   
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }
            else { // any other error 
                    self.props.enqueueSnackbar('Error! While loading subscription data.', {
                    variant: 'error',
                });
            }
        })
        .then(function () {
            self.setState({
                isLoading:false,
            })
        });
    }
   
    handleChangePage = (event,newPage) => {
        let params = this.state.params;
        let data,paymentId;
        if(params.page < newPage){
            data="next";
            paymentId=this.state.paymentNextId
        }else if(params.page > newPage){
            data="previous";
            paymentId=this.state.paymentPrevId
        }
        params['data_for'] = ( data !== '' ) ? data : undefined;
        params['invoice_id'] = (paymentId !== '' ) ?paymentId : undefined;
        params.page = newPage;
        this.paymentData(params, true);
         
    };
    paymentDisplay=(name)=>{
        let params=this.state.params
        if(name == "bankPayment"){
          params['invoice_for'] = ( "other" !== '' ) ? "other" : undefined;
            this.setState({
                bankPayment:"activePayment",
                creditCardPayment:"disablePayment"
            });
        }else{
            params['invoice_for'] = "";
            this.setState({
                creditCardPayment:"activePayment",
                bankPayment:"disablePayment"
            });
        }
        this.paymentData(params, true);
       
    }
    paymentData= (params) => {
        let self=this;
        this.setState({
            isSaveInProgress:true,
             creditCardMessage:"",
        })
        axios.get( Config.apiBaseURL + '/tenant/payments/'+this.props.match.params.id, {params:params})
        .then( response => {
            this.setState({
                paymentDetails:response.data.data, 
                paymentHashMore:response.data.hasmore
            });
        })
        .catch(function (error) {
            if(error.response && error.response.status === 500) {                   
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if(error.response && error.response.status === 400) {                   
               
                self.setState({
                    paymentDetails:"",
                    creditCardMessage:error.response.data.message
                })
               
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }
            else{ // any other error 
                    self.props.enqueueSnackbar('Error! While loading payments data.', {
                    variant: 'error',
                });
            }
            
        })
        .then(function () {
            self.setState({
                isLoading:false,
                isSaveInProgress:false
            })
        });
    }
    safeNotsafeData = () => {
        this.setState({
            isLoading:true
        })
        let self=this;
        axios.get( Config.apiBaseURL + '/tenant/isSafeToDelete/'+this.props.match.params.id)
        .then( response => {
            this.setState({
                safeNotsafeData:response.data,
            });
        })
        .catch(function (error) {
            if(error.response && error.response.status === 500) {                   
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }
            else { // any other error 
                    self.props.enqueueSnackbar('Error! While loading safe to delete status data.', {
                    variant: 'error',
                });
            }
        })
        .then(function () {
            self.setState({
                isLoading:false,
            })
        });
    }
    credentialsData = () => {
        let self=this;
        axios.get( Config.apiBaseURL + '/tenant/credentials/'+this.props.match.params.id)
        .then( response => {
            this.setState({
                useridPass:response.data
            });
        })
        .catch(function (error) {
            if(error.response && error.response.status === 500) {                   
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }
            else { // any other error 
                    self.props.enqueueSnackbar('Error! While loading tenants data.', {
                    variant: 'error',
                });
            }
        })
        .then(function () {
            self.setState({
               //
            })
        });
    }
    cancelForm = () => {
        this.setState({   
            redirect: true,
        });
    }
    handleChange = (event, newValue) => {
        this.setState({
            value:newValue,
        });  
    };
    // domain url redirect in new tab
    domainUrlClick(url){
        window.open("https://"+url+".classe365.com");
    }
    refreshSyncedData = () => {
        this.setState({
            isLoading:true
        })
        //call codebase api to sync/refresh all synced statistics data
        let self=this;
        axios.get( Config.apiBaseURL + '/tenant/refresh_statistics/'+this.props.match.params.id)
        .then( response => {
            this.setState({
                userDetails:response.data,
            });
        })
        .catch(function (error) {
            if(error.response && error.response.status === 500) {
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }else { // any other error 
                    self.props.enqueueSnackbar('Error! While loading tenants data.', {
                    variant: 'error',
                });
            }
        })
        .then(function () {
            self.setState({
                isLoading:false,
            })
        });
    }
    loadSmsLogDetails = () => {
        this.setState({
            isLoading:true
        })
        //call codebase api to sync/refresh all synced statistics data
        let self=this;
        axios.get( Config.apiBaseURL + '/tenant/sms_logs/'+this.props.match.params.id)
        .then( response => {
            this.setState({
                smsLogs:response.data,
            });            
        })
        .catch(function (error) {
            if(error.response && error.response.status === 500) {
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }else { // any other error 
                    self.props.enqueueSnackbar('Error! While loading tenants data.', {
                    variant: 'error',
                });
            }
        })
        .then(function () {
            self.setState({
                isLoading:false,
            })
        });
    }
    handleSmsCreditTypeChange = (e) =>{
        this.setState({selectedSmsCreditType:e.target.value})
    }
    handleAddSMSCredit = () =>{
        this.setState({
            isLoading:true
        })       
        let self=this;  
        let smsType = this.state.selectedSmsCreditType
        let smsCount = this.smsCreditInputRef.current.value

        if(smsType == '' || smsCount == ''){
            self.props.enqueueSnackbar('Double check your form entries.', {
            variant: 'error',})
            this.setState({
                isLoading:false
            })   
        }else{
            let obj = {credit_type:this.state.selectedSmsCreditType,credit_amount:this.smsCreditInputRef.current.value}
            axios.post( Config.apiBaseURL + '/tenant/add_sms_credit/'+this.props.match.params.id,obj)
            .then( response => {
                this.loadSmsLogDetails();
                this.ModelhandleClose();                
            })
            .catch(function (error) {
                if(error.response && error.response.status === 500) {
                    self.props.enqueueSnackbar(error.response.data.errors.message, {
                        variant: 'error',
                    });
                }else if (error.response && error.response.status === 401){
                    localStorage.setItem('logout', true);
                    window.location = '/';
                }else { // any other error 
                        self.props.enqueueSnackbar('Error! While adding SMS Credits.', {
                        variant: 'error',
                    });
                }
            })
            .then(function () {
                self.setState({
                    isLoading:false,
                    selectedSmsCreditType:''
                })
                this.ModelhandleClose();
            });
        }
    }
    ModelhandleClose = () => {
        this.setState({
            open:false,           
        })  
    };
    handleDeleteOpen = ()=>{
        this.setState({
            deleteOpen:true
        })              
    }
    handleDeleteClose = ()=>{
        this.setState({
            deleteOpen:false
        })
    }
    deleteCustomer = () => {
        let deleteParams = {}
        let id = this.props.match.params.id;
        console.log(id)
        deleteParams['password'] = this.deletePasswordRef.current.value;
        deleteParams['keyword'] = this.deleteKeywordRef.current.value;
        
        if (id != "") {  
            const self = this;
            axios.delete(Config.apiBaseURL +'/tenant/delete/'+ id,{data:deleteParams})
            .then( response => {
                if(response.data.success) { 
                    self.props.enqueueSnackbar('Customer deleted successfully.', {
                        variant: 'success',
                    });
                    window.location = '/admin/customers';
                }else{
                    self.props.enqueueSnackbar(response.data.message, {
                        variant: 'error',
                    }); 
                }
            })
            .catch(function (error) {
                console.log(error.response)
                if(error.response && error.response.status === 400) {                   
                    self.props.enqueueSnackbar(error.response.data.errors, {
                        variant: 'error',
                    });
                    self.setState({
                        deletePassError:error.response.data.validation_errors.password ? error.response.data.validation_errors.password :'',
                        deleteKeyError:error.response.data.validation_errors.keyword ? error.response.data.validation_errors.keyword :'',
                    })
                }else if (error.response && error.response.status === 401){
                    localStorage.setItem('logout', true);
                    window.location = '/';
                }
                else { // any other error 
                        self.props.enqueueSnackbar('Error! While deleting this customer.', {
                        variant: 'error',
                    });
                }                
            })
            .then(function () {
                // always executed
            });
        }
    }  
    render(){
        let OpenDialog=<Dialog className="model" onClose={this.ModelhandleClose} aria-labelledby="customized-dialog-title" open={this.state.open}>
                                <DialogTitle id="customized-dialog-title">
                                    Add SMS Credits      
                                    <IconButton edge="start" color="inherit" className="dialogCloseIcon" onClick={this.ModelhandleClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton> 
                                </DialogTitle>
                                    <DialogContent dividers>
                                        <div style={{fontSize:14}}>
                                            <Grid container spacing={3}  alignItems="center" style={{color:"#707070",lineHeight:"30px"}}>
                                                <Grid item>                                                    
                                                    <FormControl>
                                                        <FormLabel>SMS Credit Type</FormLabel>
                                                        <RadioGroup row name='smsType' onChange={this.handleSmsCreditTypeChange} >
                                                            <FormControlLabel value={'free'}  label={"Free"} control={<Radio color='primary'/>}/>
                                                            <FormControlLabel value={'purchased'} label={"Purchased"} control={<Radio color='primary'/>}/>
                                                        </RadioGroup>
                                                    </FormControl>
                                                    {this.state.selectedSmsCreditType == 'free' && <Typography style={{padding:"5px",background:"#FFE92CB3",borderRadius:"5px"}} >Kindly note that when you add free credits, existing free credits balance will reset to 0 and then this new free credits will be added.<br/>Also note that this free credits will be expired or reset on next renewal.</Typography>}
                                                    </Grid>
                                                    <Grid item md={12}>
                                                    <FormControl>
                                                        <FormLabel>SMS Credits</FormLabel>
                                                        <TextField 
                                                            inputProps={{
                                                                ref: this.smsCreditInputRef
                                                            }}                                                                                
                                                            margin='normal' 
                                                            variant="outlined"
                                                            type='number' 
                                                        />      
                                                        </FormControl>                                                  
                                                </Grid>
                                            </Grid>
                                        </div>  
                                    </DialogContent>
                                    <DialogActions>
                                        <Button variant='contained' color='primary' onClick={this.handleAddSMSCredit}>Add</Button>
                                    </DialogActions>
                                </Dialog> 
        const {value} = this.state;
        let subscriptionData = [];
            for(const i in this.state.subscriptionDetails){ 
                let cancelDate,cancelReason;
                if(this.state.subscriptionDetails[i].cancel_date == ""  || this.state.subscriptionDetails[i].cancel_date == undefined){
                    cancelDate="-"
                }else{
                    cancelDate=moment(this.state.subscriptionDetails[i].cancel_date).format("DD MMM YYYY");
                }
                if(this.state.subscriptionDetails[i].cancel_reason == "" || this.state.subscriptionDetails[i].cancel_reason == undefined){
                    cancelReason="-"
                }else{
                    cancelReason= this.state.subscriptionDetails[i].cancel_reason
                }
                let Status; // Status value display for chipEffect in subscription dataTable
                if(this.state.subscriptionDetails[i].status == 'FreeTrial'){
                    Status= <Chip label='Free Trial' className="chipEffect" style={{backgroundColor:"#1976d2"}}></Chip>
                }else if(this.state.subscriptionDetails[i].status == 'Unpaid'){
                    Status= <Chip label='Unpaid' className="chipEffect" style={{backgroundColor:"#f44336"}}></Chip>
                }else if(this.state.subscriptionDetails[i].status == 'Active'){
                    Status= <Chip label='Active' className="chipEffect" style={{backgroundColor:"#34ebb4"}}></Chip>
                }else if(this.state.subscriptionDetails[i].status == 'PaymentDue'){
                    Status= <Chip label='Payment Due' className="chipEffect" style={{backgroundColor:"#1976d2"}}></Chip>
                }else if(this.state.subscriptionDetails[i].status == 'Canceled'){
                    Status= <Chip label='Canceled' className="chipEffect" style={{backgroundColor:"#f44336"}}></Chip>
                }else if(this.state.subscriptionDetails[i].status == 'ToBeCancelled'){
                    Status= <Chip label='To Be Cancelled' className="chipEffect" style={{backgroundColor:"#f44336"}}></Chip>
                }
                
                let startDate,endDate;
                if(this.state.subscriptionDetails[i].start_date == "" || this.state.subscriptionDetails[i].start_date == null){
                    startDate = "-";
                   
                }else{
                    startDate=moment(this.state.subscriptionDetails[i].start_date).format("DD MMM YYYY");
                }
                if(this.state.subscriptionDetails[i].end_date == "" || this.state.subscriptionDetails[i].end_date == null){
                    endDate = "-";
                }else{
                    endDate=moment(this.state.subscriptionDetails[i].end_date).format("DD MMM YYYY");
                }
               
                subscriptionData.push(
                    <TableRow>
                        <TableCell>{this.state.subscriptionDetails[i].name}</TableCell>
                        <TableCell>{this.state.subscriptionDetails[i].type}</TableCell>
                        <TableCell>{Status}</TableCell>
                        <TableCell>{startDate}</TableCell>
                        <TableCell>{endDate}</TableCell>
                        <TableCell>{cancelDate}</TableCell>
                        <TableCell>{cancelReason}</TableCell>
                        <TableCell><Link className="Link" to={"/admin/subscription_details/" + this.props.match.params.id +"?keyword="+this.state.subscriptionDetails[i].keyword} ><InfoIcon></InfoIcon></Link></TableCell>
                    </TableRow>  
                );
            }
            let data_table=<div style={{paddingTop:20}}> 
                                <Typography variant="h6" gutterBottom >
                                    Subscription
                                </Typography>
                            <Paper style={{marginTop:10}}>
                                <Table stickyHeader={false}>
                                    <TableHead className="root">
                                        <TableRow style={{fontWeight:400}}>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell width="150px">Start Date</TableCell>
                                            <TableCell width="150px">End Date</TableCell>
                                            <TableCell width="150px">Cancel Date</TableCell>
                                            <TableCell width="150px">Cancel Reason</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody> 
                                        {subscriptionData}     
                                    </TableBody>
                                </Table>
                            </Paper>
                        </div>
            const PaymentData=[];
            var paymentPrevNext="",paymentTable="";
            let overlayLoader;
            this.state.total_payment="";
            this.state.records_per_page="";
            if(this.state.isSaveInProgress == true){
                 overlayLoader=  <div className="paymentloading"> 
                                     <Loader type='block' /> 
                                 </div>
             }else{
                for(const i in this.state.paymentDetails){
                    let paymentStatus; // Status value display for chipEffect in payment dataTable
                    if(this.state.paymentDetails[i].status == 'failed'){
                        paymentStatus= <Chip label='Failed' className="chipEffect" style={{backgroundColor:"#f44336"}}></Chip>
                    }else if(this.state.paymentDetails[i].status == 'paid'){
                        paymentStatus= <Chip label='Paid' className="chipEffect" style={{backgroundColor:"#34ebb4"}}></Chip>
                    }
                    let paymentFor;
                    let planTime;
                    let paymentDesc = this.state.paymentDetails[i].description;  
                    if (typeof this.state.paymentDetails[i].invoice_number !== 'undefined') {
                        paymentFor = (typeof this.state.paymentDetails[i].payment_for !== 'undefined') ? this.state.paymentDetails[i].payment_for : '';
                        planTime = (typeof this.state.paymentDetails[i].plan_start !== 'undefined') ? 'Subscription Period - '+this.state.paymentDetails[i].plan_start+' to '+this.state.paymentDetails[i].plan_end : '';
                        paymentDesc = (typeof this.state.paymentDetails[i].description !== 'undefined' && this.state.paymentDetails[i].description != null ) ? 'Description - '+this.state.paymentDetails[i].description : '';
                        //paymentDescription = paymentFor+planTime+paymentDesc
                    }
                    PaymentData.push(
                        <TableRow>
                            <TableCell>{this.state.paymentDetails[i].invoice_number}</TableCell>                            
                            <TableCell><div>{paymentFor}</div><div>{planTime}</div><div>{paymentDesc}</div></TableCell>
                            <TableCell style={{minWidth:120}}>{this.state.paymentDetails[i].paid_on}</TableCell>
                            <TableCell>{this.state.paymentDetails[i].amount}</TableCell>
                            <TableCell>{paymentStatus}</TableCell>
                        </TableRow>  
                    );
                    if(this.state.paymentHashMore == false){
                        this.state.total_payment=10
                        this.state.records_per_page=10
                    }
                    if(this.state.creditCardPayment == "activePayment"){ // Active payment tab open so prev next pagination display
                        this.state.paymentNextId=this.state.paymentDetails[i].id
                        this.state.paymentPrevId=this.state.paymentDetails[0].id
                        paymentPrevNext=<TablePagination
                                        className="paymentData"
                                        rowsPerPageOptions={[]}
                                        count={this.state.total_payment}
                                        rowsPerPage={this.state.records_per_page}
                                        page={this.state.params.page ? this.state.params.page : 0}
                                        onChangePage={this.handleChangePage}
                                    />
                    }
                }
                 
                if(PaymentData.length > 0)
                    paymentTable=<Paper style={{marginTop:20}}>
                                    <Table stickyHeader={false}>
                                        <TableHead className="root">
                                            <TableRow style={{fontWeight:400}}>
                                                <TableCell  width="200px">Invoice Number</TableCell>
                                                <TableCell  width="250px">Description</TableCell>
                                                <TableCell  width="200px">Payment Date</TableCell>
                                                <TableCell  width="150px">Payment Amount</TableCell>
                                                <TableCell  width="150px">Payment Status</TableCell>   
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {PaymentData}    
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                {paymentPrevNext}
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                            </Paper>
        
            }
            const storage=[];
            const academic=[];
            const user=[];
            const learning=[];
            const feesAndCRM = [];
            const communication = [];
            const db_standard = 0.5;
            const file_standard = 10.24;
            const size_in = "GB";
            let userDetails = this.state.userDetails;
            if(userDetails != undefined){
                let fileProgressColor = "primary",dbProgressColor = "primary",fileColor = "#3f51b5";
                let tmp_db_size = ((userDetails.db_size)/1024).toFixed(2);    // DB data display in GB
                let db_percentage = ((tmp_db_size*100)/db_standard).toFixed(2);
                let db = db_percentage;
                
                let tmp_file_size = ((userDetails.file_size)/1024).toFixed(2);    // file data display in GB
                let file_percentage = ((userDetails.file_size*100)/file_standard).toFixed(2);
                let file = file_percentage;
                
                if(tmp_file_size > file_standard){    //100 up data storage so color change
                    file = 100;
                    fileProgressColor = "secondary";
                    fileColor = "#f50057";
                }else if(tmp_db_size > db_standard){
                    db = 100;
                    dbProgressColor = "secondary";
                }
                let last_synced_on = '';
                if(userDetails.last_synced_on != undefined){
                    let timestamp = '';
                    if(isNaN(userDetails.last_synced_on) == true){
                        const isoStr = userDetails.last_synced_on;
                        const date = new Date(isoStr);
                        timestamp = date.getTime();
                    }else{
                        timestamp = userDetails.last_synced_on;
                    }
                    var date = new Date(timestamp);
                    var year = date.getFullYear();
                    var month = date.getMonth() + 1;
                    var day = date.getDate();
                    var hours = date.getHours();
                    var minutes = date.getMinutes();
                    var seconds = date.getSeconds();
                    if(month < 10){
                        month = '0' + month;
                    }
                    if(day < 10){
                        day = '0' + day;
                    }
                    if(hours < 10){
                        hours = '0' + hours;
                    }
                    if(minutes < 10){
                        minutes = '0' + minutes;
                    }
                    if(seconds < 10){
                        seconds = '0' + seconds;
                    }
                    last_synced_on = <div style={{float:"right",marginTop:2}}>{year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds}</div>
                }
                storage.push(
                    <Grid container spacing={3}>
                        <Grid item  md={4} sm={6} lg={4} style={{fontSize:14,paddingTop:10,marginBottom:13}}>
                            <Paper className="Paper">
                                <div className="storageTitle">DB Consumption</div>
                                <div className="circulerProgress"><CircularProgress thickness="4.6" size="7rem" variant="determinate" color={dbProgressColor} value={db} /></div>
                                <Grid container spacing={3}>    
                                    <Grid item  md={6} sm={6} lg={6} className="storageValueBlock">
                                        <div className="storageValue">{tmp_db_size} {size_in}</div>
                                        <div>{db_percentage}% used</div>
                                    </Grid>
                                    <Grid item md={6} sm={6} lg={6} className="storageValueBlock">
                                        <div className="totalStorageValue">{db_standard} {size_in}</div>
                                        <div>Total Storage</div>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item  md={4} sm={6} lg={4} style={{fontSize:14}}>
                            <Paper className="Paper">
                                <div className="storageTitle">File Storage</div>
                                <div className="circulerProgress"><CircularProgress thickness="4.6" size="7rem" variant="determinate" color={fileProgressColor} value={file} /></div>
                                <Grid container spacing={3}>
                                    <Grid item  md={6} sm={6} lg={6} className="storageValueBlock">
                                        <div className="storageValue" style={{color:fileColor}}>{tmp_file_size} {size_in}</div>
                                        <div>{file_percentage}% used</div>
                                    </Grid>
                                    <Grid item md={6} sm={6} lg={6} style={{fontSize:14,textAlignLast:"center"}}>
                                        <div className="totalStorageValue">{file_standard} {size_in}</div>
                                        <div>Total Storage</div>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item  md={4} sm={6} lg={4} style={{fontSize:14}}>
                            <div style={{float:"right"}}>Last synced on:</div>
                            <br/>
                            <Tooltip title="Sync now"><RefreshIcon style={{float:"right",fontSize:27,cursor:'pointer'}} onClick={this.refreshSyncedData}></RefreshIcon></Tooltip>{last_synced_on}
                        </Grid>
                    </Grid>
                );
                academic.push(
                    <Grid item  md={4} sm={6} lg={4} style={{fontSize:14}}>
                        <Paper className="Paper">
                            <div className="usageTitle">Academics</div>
                            <Grid container spacing={3}>
                                <Grid item md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.classes_count}</div>
                                    <div>Classes</div>
                                </Grid>
                                <Grid item md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.sections_count}</div>
                                    <div>Sections</div>
                                </Grid>
                                <Grid item md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.subjects_count}</div>
                                    <div>Subjects</div>
                                </Grid>
                                <Grid item md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.attendance_count}</div>
                                    <div>Attendance Records</div>
                                </Grid>
                                <Grid item md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.certificates_count}</div>
                                    <div>Certificates</div>
                                </Grid>
                                <Grid item md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.transcripts_count}</div>
                                    <div>Transcripts</div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                );    
                user.push(
                    <Grid item  md={4} sm={6} lg={4} style={{fontSize:14}}>
                        <Paper className="Paper">
                            <div className="usageTitle">Users</div>
                            <Grid container spacing={3}>
                                <Grid item  md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.admins_count}</div>
                                    <div>Admins</div>
                                </Grid>
                                <Grid item  md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.teachers_count}</div>
                                    <div>Teachers</div>
                                </Grid>
                                <Grid item  md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.students_count}</div>
                                    <div>Students</div>
                                </Grid>
                                <Grid item  md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.alumni_count}</div>
                                    <div>Alumni</div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                );
                learning.push(
                    <Grid item  md={4} sm={6} lg={4} style={{fontSize:14}}>
                        <Paper className="Paper">
                            <div className="usageTitle">LMS</div>
                            <Grid container spacing={3}>
                                <Grid item md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.flip_class_contents_count}</div>
                                    <div>Flip Class Contents</div>
                                </Grid>
                                <Grid item md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.discussion_posts_count}</div>
                                    <div>Discussion Posts</div>
                                </Grid>
                                <Grid item md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.live_lectures_count}</div>
                                    <div>Live Lectures</div>
                                </Grid>
                                <Grid item md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.resources_count}</div>
                                    <div>Resources</div>
                                </Grid>
                                <Grid item md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.assessments_count}</div>
                                    <div>Assessments</div>
                                </Grid>
                                <Grid item md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.quizzes_count}</div>
                                    <div>Quizzes</div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                );
                feesAndCRM.push(
                    <Grid item  md={4} sm={6} lg={4} style={{fontSize:14}}>
                        <Paper className="Paper">
                            <div className="usageTitle">Fees & CRM</div>
                            <Grid container spacing={3}>
                                <Grid item md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.fees_count}</div>
                                    <div>Fees</div>
                                </Grid>
                                <Grid item md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.invoices_count}</div>
                                    <div>Invoices</div>
                                </Grid>
                                <Grid item md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.e_com_orders_count}</div>
                                    <div>Orders</div>
                                </Grid>
                                <Grid item md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.forms_count}</div>
                                    <div>Forms</div>
                                </Grid>
                                <Grid item md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.submissions_count}</div>
                                    <div>Submissions</div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                );
                communication.push(
                    <Grid item  md={4} sm={6} lg={4} style={{fontSize:14}}>
                        <Paper className="Paper">
                            <div className="usageTitle">Communication</div>
                            <Grid container spacing={3}>
                                <Grid item  md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.events_count}</div>
                                    <div>Events</div>
                                </Grid>
                                <Grid item  md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.schedules_count}</div>
                                    <div>Schedules</div>
                                </Grid>
                                <Grid item  md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.notices_count}</div>
                                    <div>Notices</div>
                                </Grid>
                                <Grid item  md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.email_count}</div>
                                    <div>Emails</div>
                                </Grid>
                                <Grid item  md={4} sm={4} lg={4}>
                                    <div className="usageBlock">{userDetails.sms_count}</div>
                                    <div>SMS</div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                );
            }

            let safeBlock,notSafeBlock;
            if(this.state.safeNotsafeData != undefined){
                if(this.state.safeNotsafeData.is_safe_to_delete == true){
                    safeBlock = <div className="safeBlock">
                                    <div style={{fontSize:17}}>
                                        <div>It is safe to delete this tenant as</div> 
                                    </div>
                                    <div>- This customer has never subscribed to Paid Plan.</div>
                                    <div>- No. of students is less than 50.</div>
                                   {/*  <div>- Free trial subscription is ended before 6 months.</div> */}
                                </div>
                }else{
                    notSafeBlock = <div className="notSafeBlock">
                                    <div style={{fontSize:17}}>
                                        <div>It's not safe to delete this tenant as</div>
                                    </div>
                                    {this.state.safeNotsafeData.never_subscribed == false && <div>- This customer has subscribed to a Paid Plan once.</div> }
                                    {this.state.safeNotsafeData.less_student == false && <div>- No. of students is more than 50</div> }
                                    {this.state.safeNotsafeData.ended_before_year == false && <div>- Subscription is ended in the last 6 months.</div>}
                                </div>
                }
            }
            let deleteDialog = <div><Dialog open={this.state.deleteOpen} onClose={this.handleDeleteClose}>
                <DialogTitle>Delete Tenant</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure, you wish to delete this tenant?  
                    </DialogContentText>
                    <Grid container>                        
                        <Grid item xs={12}>
                            <TextField 
                                inputProps={{
                                    ref: this.deletePasswordRef
                                }}
                                id="deletePass"
                                fullWidth 
                                margin='normal'
                                variant="outlined"
                                label="Password"
                                type={'password'}
                                error={this.state.deletePassError ? this.state.deletePassError : ''}                                
                                helperText={this.state.deletePassError ? this.state.deletePassError : ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                inputProps={{
                                    ref: this.deleteKeywordRef
                                }}                               
                                id="deleteKeyword"
                                fullWidth
                                margin='normal' 
                                variant="outlined"                                
                                label="Type DELETE keyword"
                                error={this.state.deleteKeyError ? this.state.deleteKeyError : ''} 
                                helperText={this.state.deleteKeyError ? this.state.deleteKeyError : ''}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{padding:"24px"}}>
                    <Button variant='contained' color='primary' onClick={this.deleteCustomer}>Delete</Button>
                    <Button variant='contained' onClick={this.handleDeleteClose}>Cancel</Button>
                </DialogActions>
            </Dialog></div>
        let passwordDisplay="",passwordCopyColor="",userName="",usernameCopyColor="";
        if(this.state.pcopied == true){   
            passwordCopyColor="green";
        }
        if(this.state.ucopied == true){   
            usernameCopyColor="green";
        }
        if(this.state.useridPass !== undefined){
            passwordDisplay=<div style={{display:"inline-flex"}}>
                                <div>
                                    <input type="password" style={{border:"none", outline:"none"}} value={this.state.useridPass.password}/>
                                </div>
                                <div>
                                    <CopyToClipboard text={this.state.useridPass.password} onCopy={() => this.setState({pcopied: true})}>
                                        <Link><FileCopyIcon style={{fontSize:"18px",color:passwordCopyColor}}></FileCopyIcon></Link>
                                    </CopyToClipboard>
                                </div>
                            </div>
            userName=<div style={{display:"inline-flex"}}>
                        <div style={{width:"180px"}}>
                            {this.state.useridPass.user_name}
                        </div>
                        <div>
                            <CopyToClipboard text={this.state.useridPass.user_name} onCopy={() => this.setState({ucopied: true})}>
                                <Link><FileCopyIcon style={{fontSize:"18px",color:usernameCopyColor}}></FileCopyIcon></Link>
                            </CopyToClipboard>
                        </div>
                    </div>
            
        }
        var tenantUrl="";
        let UserData;                                     
        if ( this.state.isLoading ) {
            UserData = <Loader type='block'/>;
        }else{
            tenantUrl = "https://"+this.state.domain_url+".classe365.com";
            UserData= <div>
                        <Grid container spacing={3} alignItems="center" justify="center">
                            <Grid item xs={6}>
                                <Typography variant="h5" gutterBottom>
                                    {this.state.institute_name}
                                </Typography>        
                            </Grid>
                            <Grid item xs={6} className="addButton">
                                <Link className="Link" onClick={this.domainUrlClick.bind(this,this.state.domain_url)}>
                                    <Linkify>{tenantUrl}</Linkify>
                                </Link>
                            </Grid>
                        </Grid>
                        <div style={{background:'#fff',marginTop:15}}>
                            <AntTabs value={value} onChange={this.handleChange} aria-label="ant example">
                            <AntTab label="GENERAL" />
                            <AntTab label="PAYMENTS" /> 
                            <AntTab label="USAGE" />
                            <AntTab label="SMS"/>
                            <AntTab label="MORE" />                            
                            </AntTabs>
                            <Typography />
                        </div>
                        <div style={{background:'#fff'}}>
                            <TabPanel value={value} index={0}>
                                <div style={{fontSize:14}}>
                                    <Grid container spacing={3} lg={12}  >
                                        <Grid item xs={4} lg={2} md={2} sm={3} >
                                            <div>Tenant URL</div> 
                                            <div>Instutute Name</div> 
                                            <div>Instutute Type</div> 
                                            <div>Student Size</div> 
                                            <div>Contact Person</div>
                                            <div>Email </div>
                                            <div>Phone</div>
                                            <div>Admin Login </div>
                                        </Grid>
                                        <Grid item xs={4} lg={4} md={4} sm={5}>
                                            <div><Link className="Link" onClick={this.domainUrlClick.bind(this,this.state.domain_url)}><Linkify>{tenantUrl}</Linkify></Link></div>
                                            <div>{(this.state.institute_name == null) ? "Not Set":this.state.institute_name}</div>
                                            <div>{(this.state.inst_type == null || this.state.inst_type == "" ) ? "Not Set":this.state.inst_type}</div>
                                            <div>{(this.state.inst_student_size == null) ? "Not Set":this.state.inst_student_size}</div>
                                            <div>{this.state.admin_name}</div>
                                            <div>{(this.state.user_email_id == null) ? "Not Set":this.state.user_email_id}</div>
                                            <div>{(this.state.contact_no == null) ? "Not Set" : this.state.contact_no}</div>
                                            <div>{userName} </div>
                                            <div>{passwordDisplay}</div>
                                        </Grid>
                                        <Grid item xs={6} lg={6} md={6} sm={6}>
                                            {safeBlock}
                                            {notSafeBlock}
                                        </Grid>
                                    </Grid>
                                </div>
                                <div>
                                    {data_table}
                                </div>
                            </TabPanel>   
                            <TabPanel value={value} index={1}>
                                <div style={{display:"inline-flex"}}>
                                    <div style={{fontSize:11}} variant="contained" className={"paymentDataLabel " + this.state.creditCardPayment} onClick={this.paymentDisplay.bind(this,"creditCardPayment")}>Credit Cards</div>
                                    <div style={{marginLeft:5,fontSize:11}} className={"paymentDataLabel " + this.state.bankPayment} onClick={this.paymentDisplay.bind(this,"bankPayment")} variant="contained">Bank Transfers</div>
                                </div>
                                {paymentTable}
                                {overlayLoader}
                                <div className="paymentMessage">{this.state.creditCardMessage}</div>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                {storage}
                                <Grid container spacing={3}>
                                    {academic}
                                    {user}
                                    {learning}
                                    {feesAndCRM}
                                    {communication}
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                {this.state.smsLogs != undefined &&
                                <Grid container spacing={3}>
                                    <Grid item container spacing={3}>
                                        <Grid item xs={12} sm={6} md={3} lg={3}>
                                            <Paper className='paper'>
                                                <div style={{color:"#1976d2",fontWeight:"bold"}}>Total Credits</div> 
                                                <div>
                                                    <div className="countData">
                                                        <div style={{fontSize:23}}>{this.state.smsLogs.total_credit}</div>  
                                                    </div>
                                                </div> 
                                            </Paper>                                            
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3} lg={3}>
                                            <Paper className='paper'>
                                                <div style={{color:"#34ebb4",fontWeight:"bold"}}>Purchased Credits</div> 
                                                <div>
                                                    <div className="countData">
                                                        <div style={{fontSize:23}}>{this.state.smsLogs.purchased_credit}</div>  
                                                    </div>
                                                </div> 
                                            </Paper>                                            
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3} lg={3}>
                                            <Paper className='paper'>
                                                <div style={{color:"#e96353",fontWeight:"bold"}}>Free Credits</div> 
                                                <div>
                                                    <div className="countData">
                                                        <div style={{fontSize:23}}>{this.state.smsLogs.free_credit}</div>  
                                                    </div>
                                                </div> 
                                            </Paper>                                            
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item style={{marginTop:"50px"}}> 
                                   { (config.allowUser.indexOf(role) > -1) &&
                                        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                            <Typography variant='h6'>Credit Topup Log</Typography>
                                            {this.state.showCreditInput == false && <Button variant='contained' color='primary' onClick={()=>{this.setState({'open':true})}}>Add Credits</Button> }
                                        </div> 
                                    }                                       
                                       {this.state.smsLogs.credit_logs.length > 0 && 
                                        <Table stickyHeader={false}>
                                            <TableHead className="root">
                                                <TableRow style={{fontWeight:400}}>
                                                    <TableCell  width="200px">Sr. No.</TableCell>
                                                    <TableCell  width="250px">Date</TableCell>
                                                    <TableCell  width="200px">Credit Type</TableCell>
                                                    <TableCell  width="150px">SMS Credited</TableCell>
                                                    <TableCell  >Balance</TableCell>   
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.smsLogs.credit_logs.map((log,index)=>{

                                                    return(
                                                        <TableRow>
                                                            <TableCell>{index+1}</TableCell>
                                                            <TableCell>{log.date}</TableCell>
                                                            <TableCell>{log.credit_type}</TableCell>
                                                            <TableCell>{log.cradit_amount}</TableCell>
                                                            <TableCell>{log.balance}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}    
                                            </TableBody>                                            
                                        </Table>
                                        }
                                    </Grid>                                     
                                </Grid>
                            }
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <div style={{width:500}}>
                                    {safeBlock}
                                    {notSafeBlock}
                                    {(localStorage.getItem('role') == 'admin') ? <Button variant='contained' style={{background:"#f44336",color:"#fff",marginTop:"25px"}} onClick={this.handleDeleteOpen}><DeleteIcon style={{fontSize:"16px",marginRight:"5px"}}></DeleteIcon>Delete</Button> :''}
                                </div>
                            </TabPanel>
                            
                        </div>
                        {OpenDialog}
                        {deleteDialog}
                    </div>
                    
        }
        return(
            <div>
                {UserData} 
            </div>
        );
    }
    submitForm(e){
        e.preventDefault();
        this.setState({
            isSaveInProgress: true
        });  
    } 
}
export default withSnackbar(profile);