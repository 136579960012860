import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withSnackbar  } from 'notistack';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

class Inactive extends Component {
    constructor(props) {
        super(props); 
        this.state={
            count:[],
            isLoading:true
        };
    }
    componentDidMount(){
      /*  let self=this;
        axios.get( Config.apiBaseURL + '/user_tasks_count')
        .then( response => {
            this.setState({
                count:response.data, 
            });
        })
        .catch(function (error) {
            if(error.response && error.response.status === 500) {                   
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                    self.props.enqueueSnackbar(Config.message.session_expired, {
                    variant: 'error',
                });
            }
            else { // any other error 
                    self.props.enqueueSnackbar('Error! While loading count data.', {
                    variant: 'error',
                });
            }
        })
        .then(function () {
                self.setState({
                   isLoading:false
               })
			}); */
		}
		render(){
           /* if(this.state.isLoading){
                dashboardData=<Loader type='block' /> 
            }else{ */
               
           // }
				return (
					<div>
                        <Grid container spacing={3} lg={12}>
                            <Grid item xs={6}>
                                <Typography variant="h5" gutterBottom >
                                    Inactive Tenants
                                </Typography>       
                            </Grid>
                        </Grid>
                        <div style={{paddingTop:10}}> 
                            <Paper>
                                <Table stickyHeader={false}>
                                    <TableHead className="root">
                                        <TableRow style={{fontWeight:400}}>
                                            <TableCell  width="200px">Name</TableCell>
                                            <TableCell  width="200px">Email</TableCell>
                                            <TableCell  width="200px">Contact</TableCell>
                                            <TableCell>Address</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell >
                                                <div style={{display:"flex",alignItems:"center"}}>
                                                    <img src="https://vignette.wikia.nocookie.net/fan-fiction-library/images/1/15/Admin.png/revision/latest?cb=20140917130743" style={{height:50,width:50,borderRadius:50}}></img>
                                                    <div>
                                                        Bhoomi Patel
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{display:"flex",alignItems:"center"}}>
                                                    <div style={{marginLeft:5}}>bhoomipatel150897@gmail.com</div>
                                                </div>
                                            </TableCell>	
                                            <TableCell>
                                                <div style={{display:"flex",alignItems:"center"}}>
                                                    <div style={{marginLeft:5}}>9875896477</div>
                                                </div>  
                                            </TableCell>
                                            <TableCell>
                                                <div style={{display:"flex",alignItems:"center"}}>
                                                    <div style={{marginLeft:5}}>Ahemdabad</div>
                                                </div>  
                                            </TableCell>
                                            <TableCell>
                                                <div style={{display:"flex",alignItems:"center"}}>
                                                   <AddIcon/>
                                                   <EditIcon/>
                                                   <DeleteIcon/>
                                                </div>  
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell >
                                                <div style={{display:"flex",alignItems:"center"}}>
                                                    <img src="https://vignette.wikia.nocookie.net/fan-fiction-library/images/1/15/Admin.png/revision/latest?cb=20140917130743" style={{height:50,width:50,borderRadius:50}}></img>
                                                    <div>
                                                        Digesha Patel
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{display:"flex",alignItems:"center"}}>
                                                    <div style={{marginLeft:5}}>Digeshapatel15@gmail.com</div>
                                                </div>
                                            </TableCell>	
                                            <TableCell>
                                                <div style={{display:"flex",alignItems:"center"}}>
                                                    <div style={{marginLeft:5}}>8875896477</div>
                                                </div>  
                                            </TableCell>
                                            <TableCell>
                                                <div style={{display:"flex",alignItems:"center"}}>
                                                    <div style={{marginLeft:5}}>Ahemdabad</div>
                                                </div>  
                                            </TableCell>
                                            <TableCell>
                                                <div style={{display:"flex",alignItems:"center"}}>
                                                   <AddIcon/>
                                                   <EditIcon/>
                                                   <DeleteIcon/>
                                                </div>  
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <div style={{display:"flex",alignItems:"center"}}>
                                                    <img src="https://vignette.wikia.nocookie.net/fan-fiction-library/images/1/15/Admin.png/revision/latest?cb=20140917130743" style={{height:50,width:50,borderRadius:50}}></img>
                                                    <div>
                                                        Rajani Patel
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div style={{display:"flex",alignItems:"center"}}>
                                                    <div style={{marginLeft:5}}>rajani97@gmail.com</div>
                                                </div>
                                            </TableCell>	
                                            <TableCell>
                                                <div style={{display:"flex",alignItems:"center"}}>
                                                    <div style={{marginLeft:5}}>7255589647</div>
                                                </div>  
                                            </TableCell>
                                            <TableCell>
                                                <div style={{display:"flex",alignItems:"center"}}>
                                                    <div style={{marginLeft:5}}>Ahemdabad</div>
                                                </div>  
                                            </TableCell>
                                            <TableCell>
                                                <div style={{display:"flex",alignItems:"center"}}>
                                                   <AddIcon/>
                                                   <EditIcon/>
                                                   <DeleteIcon/>
                                                </div>  
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </div>
					</div>
				);
		}
	}
				
export default withSnackbar(Inactive);
