import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Loader from '../../elements/loader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Link} from "react-router-dom";
import { withSnackbar  } from 'notistack';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Config from '../../config/index';
import moment from 'moment';

const axios = require('axios');
axios.defaults.headers.common['Authorization'] = localStorage.getItem('user_token');
const role = localStorage.getItem('role');
class SubscriptionDetails extends Component {
    constructor(props) {
        super(props); 
        this.state={
            tenants:[],
            isLoading:true,
            isSaveInProgress:false,
            userDetails:"",
            userData:"",
            params: { 
                keyword:"", 
                sort: {},
                filter: {},
                page: 0
            }, 
            open:false,
            more_filters_open: false,
            total_tenants: 50,
            records_per_page:10,
            copied:false,
        };
        this.loadSubscription = this.loadSubscription.bind(this);
    }
    componentDidMount(){
        this.loadSubscription(this.state.params);
    }
    loadSubscription= (params, preReset = false) => {
        if ( preReset === true ) {  
            params.page = 0;
            this.setState({
                isLoading: true,
                tenants:[],
                more_filters_open: false
            });            
        }
        let search = window.location.search;
        let getValue = new URLSearchParams(search);
        let keyWord = getValue.get('keyword');
        this.state.keyWord= keyWord
        params.keyword=keyWord
        let self=this;
        axios.get( Config.apiBaseURL + '/tenant/get_module_subscription_details/' +this.props.match.params.id,{params:params})
        .then( response => {
            this.setState({
                subscriptionDetails:response.data.data, 
                params: params,
            });
        })
        .catch(function (error) {  
            if(error.response && error.response.status === 500) {                   
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }
            else { // any other error 
                    self.props.enqueueSnackbar('Error! While loading tenants data.', {
                    variant: 'error',
                });
            }
        })
        .then(function () {
                self.setState({
                   isLoading:false
               })
			});
    }
    Capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    render(){
        let data_table;                                     
        if ( this.state.isLoading ) {
            data_table = <Loader type='block'/>;
        }else{
          
            let subscriptionData = [];
                for(const i in this.state.subscriptionDetails){ 
                    let startDate,endDate,paidOn,type,paidThrough;
                    if(this.state.subscriptionDetails[i].start_date == "" || this.state.subscriptionDetails[i].start_date == null){
                        startDate = "-";
                       
                    }else{
                        startDate=moment(this.state.subscriptionDetails[i].start_date).format("DD MMM YYYY");
                    }
                    if(this.state.subscriptionDetails[i].end_date == "" || this.state.subscriptionDetails[i].end_date == null){
                        endDate = "-";
                    }else{
                        endDate=moment(this.state.subscriptionDetails[i].end_date).format("DD MMM YYYY");
                    }
                    if(this.state.subscriptionDetails[i].paid_on == "" || this.state.subscriptionDetails[i].paid_on == null){
                        paidOn = "-";
                    }else{
                        paidOn=moment(this.state.subscriptionDetails[i].paid_on).format("DD MMM YYYY");
                    }
                    if(this.state.subscriptionDetails[i].type == "" || this.state.subscriptionDetails[i].type == null){
                        type="-"
                    }else{
                        type=this.Capitalize(this.state.subscriptionDetails[i].type)
                    }
                    if(this.state.subscriptionDetails[i].paid_through == "" || this.state.subscriptionDetails[i].paid_through == null){
                        paidThrough="-"
                    }else{
                        paidThrough=this.Capitalize(this.state.subscriptionDetails[i].paid_through)
                    }
                        
                        let paymentStatus;

                        if(this.state.subscriptionDetails[i].payment_status == 1){
                            paymentStatus=<Chip label='In progress' className="chipEffect" style={{backgroundColor:"#1976d2"}}></Chip>
                        }else if(this.state.subscriptionDetails[i].payment_status == 2){
                            paymentStatus=<Chip label='Paid' className="chipEffect" style={{backgroundColor:"#34ebb4"}}></Chip>
                        }else if(this.state.subscriptionDetails[i].payment_status == 3){
                            paymentStatus=<Chip label='Un Paid' className="chipEffect" style={{backgroundColor:"#f44336"}}></Chip>
                        }
                        
                        this.state.subscriptionId=this.state.subscriptionDetails[i].id
                        subscriptionData.push(
                        <TableRow>
                            <TableCell><div className="TableWordBreak">{type} </div></TableCell>
                            <TableCell><div className="TableWordBreak">{paymentStatus}</div></TableCell>
                            <TableCell>{this.state.subscriptionDetails[i].payment_amount}</TableCell>
                            <TableCell style={{minWidth:70}}>{paidOn}</TableCell>
                            <TableCell style={{minWidth:70}}>{paidThrough}</TableCell>
                            {this.state.keyWord == "core"?<TableCell style={{minWidth:70}}>{this.state.subscriptionDetails[i].student_limit}</TableCell>:""}
                            <TableCell style={{minWidth:70}}>{startDate}</TableCell>
                            <TableCell style={{minWidth:70}}>{endDate}</TableCell>
                            <TableCell style={{minWidth:120,fontSize:15}}>  
                            {(Config.allowUser.indexOf(role) > -1)? 
                                <Link  to={{pathname: "/admin/subscription/edit/"+this.props.match.params.id +"?keyword="+this.state.keyWord, data: this.state.subscriptionDetails[i]}}
                                className="Link actionIcon"><EditIcon></EditIcon>
                               </Link>:''}   
                            </TableCell>
                        </TableRow>  
                    );
                } 
                data_table=<div> 
                                <Paper>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Type
                                                </TableCell>
                                                <TableCell>
                                                    Payment Status
                                                </TableCell>
                                                <TableCell>
                                                     Payment Amount
                                                </TableCell>
                                                <TableCell>
                                                     Paid On
                                                </TableCell>
                                                <TableCell>
                                                    Paid Through
                                                </TableCell>
                                                {this.state.keyWord == "core"?<TableCell>Student Limit</TableCell>:""}
                                                <TableCell>
                                                    Start Date
                                                </TableCell>
                                                <TableCell>
                                                        End Date
                                                </TableCell>
                                                <TableCell style={{minWidth:"100px"}}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {subscriptionData}    
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </div>
            }  
            let keyword = {
                "core": "Core Module",
                "fees": "Fees & Invoicing",
                "finance": "Finance",
                "alumni": "Alumni",
                "ecommerce": "E-Commerce",
                "lms": "Learning Management System",
                "admission": "CRM-Enroll & Forms",
                "whitelabel": "White Label",
                "fundraising": "Fund Raising",
                "degreeaudit": "Degree Audit"
            };
            return (
                <div>  
                    <Grid container spacing={3} lg={12} style={{marginBottom:10}}>
                        <Grid item xs={6}>
                            <div style={{display:"inline-flex",alignItems:"center"}}>
                                <Link to={"/admin/profile/"+this.props.match.params.id} ><ArrowBackIcon className="backArrowIconReport"></ArrowBackIcon></Link>
                                <Typography variant="h5">
                                    {keyword[this.state.keyWord]}
                                </Typography>
                            </div>       
                        </Grid>
                        <Grid item xs={6} className="addButton">
                            {(Config.allowUser.indexOf(role) > -1)?
                            <Link  to={"/admin/subscription/add/"+this.props.match.params.id +"?keyword="+this.state.keyWord} className="Link">
                                <Button variant="contained" color="primary" >
                                    Add Subscription
                                </Button>
                            </Link>:''}
                        </Grid>
                    </Grid>
                    {data_table}
                </div>
            );
		}
	}
				
export default withSnackbar(SubscriptionDetails);
