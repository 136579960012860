import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

class Loader extends Component {  
  
  render() {
    let Loader = null;

    if ( this.props.display === false ) {
      return Loader;
    }

    switch ( this.props.type ) {
      case 'block': {
        Loader =<div className="blockLoader">
                  <CircularProgress />
                </div>
        break;  
      }
      case 'inline': {
        Loader = <div className="inlineLoader">
                   <CircularProgress size="1.5rem"/>
                 </div>
        break;  
      }
      default : {
        Loader = <div>
                  <CircularProgress />
                </div>
        break;  
      }  
    }

    return (
      Loader 
    );
  }
  
}
export default Loader;
