import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Redirect} from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import { withSnackbar  } from 'notistack';
import InputError from '../../elements/input_error';
import Loader from '../../elements/loader';
import Config from '../../config/index';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const axios = require('axios');
axios.defaults.headers.common['Authorization'] = localStorage.getItem('user_token');

class SubscriptionAdd extends Component {
    constructor(props) {
        super(props); 
        this.state={ 
            data:[], 
            validationErrors: {},
            isSaveInProgress: false, 
            redirect: false,
            selectedType:"",
            selectedPaid:"",
            selectedStatus:"",
            date:false,
        };
        this.studentLimitInputRef = React.createRef();
        this.startDateInputRef = React.createRef();
        this.endDateInputRef = React.createRef();
        this.amountInputRef = React.createRef();
        this.paidOnInputRef = React.createRef();
        this.transactionIdInputRef = React.createRef();
        this.submitForm = this.submitForm.bind(this);
        this.cancelForm = this.cancelForm.bind(this); 
       
    }
    handleType=(event)=>{
        this.setState({
            selectedType:event.target.value
        })
    }
    handlePaid=(event)=>{
        this.setState({
            selectedPaid:event.target.value
        })
    }
    handleStatus=(event)=>{
        this.setState({
            selectedStatus:event.target.value
        })
    }
    cancelForm = () => {
        this.setState({   
            redirect: true,
        });
    }
    render(){
        
        const { redirect } = this.state;
        let search = window.location.search;
        let getValue = new URLSearchParams(search);
        this.state.keyWord = getValue.get('keyword');
        if (redirect) {
            let url="/admin/subscription_details/"+ this.props.match.params.id +"?keyword="+this.state.keyWord
            return <Redirect to={url} />;
        }
       
        const { selectedType } = this.state;
        const { selectedStatus } = this.state;
        const { selectedPaid } = this.state;
       
        let overlayLoader;
        if(this.state.isSaveInProgress == true){
            overlayLoader=  <div className="loading"> 
                                <Loader type='block' /> 
                            </div>
        }
        return(
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="h5" gutterBottom>
                            Add Subscription Details
                        </Typography>        
                    </Grid>
                </Grid>
                <div style={{maxWidth:600}}>
                    <form method="POST" onSubmit={this.submitForm}>
                        <FormControl variant="outlined" fullWidth style={{marginTop:15}}>
                            <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedType}
                                onChange={this.handleType}
                                label="Type"
                            >
                                <MenuItem value="monthly">Monthly</MenuItem>
                                <MenuItem value="yearly">Yearly</MenuItem>
                            </Select>
                        </FormControl>
                        <InputError field="type" errors={(this.state.validationErrors['type'] != undefined) ? {"type":"Type is required."}: this.state.validationErrors} />
                        <TextField
                            style={{marginTop:24}}
                            inputProps={{
                                ref: this.startDateInputRef
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            id="date"
                            label="Start Date"
                            type="date"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                        ></TextField>
                        <InputError field="start_date" errors={((this.state.validationErrors['start_date'] != undefined) ? ((this.state.validationErrors['start_date'] == "required") ? {"start_date":"Start date is required."} : {"start_date":"Start date is invalid."}) : (this.state.validationErrors))} />
                        <TextField 
                            inputProps={{
                                ref: this.endDateInputRef
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            id="date"
                            label="End Date"
                            type="date"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                        ></TextField>
                        <InputError field="end_date" errors={((this.state.validationErrors['end_date'] != undefined) ? ((this.state.validationErrors['end_date'] == "required") ? {"end_date":"End date is required."} : {"end_date":"End date is invalid."}) : (this.state.validationErrors))} />
                        {
                        (this.state.keyWord == "core")
                            ?
                            (<div>
                                <TextField   
                                    inputProps={{
                                        ref: this.studentLimitInputRef
                                    }}
                                    id="student_limit"
                                    label="Student Limit"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                ></TextField>
                                <InputError field="max" errors={(this.state.validationErrors['max'] != undefined) ? {"max":"Student limit is required."}: this.state.validationErrors} />
                            </div>)
                            :
                                (<div></div>)
                        }
                        <TextField
                            inputProps={{
                                ref: this.amountInputRef
                            }}
                            id="amount"
                            label="Amount"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                        />
                        <InputError field="response_paid_amount" errors={(this.state.validationErrors['response_paid_amount'] != undefined) ? {"response_paid_amount":"Amount is required."}: this.state.validationErrors} /><br/>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="payment-outlined-label">Payment Status</InputLabel>
                            <Select
                                labelId="payment-select-label"
                                id="demo-simple-select"
                                value={selectedStatus}
                                onChange={this.handleStatus}
                                label="Payment Status"
                                >
                                <MenuItem value="1">In Progress</MenuItem>
                                <MenuItem value="2">Paid</MenuItem>
                                <MenuItem value="3">UnPaid</MenuItem>
                            </Select>
                        </FormControl> 
                        <InputError field="payment_status" errors={(this.state.validationErrors['payment_status'] != undefined) ? {"payment_status":"Payment status is required."}: this.state.validationErrors} /><br/>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="demo-simple-select-outlined-label">Paid Through</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedPaid}
                                onChange={this.handlePaid}
                                label="Paid Through"
                                >
                                <MenuItem value="paypal">Paypal</MenuItem>
                                <MenuItem value="eway">Eway</MenuItem>
                                <MenuItem value="stripe">Stripe</MenuItem>
                            </Select>
                        </FormControl>
                        <InputError field="paid_through" errors={(this.state.validationErrors['paid_through'] != undefined) ? {"paid_through":"Paid through is required."}: this.state.validationErrors} />
                        <TextField 
                            style={{marginTop:24}}
                            inputProps={{
                                ref: this.paidOnInputRef
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            id="date"
                            label="Paid On"
                            type="date"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                        ></TextField>
                        <InputError field="paid_on" errors={((this.state.validationErrors['paid_on'] != undefined) ? ((this.state.validationErrors['paid_on'] == "required") ? {"paid_on":"Paid on is required."} : {"paid_on":"Paid on is invalid."}) : (this.state.validationErrors))} />
                        <TextField
                            style={{marginTop:12}}
                            inputProps={{
                                ref: this.transactionIdInputRef
                            }}
                            id="transaction_id"
                            label="Transaction Id"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                        /><br/><br/>
                        <div>
                            <Button variant="contained" size="medium" color="primary" type="submit" >
                                Submit
                            </Button>&nbsp;&nbsp;
                            <Button variant="contained" size="medium" color="defualt" type="Cancel" onClick={this.cancelForm}>
                                Cancel
                            </Button>
                            {overlayLoader}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
    submitForm(e){
        e.preventDefault();
        const selfObj=this;
        if(this.startDateInputRef.current.value > this.endDateInputRef.current.value){
            selfObj.props.enqueueSnackbar('Please select endDate larger than startDate.', {
                variant: 'error',
            });
        }else{
            selfObj.setState({
                isSaveInProgress: true
            });
    
            let startDate,endDate;
            if(this.startDateInputRef.current.value < this.endDateInputRef.current.value){
                startDate = this.startDateInputRef.current.value;
                endDate=this.endDateInputRef.current.value;
            }
            const paidOn=this.paidOnInputRef.current.value;
            const amount= this.amountInputRef.current.value;
            const transactionId= this.transactionIdInputRef.current.value;
            let data={};
            if(this.state.keyWord == "core"){
                const studentLimit=this.studentLimitInputRef.current.value;
                data={
                    "module_type":this.state.keyWord,
                    "student_limit":studentLimit,
                    "type": this.state.selectedType,
                    "start_date": startDate,
                    "end_date": endDate,
                    "amount": amount,
                    "payment_status": this.state.selectedStatus,
                    "paid_on": paidOn,
                    "paid_through": this.state.selectedPaid,
                    "transaction_id": transactionId
                }
            }else{
                data={
                    "module_type":"addon",
                    "keyword": this.state.keyWord,
                    "type": this.state.selectedType,
                    "start_date": startDate,
                    "end_date": endDate,
                    "amount": amount,
                    "payment_status": this.state.selectedStatus,
                    "paid_on": paidOn,
                    "paid_through": this.state.selectedPaid,
                    "transaction_id": transactionId
                }
            }
            axios.post(Config.apiBaseURL +"/tenant/save_module_subscription_details/" +this.props.match.params.id, data)
            .then(response => {  
                if(response.status === 200){
                    if(response.data.success == 0){
                        selfObj.setState({
                            validationErrors:response.data.field_validations,
                        });
                        selfObj.props.enqueueSnackbar('Please double check all form entries.', {
                            variant: 'error',
                        });
                    }
                    if(response.data.success == 1){
                        selfObj.props.enqueueSnackbar('Subscription record created successfully.', {
                            variant: 'success',
                        });
                        selfObj.setState({
                            validationErrors:[],
                            redirect:true
                        });
                    }
                }  
            })
            .catch(function (error){
                //
            })
            .then(function () {
                selfObj.setState({
                    isSaveInProgress: false
                });
            });
        }
    } 
}
export default withSnackbar(SubscriptionAdd);