import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Loader from '../../elements/loader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import  {InputBase, Drawer, Badge, Chip} from '@material-ui/core';
import { withSnackbar  } from 'notistack';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Config from '../../config/index';
import moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import {Link} from "react-router-dom";
import InfoIcon from '@material-ui/icons/Info';

const axios = require('axios');
axios.defaults.headers.common['Authorization'] = localStorage.getItem('user_token');

class InactiveSubscription extends Component {
    constructor(props) {
        super(props); 
        this.state={
            tenants:[],
            isLoading:true,
            isSaveInProgress:false,
            userDetails:"",
            userData:"",
            params: {  
                sort: {"created":"desc"},
                filter: {},
                page: 0
            }, 
            open:false,
            more_filters_open: false,
            total_tenants: 50,
            records_per_page:10,
            copied:false,
            selectedBillingCycle:"",
            selectedCoreStatus:"",
            selectedLmsStatus:"",
            selectedFeesStatus:"",
            selectedCRMStatus:"",
            selectedFinanceStatus:"",
            selectedAlumniStatus:"",
            selectedECommerceStatus:"",
            selectedWhiteLabelStatus:"",
            selectedFundRaisingStatus:"",
            selectedDegreeAuditStatus:"",
        };
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.loadTenant = this.loadTenant.bind(this);
        this.searchtitleInputRef = React.createRef();
        this.instituteNameInputRef = React.createRef();
        this.emailInputRef = React.createRef();
        this.registerFromInputRef = React.createRef();
        this.registerToInputRef = React.createRef();        
        this.subDomainInputRef = React.createRef();               
    }
    componentDidMount(){
        this.loadTenant(this.state.params);
    }
    loadTenant= (params, preReset = false) => {
        if ( preReset === true ) {  
            params.page = 0;
            this.setState({
                isLoading: true,
                tenants:[],
                more_filters_open: false
            });            
        }
        let self=this;
        axios.get( Config.apiBaseURL + '/tenants/inactive_subscription',{params:params})
        .then( response => {            
            this.setState({
                inactive_tenants:response.data.tenants, 
                records_per_page: response.data.records_per_page,
                total_tenants: response.data.total_tenants,
                params: params,
            });
            console.log(this.state.inactive_tenants);
        })
        .catch(function (error) {
            if(error.response && error.response.status === 500) {                   
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }
            else { // any other error 
                    self.props.enqueueSnackbar('Error! While loading deleted users data.', {
                    variant: 'error',
                });
            }
        })
        .then(function () {
            self.setState({
                isLoading:false
            });
        });
    }
    domainUrlClick(url){
        window.open("https://"+url+".classe365.com");
    }
    billingCycleHandle = (event) => {
        this.setState({
            selectedBillingCycle:event.target.value
        })
    }
    coreStatusHandle = (event) => {
        this.setState({
            selectedCoreStatus:event.target.value
        })
    }
    lmsStatusHandle = (event) => {
        this.setState({
            selectedLmsStatus:event.target.value
        })
    }
    feesStatusHandle = (event) => {
        this.setState({
            selectedFeesStatus:event.target.value
        })
    }
    crmStatusHandle = (event) => {
        this.setState({
            selectedCRMStatus:event.target.value
        })
    }
    financeStatusHandle = (event) => {
        this.setState({
            selectedFinanceStatus:event.target.value
        })
    }
    alumniStatusHandle = (event) => {
        this.setState({
            selectedAlumniStatus:event.target.value
        })
    }
    eCommerceStatusHandle = (event) => {
        this.setState({
            selectedECommerceStatus:event.target.value
        })
    }
    whiteLabelStatusHandle = (event) => {
        this.setState({
            selectedWhiteLabelStatus:event.target.value
        })
    }
    fundRaisingStatusHandle = (event) => {
        this.setState({
            selectedFundRaisingStatus:event.target.value
        })
    }
    degreeAuditStatusHandle = (event) => {
        this.setState({
            selectedDegreeAuditStatus:event.target.value
        })
    }
    detectEnter = (e) => {
        if(e.keyCode == 13){
            this.triggerBasicFilters();
        }
    }
    triggerBasicFilters = (e) => {
        const title = this.searchtitleInputRef.current.value;
        let params = this.state.params;
        params.filter['search_keyword'] = ( title !== '' ) ? title : undefined;
        params.filter['domain_url'] = ( title !== '' ) ? title : undefined;
        this.loadTenant(params, true);
    } 
    handleSortChange = (sortAttr, sortDir) => event => {
        let params = this.state.params;
        params.sort = {}; 
        params.sort[sortAttr] = sortDir;
        this.loadTenant(params);
    };
    handleChangePage = (event, newPage) => {
        let params = this.state.params;
        params.page = newPage;
        this.loadTenant(params);
    }; 
    handleFiltersToggle = () => this.setState({more_filters_open: !this.state.more_filters_open});
    triggerMoreFilters = (e) => {
        e.preventDefault();

        let params = this.state.params;
        const institute_name = this.instituteNameInputRef.current.value;
        const email = this.emailInputRef.current.value;
        const registerFrom = this.registerFromInputRef.current.value;
        const registerTo = this.registerToInputRef.current.value;
        const domain_url =this.subDomainInputRef.current.value; 
        const billing_cycle = this.state.selectedBillingCycle;
        const core_status = this.state.selectedCoreStatus;
        const lms_status = this.state.selectedLmsStatus;
        const fees_status = this.state.selectedFeesStatus;
        const crm_status = this.state.selectedCRMStatus;
        const finance_status = this.state.selectedFinanceStatus;
        const alumni_status = this.state.selectedAlumniStatus;  
        const ecommerce_status = this.state.selectedECommerceStatus;
        const whitelabel_status = this.state.selectedWhiteLabelStatus;
        const fundraising_status = this.state.selectedFundRaisingStatus;
        const degreeaudit_status = this.state.selectedDegreeAuditStatus;       
        params.filter['search_keyword'] = ( domain_url !== '' ) ? domain_url : undefined;
        params.filter['institute_name'] = ( institute_name !== '' ) ? institute_name : undefined;
        params.filter['domain_url'] = ( domain_url !== '' ) ? domain_url : undefined;        
        params.filter['user_email_id'] = ( email !== '' ) ? email : undefined;  
        params.filter['activated_date_from'] = ( registerFrom !== '' ) ? registerFrom : undefined;
        params.filter['activated_date_to'] = ( registerTo !== '' ) ? registerTo : undefined; 
        params.filter['billing_cycle'] = ( billing_cycle !== '' ) ? billing_cycle : undefined;
        params.filter['core_status'] = ( core_status !== '' ) ? core_status : undefined;
        params.filter['lms_status'] = ( lms_status !== '' ) ? lms_status : undefined;
        params.filter['fees_status'] = ( fees_status !== '' ) ? fees_status : undefined;
        params.filter['crm_status'] = ( crm_status !== '' ) ? crm_status : undefined;
        params.filter['finance_status'] = ( finance_status !== '' ) ? finance_status : undefined;
        params.filter['alumni_status'] = ( alumni_status !== '' ) ? alumni_status : undefined;
        params.filter['ecommerce_status'] = ( ecommerce_status !== '' ) ? ecommerce_status : undefined;
        params.filter['whitelabel_status'] = ( whitelabel_status !== '' ) ? whitelabel_status : undefined;
        params.filter['fundraising_status'] = ( fundraising_status !== '' ) ? fundraising_status : undefined;
        params.filter['degreeaudit_status'] = ( degreeaudit_status !== '' ) ? degreeaudit_status : undefined;       
        this.loadTenant(params, true);
    }
    resetFilters = () => {
        let params = this.state.params;
        params.filter = {};
        this.searchtitleInputRef.current.value=null;
        this.instituteNameInputRef.current.value=null;
        this.emailInputRef.current.value=null;
        this.subDomainInputRef.current.value=null;        
        this.registerFromInputRef.current.value=null; 
        this.registerToInputRef.current.value=null;
        this.setState({
            selectedBillingCycle:"",
            selectedCoreStatus:"",
            selectedLmsStatus:"",
            selectedFeesStatus:"",
            selectedCRMStatus:"",
            selectedFinanceStatus:"",
            selectedAlumniStatus:"",
            selectedECommerceStatus:"",
            selectedWhiteLabelStatus:"",
            selectedFundRaisingStatus:"",
            selectedDegreeAuditStatus:"",            
        });
        this.loadTenant(params, true);
    }
    render(){
        const { selectedBillingCycle } = this.state;
        const { selectedCoreStatus } = this.state;
        const { selectedLmsStatus } = this.state;
        const { selectedFeesStatus } = this.state;
        const { selectedCRMStatus } = this.state;
        const { selectedFinanceStatus } = this.state;
        const { selectedAlumniStatus } = this.state;
        const { selectedECommerceStatus } = this.state;
        const { selectedWhiteLabelStatus } = this.state;
        const { selectedFundRaisingStatus } = this.state;
        const { selectedDegreeAuditStatus } = this.state;
        let basicFilter;
        let more_filters =  <Drawer
                                anchor="right"
                                open={this.state.more_filters_open}
                                width={500}>
                                <div>
                                    <div className="moreFilterTitle">Filters</div>
                                    <CancelIcon onClick={this.handleFiltersToggle} className="cancelIcon"/>
                                </div>
                                <div  style={{minWidth:400}}>
                                    <form method="POST" onSubmit={this.triggerMoreFilters} 
                                        style={{margin:30,marginTop:20,maxWidth:400}}>
                                        <Grid container spacing={3} lg={12}>
                                            <Grid item xs={6}>
                                                <TextField 
                                                    inputProps={{
                                                        ref: this.instituteNameInputRef
                                                    }}
                                                    id="title"
                                                    label="Institute Name"                                                    
                                                    variant="outlined"
                                                    fullWidth
                                                    defaultValue={(this.state.params.filter['institute_name']) ? this.state.params.filter['institute_name'] : null}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField 
                                                    inputProps={{
                                                        ref: this.subDomainInputRef
                                                    }}
                                                    id="subDomain"
                                                    label="Sub Domain"                                                    
                                                    variant="outlined"
                                                    fullWidth
                                                    defaultValue={(this.state.params.filter['domain_url']) ? this.state.params.filter['domain_url'] : null}
                                                />   
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField 
                                                    inputProps={{
                                                        ref: this.emailInputRef
                                                    }}
                                                    id="email"
                                                    label="Email"                                                    
                                                    variant="outlined"
                                                    fullWidth
                                                    defaultValue={(this.state.params.filter['user_email_id']) ? this.state.params.filter['user_email_id'] : null}
                                                /> 
                                            </Grid>
                                            <Grid item xs={6}>                                                
                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="demo-simple-select-outlined-label">Billing Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Core"
                                                        value={selectedBillingCycle}
                                                        onChange={this.billingCycleHandle}
                                                        >
                                                        <MenuItem value="">Select</MenuItem>
                                                        <MenuItem value="free">Free</MenuItem>
                                                        <MenuItem value="auto">Auto</MenuItem>
                                                        <MenuItem value="manual">Manual</MenuItem>
                                                    </Select>
                                                </FormControl> 
                                            </Grid>
                                        </Grid>                                
                                        <Grid container spacing={3} lg={12}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    inputProps={{
                                                        ref: this.registerFromInputRef
                                                    }}                                                    
                                                    id="date"
                                                    label="Register Date (From)"
                                                    type="date"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                    shrink: true,
                                                    }}
                                                    defaultValue={(this.state.params.filter['activated_date_from']) ? this.state.params.filter['activated_date_from'] : null}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    inputProps={{
                                                        ref: this.registerToInputRef
                                                    }}                                                    
                                                    id="date"
                                                    label="register Date (To)"
                                                    type="date"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                    shrink: true,
                                                    }}
                                                    defaultValue={(this.state.params.filter['activated_date_to']) ? this.state.params.filter['activated_date_to'] : null}
                                                />
                                            </Grid> 
                                        </Grid>
                                        <div style={{fontSize:16,marginTop:20,marginBottom:5,display:'flex',alignItems:'center',flexWrap:'wrap'}}>
                                            Modules Status                                            
                                        </div>
                                        <Grid container spacing={3} lg={12}>
                                                <Grid item xs={6}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">Core</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Core"
                                                            value={selectedCoreStatus}
                                                            onChange={this.coreStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">LMS</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="LMS"
                                                            value={selectedLmsStatus}
                                                            onChange={this.lmsStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">Fees</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Fees"
                                                            value={selectedFeesStatus}
                                                            onChange={this.feesStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">CRM</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="CRM"
                                                            value={selectedCRMStatus}
                                                            onChange={this.crmStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">Finance</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Finance"
                                                            value={selectedFinanceStatus}
                                                            onChange={this.financeStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">Alumni</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Alumni"
                                                            value={selectedAlumniStatus}
                                                            onChange={this.alumniStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">E-commerce</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="E-commerce"
                                                            value={selectedECommerceStatus}
                                                            onChange={this.eCommerceStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">Whitelabel</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Whitelabel"
                                                            value={selectedWhiteLabelStatus}
                                                            onChange={this.whiteLabelStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">Fundraising</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Fundraising"
                                                            value={selectedFundRaisingStatus}
                                                            onChange={this.fundRaisingStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">Degree Audit</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Degree Audit"
                                                            value={selectedDegreeAuditStatus}
                                                            onChange={this.degreeAuditStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        <div style={{marginTop:20,marginBottom:20}} >
                                            <Button variant="contained" size="medium" color="primary" type="submit">
                                                Apply
                                            </Button>&nbsp;&nbsp;
                                            <Button variant="contained" size="medium" onClick={this.resetFilters} type="submit" >
                                                Reset
                                            </Button>
                                        </div> 
                                    </form>  
                                </div> 
                            </Drawer>;  
        let overlayLoader;
        if(this.state.isSaveInProgress == true){
             overlayLoader=  <div className="loading"> 
                                 <Loader type='block' /> 
                             </div>
         }
        let data_table;                                     
        if ( this.state.isLoading ) {
            data_table = <Loader type='block'/>;
        }else{
            let inactiveData = [];
                for(const i in this.state.inactive_tenants){ 
                    var created = (this.state.inactive_tenants[i].created)?this.state.inactive_tenants[i].created.split('T'):this.state.inactive_tenants[i].activated_date.split('T')
                    let date = created[0];
                    var Date= moment(date).format("DD MMM YYYY");
                    var billingCycle = "";
                    if(this.state.inactive_tenants[i].billing_cycle == "auto"){
                        billingCycle = "Auto";
                    }else if(this.state.inactive_tenants[i].billing_cycle == "free"){
                        billingCycle = "Free";
                    }else if(this.state.inactive_tenants[i].billing_cycle == "manual"){
                        billingCycle = "Manual";
                    }
                    let moduleData = [];
                    if((this.state.inactive_tenants[i].lms_status == "Unpaid" || this.state.inactive_tenants[i].lms_status == "Canceled") && 
                        (this.state.inactive_tenants[i].assessments_count > 0 || this.state.inactive_tenants[i].discussion_posts_count > 0 ||
                        this.state.inactive_tenants[i].flip_class_contents_count > 0 || this.state.inactive_tenants[i].quizzes_count > 0 || 
                        this.state.inactive_tenants[i].resources_count > 0 || this.state.inactive_tenants[i].live_lectures_count > 0)){
                        moduleData.push(<div className='TableWordBreak' style={{paddingTop:'10px'}}>Learning Management System&nbsp;&nbsp; 
                                            <Chip size="small" label={(this.state.inactive_tenants[i].discussion_posts_count > 0 ? this.state.inactive_tenants[i].discussion_posts_count :'0')+" Posts"} />&nbsp;
                                            <Chip size="small" label={(this.state.inactive_tenants[i].quizzes_count > 0 ? this.state.inactive_tenants[i].quizzes_count :'0')+" Quizzes"} />&nbsp;
                                            <Chip size="small" label={(this.state.inactive_tenants[i].flip_class_contents_count > 0 ? this.state.inactive_tenants[i].flip_class_contents_count :'0')+" Contents"} />
                                        </div>)
                    }
                    if((this.state.inactive_tenants[i].fees_status == "Unpaid" || this.state.inactive_tenants[i].fees_status == "Canceled") && 
                        (this.state.inactive_tenants[i].fees_count > 0 || this.state.inactive_tenants[i].invoices_count > 0 )){
                        moduleData.push(<div className='TableWordBreak' style={{paddingTop:'10px'}}>Fees & Invoicing&nbsp;&nbsp;&nbsp;
                                            <Chip size="small" label={(this.state.inactive_tenants[i].invoices_count > 0 ? this.state.inactive_tenants[i].invoices_count :'0')+" Invoices"} />&nbsp;
                                        </div>)
                    }
                    if((this.state.inactive_tenants[i].crm_status == "Unpaid" || this.state.inactive_tenants[i].crm_status == "Canceled") && 
                        (this.state.inactive_tenants[i].forms_count > 0 || this.state.inactive_tenants[i].submissions_count > 0 )){
                        moduleData.push(<div className='TableWordBreak' style={{paddingTop:'10px'}}>CRM-Enroll & Forms&nbsp;&nbsp;&nbsp;
                                            <Chip size="small" label={(this.state.inactive_tenants[i].submissions_count > 0 ? this.state.inactive_tenants[i].submissions_count :'0')+" Submissions"} />&nbsp;
                                        </div>)
                    }
                    if((this.state.inactive_tenants[i].alumni_status == "Unpaid" || this.state.inactive_tenants[i].alumni_status == "Canceled") && 
                        (this.state.inactive_tenants[i].alumni_count > 0 )){
                        moduleData.push(<div className='TableWordBreak' style={{paddingTop:'10px'}}>Alumni&nbsp;&nbsp;&nbsp;
                                            <Chip size="small" label={(this.state.inactive_tenants[i].alumni_count > 0 ? this.state.inactive_tenants[i].alumni_count :'0')+" Alumni"} />&nbsp;
                                        </div>)
                    }
                    if((this.state.inactive_tenants[i].ecommerce_status == "Unpaid" || this.state.inactive_tenants[i].ecommerce_status == "Canceled") && 
                        (this.state.inactive_tenants[i].e_com_orders_count > 0)){
                        moduleData.push(<div className='TableWordBreak' style={{paddingTop:'10px'}}>E-Commerce&nbsp;&nbsp;&nbsp;
                                            <Chip size="small" label={(this.state.inactive_tenants[i].e_com_orders_count > 0 ? this.state.inactive_tenants[i].e_com_orders_count :'0')+" Orders"} />&nbsp;
                                        </div>)
                    }
                    inactiveData.push(
                        <TableRow>
                            <TableCell><div className='TableWordBreak'>{this.state.inactive_tenants[i].institute_name}</div><div className='TableWordBreak'><Link to="#" className="Link" onClick={this.domainUrlClick.bind(this,this.state.inactive_tenants[i].domain_url)}>{this.state.inactive_tenants[i].domain_url}.classe365.com</Link></div><div className='TableWordBreak'>{this.state.inactive_tenants[i].user_email_id}</div></TableCell>
                            <TableCell>{moduleData}</TableCell>
                            <TableCell>{billingCycle}</TableCell>
                            <TableCell style={{maxWidth:70}}>{Date}</TableCell>
                            <TableCell>
                                <Tooltip title="View"><Link  to={"/admin/profile/"+this.state.inactive_tenants[i].id } className="actionIcon" ><InfoIcon></InfoIcon></Link></Tooltip>
                            </TableCell> 
                        </TableRow>  
                    );
                }
                basicFilter= <div className="adminSearchBox" style={{marginBottom:"20px"}}>
                            <div>
                                <SearchIcon className="searchIcon" onClick={this.triggerBasicFilters}/>
                            </div>   
                            <div>
                                <InputBase
                                    style={{width: "calc(100% - 10px)"}}
                                    placeholder="Search Customers"
                                    inputProps={{
                                        ref: this.searchtitleInputRef
                                    }}
                                    onKeyUp={this.detectEnter}
                                    defaultValue={(this.state.params.filter['search_keyword']) ? this.state.params.filter['search_keyword'] : null} 
                                />
                            </div>
                            <div style={{width:100}}>
                                <div onClick={this.handleFiltersToggle} className="moreFilterLabel" >More Filters</div>
                            </div>
                        </div>  
                data_table=<div> 
                                <Paper>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <TableSortLabel 
                                                        active={ (this.state.params.sort.institute_name) ? true : false} 
                                                        direction={ (this.state.params.sort.institute_name) ? this.state.params.sort.institute_name.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('institute_name', (this.state.params.sort.institute_name && this.state.params.sort.institute_name.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        Institute
                                                    </TableSortLabel>
                                                </TableCell>                                                
                                                <TableCell>
                                                    Inactive Module
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={ (this.state.params.sort.billing_cycle) ? true : false} 
                                                        direction={ (this.state.params.sort.billing_cycle) ? this.state.params.sort.billing_cycle.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('billing_cycle', (this.state.params.sort.billing_cycle && this.state.params.sort.billing_cycle.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        Billing Type
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={ (this.state.params.sort.created) ? true : false} 
                                                        direction={ (this.state.params.sort.created) ? this.state.params.sort.created.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('created', (this.state.params.sort.created && this.state.params.sort.created.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        Registered On
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell style={{maxWidth:70}}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {inactiveData}    
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[]}
                                                    count={this.state.total_tenants}
                                                    rowsPerPage={this.state.records_per_page}
                                                    page={this.state.params.page ? this.state.params.page : 0}
                                                    onChangePage={this.handleChangePage}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </Paper>
                            </div>
            }  
            return (
                <div>
                    <Grid container spacing={3} lg={12}>
                        <Grid item xs={6}>
                            <Typography variant="h5" gutterBottom >
                            Inactive Subscriptions
                            </Typography>       
                        </Grid>
                    </Grid>
                    {overlayLoader}
                    {more_filters}
                    {basicFilter}
                    {data_table}
                </div>
            );
		}
	}
				
export default withSnackbar(InactiveSubscription);
