import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Message from './elements/message';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import Container from '@material-ui/core/Container';
import Config from './config/index';  
const axios = require('axios');

const styles = {
  '@global': {
    body: {
      backgroundColor: '#FFFFFF',
    },
  },
  paper: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 16,
  },
  submit: {
    marginTop: 24,
    marginBottom: 16,
    paddingTop: 12,
    paddingBottom: 12,
  },
};

class SignIn extends Component {
  constructor(props) {
        super(props); 
        this.state={
            btnLoading: false,
            validationErrors: {},
            flashMessageDisplay: true,
        };

        this.emailInputRef = React.createRef();
        this.passwordInputRef = React.createRef();
        this.submitForm = this.submitForm.bind(this);
    }

    submitForm(e) {
        e.preventDefault();
        this.setState({
            btnLoading : true
        });
      
        const email = this.emailInputRef.current.value;
        const password =this.passwordInputRef.current.value;
       
        const selfObj = this;
        axios.post(Config.apiBaseURL + '/login',{email: email, password: password})
            .then( response => {
                const admin = response.data.token; 
                localStorage.setItem('authenticated', true); 
                localStorage.setItem('user_token', response.data.token); 
                localStorage.setItem('admin', JSON.stringify(admin) );
                localStorage.setItem('role', response.data.user.role);
                window.location = '/admin';
            })
            .catch(function (error) {          
                if (error.response && error.response.status === 400) {
                    selfObj.setState({
                        flashMessage: error.response.data.message,
                        btnLoading : false
                    })
                }
                else {
                    selfObj.setState({
                        flashMessage: error.response.data,
                        btnLoading : false
                    });
                }     
            })
            .then(function () {
                 //
            });    
        }
    render (){
        const { classes } = this.props;
        if(localStorage.getItem('logout')){
            this.props.enqueueSnackbar("Your login session is expired. Please login again.", {
                variant: 'error',
            });
        }
        localStorage.clear();
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Box mb={{ xs: 2, sm: 2, md: 4, lg: 5 }} >
                        <Typography component="h1" variant="h4">
                            {Config.projectName}
                        </Typography>
                    </Box>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
            
                    <form className={classes.form} noValidate onSubmit={this.submitForm}>
                        <Message type="error" text={this.state.flashMessage} />
                        <TextField
                            inputProps={{
                                ref: this.emailInputRef
                            }}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            inputProps={{
                                ref: this.passwordInputRef
                            }}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            disabled = { (this.state.btnLoading) ? true : false }
                            variant="contained"
                            color="primary"
                            className={ (this.state.btnLoading) ? classes.submit + ' btnLoading' : classes.submit }
                        >
                        Sign In
                        </Button>
                    </form>
                </div>
            </Container>
        );
    }  
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};
const StyledSignIn = withStyles(styles)(SignIn);
export default withSnackbar(StyledSignIn);
