const dataArrays = {
    countries:{
    '1': 'Afghanistan', '2': 'Albania',
    '3': 'Algeria', '4': 'Andorra',
    '5': 'Angola', '6': 'Antigua and Barbuda',
    '7': 'Argentina', '8': 'Armenia',
    '9': 'Australia', '10': 'Austria',
    '11': 'Azerbaijan', '12': 'Bahamas',
    '13': 'Bahrain', '14': 'Bangladesh',
    '15': 'Barbados', '16': 'Belarus',
    '17': 'Belgium', '18': 'Belize',
    '19': 'Benin', '20': 'Bhutan',
    '21': 'Bolivia', '22': 'Bosnia and Herzegovina',
    '23': 'Botswana', '24': 'Brazil',
    '25': 'Brunei', '26': 'Bulgaria',
    '27': 'Burkina Faso', '28': 'Burundi',
    '29': 'Cambodia', '30': 'Cameroon',
    '31': 'Canada', '32': 'Cape Verde',
    '33': 'Central African Republic', '34': 'Chad',
    '35': 'Chile', '36': 'China',
    '37': 'Colombia', '38': 'Comoros',
    '39': 'Republic of the Congo', '40': 'Democratic Republic of the Congo',
    '41': 'Costa Rica', '42': 'Croatia',
    '43': 'Cuba', '44': 'Cyprus',
    '45': 'Czechia', '46': 'Denmark',
    '47': 'Djibouti', '48': 'Dominica',
    '49': 'Dominican Republic', '50': 'East Timor',
    '51': 'Ecuador', '52': 'Egypt',
    '53': 'El Salvador', '54': 'Equatorial Guinea',
    '55': 'Eritrea', '56': 'Estonia',
    '57': 'Ethiopia', '58': 'Fiji',
    '59': 'Finland', '60': 'France',
    '61': 'Gabon', '62': 'Gambia',
    '63': 'Georgia', '64': 'Germany',
    '65': 'Ghana', '66': 'Greece',
    '67': 'Grenada', '68': 'Guatemala',
    '69': 'Guinea', '70': 'Guinea-Bissau',
    '71': 'Guyana', '72': 'Haiti',
    '73': 'Honduras', '74': 'Hungary',
    '75': 'Iceland', '76': 'India',
    '77': 'Indonesia', '78': 'Iran',
    '79': 'Iraq', '80': 'Ireland',
    '81': 'Israel', '82': 'Italy',
    '83': 'Ivory Coast', '84': 'Jamaica',
    '85': 'Japan', '86': 'Jordan',
    '87': 'Kazakhstan', '88': 'Kenya',
    '89': 'Kiribati', '90': 'North Korea',
    '91': 'South Korea', '92': 'Kosovo',
    '93': 'Kuwait', '94': 'Kyrgyzstan',
    '95': 'Laos', '96': 'Latvia',
    '97': 'Lebanon', '98': 'Lesotho',
    '99': 'Liberia', '100': 'Libya',
    '101': 'Liechtenstein', '102': 'Lithuania',
    '103': 'Luxembourg', '104': 'Macedonia',
    '105': 'Madagascar', '106': 'Malawi',
    '107': 'Malaysia', '108': 'Maldives',
    '109': 'Mali', '110': 'Malta',
    '111': 'Marshall Islands', '112': 'Mauritania',
    '113': 'Mauritius', '114': 'Mexico',
    '115': 'Micronesia', '116': 'Moldova',
    '117': 'Monaco', '118': 'Mongolia',
    '119': 'Montenegro', '120': 'Morocco',
    '121': 'Mozambique', '122': 'Myanmar [Burma]',
    '123': 'Namibia', '124': 'Nauru',
    '125': 'Nepal', '126': 'Netherlands',
    '127': 'New Zealand', '128': 'Nicaragua',
    '129': 'Niger', '130': 'Nigeria',
    '131': 'Norway', '132': 'Oman',
    '133': 'Pakistan', '134': 'Palau',
    '135': 'Panama', '136': 'Papua New Guinea',
    '137': 'Paraguay', '138': 'Peru',
    '139': 'Philippines', '140': 'Poland',
    '141': 'Portugal', '142': 'Qatar',
    '143': 'Romania', '144': 'Russia',
    '145': 'Rwanda', '146': 'Saint Kitts and Nevis',
    '147': 'Saint Lucia', '148': 'Saint Vincent and the Grenadines',
    '149': 'Samoa', '150': 'San Marino',
    '151': 'São Tomé and Príncipe', '152': 'Saudi Arabia',
    '153': 'Senegal', '154': 'Serbia',
    '155': 'Seychelles', '156': 'Sierra Leone',
    '157': 'Singapore', '158': 'Slovakia',
    '159': 'Slovenia', '160': 'Solomon Islands',
    '161': 'Somalia', '162': 'South Africa',
    '163': 'South Sudan', '164': 'Spain',
    '165': 'Sri Lanka', '166': 'Sudan',
    '167': 'Suriname', '168': 'Swaziland',
    '169': 'Sweden', '170': 'Switzerland',
    '171': 'Syria', '172': 'Taiwan',
    '173': 'Tajikistan', '174': 'Tanzania',
    '175': 'Thailand', '176': 'Togo',
    '177': 'Tonga', '178': 'Trinidad and Tobago',
    '179': 'Tunisia', '180': 'Turkey',
    '181': 'Turkmenistan', '182': 'Tuvalu',
    '183': 'Uganda', '184': 'Ukraine',
    '185': 'United Arab Emirates', '186': 'United Kingdom',
    '187': 'United States', '188': 'Uruguay',
    '189': 'Uzbekistan', '190': 'Vanuatu',
    '191': 'Vatican City', '192': 'Venezuela',
    '193': 'Vietnam', '194': 'Yemen',
    '195': 'Zambia', '196': 'Zimbabwe',
    '197': 'Other', '198': 'Palestine',
    '199': 'ky', '200': 'undefined',
    '201': 'tc', '202': 'as',
    '203': 'hk', '204': 'gi',
    '205': 'vi', '206': 'pr',
    '207': 'bq', '208': 'mo',
    '209': 'bm', '210': 'vg',
    '211': 'sx', '212': 'cw',
    '213': 'mp', '214': 're',
    '215': 'eh'
    },
    exportheaders : [
        { label: "User Email Id", key: "user_email_id" },	
        { label: "Admin Name", key: "admin_name" },	
        { label: "Institute Name", key: "institute_name" },
        { label: "Domain URL", key: "domain_url" },
        { label: "Username", key: "username" },
        { label: "Tenant Setup", key: "tenant_setup" },	
        { label: "Phone Code", key: "phone_code" },	
        { label: "Contact No", key: "contact_no" },	
        { label: "Country", key: "country" },	
        { label: "Admin Contact", key: "admin_contact" },	
        { label: "Status", key: "status" },	
        { label: "User Type", key: "user_type" },	
        { label: "Classes Count", key: "classes_count" },		
        { label: "Sections Count", key: "sections_count" },		
        { label: "Subjects Count", key: "subjects_count	" },	
        { label: "Admins Count", key: "admins_count" },		
        { label: "Students Count", key: "students_count" },		
        { label: "Alumni Count", key: "alumni_count" },		
        { label: "Trachres Count", key: "teachers_count" },		
        { label: "Attendance Count", key: "attendance_count" },		
        { label: "Certificates Count", key: "certificates_count" },		
        { label: "Transcript Count", key: "transcripts_count" },		
        { label: "Institute Student Size", key: "inst_student_size" },		
        { label: "Institute Type", key: "inst_type" },		
        { label: "Institute User Organization Role", key: "inst_user_org_role" },		
        { label: "Flip Class Contents Count", key: "flip_class_contents_count" },		
        { label: "Discussion Posts Count", key: "discussion_posts_count" },		
        { label: "Assessmnets Count", key: "assessments_count" },		
        { label: "Quizzes Count", key: "quizzes_count" },		
        { label: "Live Lectures Count", key: "live_lectures_count" },		
        { label: "Resources Count", key: "resources_count" },		
        { label: "Events Count", key: "events_count" },		
        { label: "Schedules Count", key: "schedules_count" },		
        { label: "Notices Count", key: "notices_count" },		
        { label: "Fees Count", key: "fees_count" },		
        { label: "Invoices Count", key: "invoices_count" },		
        { label: "Ecommerce Orders Count", key: "e_com_orders_count" },		
        { label: "Forms Count", key: "forms_count" },		
        { label: "Submissions Count", key: "submissions_count" },		
        { label: "DB Size", key: "db_size" },
        { label: "File Size", key: "file_size" },		
        { label: "Tenant Size", key: "tenant_size" },		
        { label: "Core Module Status", key: "core_status" },		
        { label: "LMS Module Status", key: "lms_status" },		
        { label: "Fees Module Status", key: "fees_status" },		
        { label: "CRM Module Status", key: "crm_status" },		
        { label: "Finance Module Status", key: "finance_status" },		
        { label: "Alumni Module Status", key: "alumni_status" },	
        { label: "Ecommerce Module Status", key: "ecommerce_status" },	
        { label: "WhiteLabel Module Status", key: "whitelabel_status" },	
        { label: "Fund Rising Module Status", key: "fundraising_status" },	
        { label: "Degree Audit Module Status", key: "degreeaudit_status" },	
        { label: "Billing Cycle", key: "billing_cycle" },	
        { label: "Activated Date", key: "activated_date" },	
        { label: "Paid Subscription Start Date", key: "paid_sub_start_date" },	
        { label: "Subscription Renew Date", key: "sub_renew_date" },
        { label: "Subscription Cancellation Date", key: "sub_cancellation_date"},
        { label: "Subscription Cancellation Reason", key: "sub_cancel_reason"},
        { label: "Subscription Cancellation Description", key: "sub_cancel_description"},	
        { label: "Last Synced On", key: "last_synced_on" },	
        { label: "Last Visited On", key: "last_visit_on" },	
      ]
}
export default dataArrays;