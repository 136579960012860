import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Loader from '../../elements/loader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Link} from "react-router-dom";
import SearchIcon from '@material-ui/icons/Search';
import  {InputBase, Drawer, DialogContentText} from '@material-ui/core';
import { withSnackbar  } from 'notistack';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Config from '../../config/index';
import BlockIcon from '@material-ui/icons/Block';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DeleteIcon from '@material-ui/icons/Delete';
import dataArrays from './dataArrays';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@material-ui/icons/GetApp';
import config from '../../config/index';


const axios = require('axios');
axios.defaults.headers.common['Authorization'] = localStorage.getItem('user_token');
const countries = dataArrays.countries;
const exportheaders = dataArrays.exportheaders;
const role = localStorage.getItem('role');
let allTenants = [];
class Customers extends Component {
    constructor(props) {
        super(props); 
        this.state={
            tenants:[],
            isLoading:true,
            isSaveInProgress:false,
            userDetails:"",
            userData:"",
            params: {  
                sort: {},
                filter: {},
                page: 0
            }, 
            open:false,
            more_filters_open: false,
            total_tenants: 50,
            records_per_page:10,
            pcopied:false,
            ucopied:false,
            wantToDelete : 0,
            deletePassError:false,
            deleteKeyError:false,
            deleteOpen:false,
            selectedInstituteType:"",
            selectedBillingCycle:"",
            selectedCoreStatus:"",
            selectedLmsStatus:"",
            selectedFeesStatus:"",
            selectedCRMStatus:"",
            selectedFinanceStatus:"",
            selectedAlumniStatus:"",
            selectedECommerceStatus:"",
            selectedWhiteLabelStatus:"",
            selectedFundRaisingStatus:"",
            selectedDegreeAuditStatus:"",
            selectedcountry:"",                        
        };
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.modelOpen = this.modelOpen.bind(this);
        this.loadTenant = this.loadTenant.bind(this);       
        this.searchtitleInputRef = React.createRef();
        this.instituteNameInputRef = React.createRef();
        this.emailInputRef = React.createRef();
        this.registerFromInputRef = React.createRef();
        this.registerToInputRef = React.createRef();
        this.subscriptionRenewFromInputRef = React.createRef();
        this.subscriptionRenewToInputRef = React.createRef();
        this.subscriptionCancelFromInputRef = React.createRef();
        this.subscriptionCancelToInputRef = React.createRef();
        this.paidSubscriptionStartFromInputRef = React.createRef();
        this.paidSubscriptionStartToInputRef = React.createRef();
        this.phoneInputRef = React.createRef();
        this.subDomainInputRef = React.createRef();
        this.instStudentSizeInputRef = React.createRef();
        this.contactPersonInputRef = React.createRef();
        this.classCountInputRef = React.createRef();
        this.sectionCountInputRef = React.createRef();
        this.subjectCountInputRef = React.createRef();
        this.adminsCountInputRef = React.createRef();
        this.studentsCountInputRef = React.createRef();
        this.alumniCountInputRef = React.createRef();
        this.teacherCountInputRef = React.createRef(); 
        this.attendanceCountInputRef = React.createRef(); 
        this.certificatesCountInputRef = React.createRef(); 
        this.transcriptsCountInputRef = React.createRef(); 
        this.flipClassContentsCountInputRef = React.createRef(); 
        this.discussionPostsCountInputRef = React.createRef(); 
        this.assessmentsCountInputRef = React.createRef(); 
        this.quizzesCountInputRef = React.createRef();
        this.liveLecturesCountInputRef = React.createRef();
        this.resourcesCountInputRef = React.createRef();
        this.eventsCountInputRef = React.createRef();
        this.schedulesCountInputRef = React.createRef();
        this.noticesCountInputRef = React.createRef();
        this.feesCountInputRef = React.createRef();
        this.invoicesCountInputRef = React.createRef();
        this.eComOrdersCountInputRef = React.createRef();
        this.formsCountInputRef = React.createRef();
        this.submissionsCountInputRef = React.createRef();
        this.dbSizeInputRef = React.createRef();
        this.fileSizeInputRef = React.createRef();
        this.deletePasswordRef = React.createRef();
        this.deleteKeywordRef = React.createRef();
    }
    componentDidMount(){
        let parameter = this.state.params;
        if(localStorage.getItem('requested_for') != null){
            if(localStorage.getItem('requested_for') == 'upcoming_renewals'){
                parameter.sort.sub_renew_date = "asc";
                parameter.filter.billing_cycle = "manual";
                parameter.page = 0;
                this.setState({
                    selectedBillingCycle: "manual",
                    params: parameter
                })
            }else if(localStorage.getItem('requested_for') == 'failed_renewals'){
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = today.getFullYear();
                today = yyyy + '-' + mm + '-' + dd;
                parameter.sort.sub_renew_date = "desc";
                parameter.filter = {'subscription_renew_to':today};
                parameter.page = 0;
                this.setState({
                    params: parameter,
                })
            }
            localStorage.removeItem('requested_for');
        }
        this.loadTenant(this.state.params);
        this.loadAllTenant(this.state.params)
    }
    loadTenant= (params, preReset = false) => {
        delete params['reqFor']
        if ( preReset === true ) {  
            params.page = 0;
            this.setState({
                isLoading: true,
                tenants:[],
                more_filters_open: false
            });            
        }
        let self=this;
        axios.get( Config.apiBaseURL + '/tenants',{params:params})
        .then( response => {
            this.setState({
                tenants:response.data.tenants, 
                records_per_page: response.data.records_per_page,
                total_tenants: response.data.total_tenants,
                params: params,
            });
        })
        .catch(function (error) {
            if(error.response && error.response.status === 500) {                   
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }
            else { // any other error 
                    self.props.enqueueSnackbar('Error! While loading tenants data.', {
                    variant: 'error',
                });
            }
        })
        .then(function () {
                self.setState({
                   isLoading:false
               })
			});
    }
    loadAllTenant= (params,preReset=false) => {
        
        if ( preReset === true ) {  
            params.page = 0 
            this.setState({
                isLoading: true,
                more_filters_open: false
            });            
        }
        params.reqFor="export"
        let self=this;
        axios.get( Config.apiBaseURL + '/tenants',{params:params})
        .then( response => {                
            delete params['reqFor']
            allTenants = [];
            response.data.tenants.map(tenant=>{
                let tempTenent = tenant;
                tempTenent.tenant_setup = (tenant.tenant_setup == 1) ? 'Yes' : 'No'
                tempTenent.country = (tenant.country != '') ? countries[tenant.country]: '';
                allTenants.push(tempTenent);
            })
            this.setState({                                
                params: params,
            });                     
        })
        .catch(function (error) {
            delete params['reqFor']
            if(error.response && error.response.status === 500) {                   
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }
            else { // any other error 
                    self.props.enqueueSnackbar('Error! While loading tenants data.', {
                    variant: 'error',
                });
            }            
        })
        .then(function () {
            delete params['reqFor']
            self.setState({
                isLoading:false
            })
        });
    } 
    detectEnter = (e) => {
        if(e.keyCode == 13){
            this.triggerBasicFilters();
        }
    }
    triggerBasicFilters = (e) => {
        const title = this.searchtitleInputRef.current.value;
        let params = this.state.params;
        params.filter['search_keyword'] = ( title !== '' ) ? title : undefined;
        params.filter['domain_url'] = ( title !== '' ) ? title : undefined;
        this.loadTenant(params, true);
        this.loadAllTenant(params,true);
    } 
    handleSortChange = (sortAttr, sortDir) => event => {
        let params = this.state.params;
        params.sort = {}; 
        params.sort[sortAttr] = sortDir;
        this.loadTenant(params);
        this.loadAllTenant(params);
    };
    handleChangePage = (event, newPage) => {
        let params = this.state.params;
        params.page = newPage;
        this.loadTenant(params);        
    }; 
    handleFiltersToggle = () => this.setState({more_filters_open: !this.state.more_filters_open});
    triggerMoreFilters = (e) => {
        e.preventDefault();
        let params = this.state.params;
       
        const institute_name = this.instituteNameInputRef.current.value;
        const email = this.emailInputRef.current.value;
        const registerFrom = this.registerFromInputRef.current.value;
        const registerTo = this.registerToInputRef.current.value;
        const subRenewFrom = this.subscriptionRenewFromInputRef.current.value;
        const subRenewTo = this.subscriptionRenewToInputRef.current.value;
        const subCancelFrom = this.subscriptionCancelFromInputRef.current.value;
        const subCancelTo = this.subscriptionCancelToInputRef.current.value;
        const paidSubStartFrom = this.paidSubscriptionStartFromInputRef.current.value;
        const paidSubStartTo = this.paidSubscriptionStartToInputRef.current.value;
        const billing_cycle = this.state.selectedBillingCycle;
        const contact_no = this.phoneInputRef.current.value;
        const domain_url =this.subDomainInputRef.current.value;
        const institute_type = this.state.selectedInstituteType;
        const inst_student_size = this.instStudentSizeInputRef.current.value;
        const contact_person=this.contactPersonInputRef.current.value;
        const classes_count = this.classCountInputRef.current.value;
        const sections_count = this.sectionCountInputRef.current.value;
        const subjects_count = this.subjectCountInputRef.current.value;
        const admins_count = this.adminsCountInputRef.current.value;
        const students_count = this.studentsCountInputRef.current.value;
        const alumni_count = this.alumniCountInputRef.current.value;
        const teachers_count = this.teacherCountInputRef.current.value;
        const attendance_count = this.attendanceCountInputRef.current.value;
        const certificates_count = this.certificatesCountInputRef.current.value;
        const transcripts_count = this.transcriptsCountInputRef.current.value;
        const flip_class_contents_count = this.flipClassContentsCountInputRef.current.value;
        const discussion_posts_count = this.discussionPostsCountInputRef.current.value;
        const assessments_count = this.assessmentsCountInputRef.current.value;
        const quizzes_count = this.quizzesCountInputRef.current.value;
        const live_lectures_count = this.liveLecturesCountInputRef.current.value;
        const resources_count = this.resourcesCountInputRef.current.value;
        const events_count = this.eventsCountInputRef.current.value;
        const schedules_count = this.schedulesCountInputRef.current.value;
        const notices_count = this.noticesCountInputRef.current.value;
        const fees_count = this.feesCountInputRef.current.value;
        const invoices_count = this.invoicesCountInputRef.current.value;
        const e_com_orders_count = this.eComOrdersCountInputRef.current.value;
        const forms_count = this.formsCountInputRef.current.value;
        const submissions_count = this.submissionsCountInputRef.current.value;
        const db_size = this.dbSizeInputRef.current.value;
        const file_size = this.fileSizeInputRef.current.value;
        const core_status = this.state.selectedCoreStatus;
        const lms_status = this.state.selectedLmsStatus;
        const fees_status = this.state.selectedFeesStatus;
        const crm_status = this.state.selectedCRMStatus;
        const finance_status = this.state.selectedFinanceStatus;
        const alumni_status = this.state.selectedAlumniStatus;  
        const ecommerce_status = this.state.selectedECommerceStatus;
        const whitelabel_status = this.state.selectedWhiteLabelStatus;
        const fundraising_status = this.state.selectedFundRaisingStatus;
        const degreeaudit_status = this.state.selectedDegreeAuditStatus;
        const country = this.state.selectedcountry;
        
        params.filter['search_keyword'] = ( domain_url !== '' ) ? domain_url : undefined;
        params.filter['institute_name'] = ( institute_name !== '' ) ? institute_name : undefined;
        params.filter['domain_url'] = ( domain_url !== '' ) ? domain_url : undefined;
        params.filter['inst_type'] = ( institute_type !== '' ) ? institute_type : undefined;
        params.filter['inst_student_size'] = ( inst_student_size !== '' ) ? inst_student_size : undefined;
        params.filter['username'] = ( contact_person !== '' ) ? contact_person : undefined;
        params.filter['contact_no'] = ( contact_no !== '' ) ? contact_no : undefined;
        params.filter['user_email_id'] = ( email !== '' ) ? email : undefined;  
        params.filter['billing_cycle'] = ( billing_cycle !== '' ) ? billing_cycle : undefined;
        params.filter['activated_date_from'] = ( registerFrom !== '' ) ? registerFrom : undefined;
        params.filter['activated_date_to'] = ( registerTo !== '' ) ? registerTo : undefined;
        params.filter['subscription_renew_from'] = ( subRenewFrom !== '' ) ? subRenewFrom : undefined;
        params.filter['subscription_renew_to'] = ( subRenewTo !== '' ) ? subRenewTo : undefined;
        params.filter['sub_cancellation_from'] = ( subCancelFrom !== '' ) ? subCancelFrom : undefined;
        params.filter['sub_cancellation_to'] = ( subCancelTo !== '' ) ? subCancelTo : undefined;
        params.filter['paid_sub_start_from'] = ( paidSubStartFrom !== '' ) ? paidSubStartFrom : undefined;
        params.filter['paid_sub_start_to'] = ( paidSubStartTo !== '' ) ? paidSubStartTo : undefined;
        params.filter['classes_count'] = ( classes_count !== '' ) ? classes_count : undefined;
        params.filter['sections_count'] = ( sections_count !== '' ) ? sections_count : undefined;
        params.filter['subjects_count'] = ( subjects_count !== '' ) ? subjects_count : undefined;
        params.filter['admins_count'] = ( admins_count !== '' ) ? admins_count : undefined;
        params.filter['students_count'] = ( students_count !== '' ) ? students_count : undefined;
        params.filter['alumni_count'] = ( alumni_count !== '' ) ? alumni_count : undefined;
        params.filter['teachers_count'] = ( teachers_count !== '' ) ? teachers_count : undefined;
        params.filter['attendance_count'] = ( attendance_count !== '' ) ? attendance_count : undefined;
        params.filter['certificates_count'] = ( certificates_count !== '' ) ? certificates_count : undefined;
        params.filter['transcripts_count'] = ( transcripts_count !== '' ) ? transcripts_count : undefined;
        params.filter['flip_class_contents_count'] = ( flip_class_contents_count !== '' ) ? flip_class_contents_count : undefined;
        params.filter['discussion_posts_count'] = ( discussion_posts_count !== '' ) ? discussion_posts_count : undefined;
        params.filter['assessments_count'] = ( assessments_count !== '' ) ? assessments_count : undefined;
        params.filter['quizzes_count'] = ( quizzes_count !== '' ) ? quizzes_count : undefined;
        params.filter['live_lectures_count'] = ( live_lectures_count !== '' ) ? live_lectures_count : undefined;
        params.filter['resources_count'] = ( resources_count !== '' ) ? resources_count : undefined;
        params.filter['events_count'] = ( events_count !== '' ) ? events_count : undefined;
        params.filter['schedules_count'] = ( schedules_count !== '' ) ? schedules_count : undefined;
        params.filter['notices_count'] = ( notices_count !== '' ) ? notices_count : undefined;
        params.filter['fees_count'] = ( fees_count !== '' ) ? fees_count : undefined;
        params.filter['invoices_count'] = ( invoices_count !== '' ) ? invoices_count : undefined;
        params.filter['e_com_orders_count'] = ( e_com_orders_count !== '' ) ? e_com_orders_count : undefined;
        params.filter['forms_count'] = ( forms_count !== '' ) ? forms_count : undefined;
        params.filter['submissions_count'] = ( submissions_count !== '' ) ? submissions_count : undefined;
        params.filter['db_size'] = ( db_size !== '' ) ? db_size : undefined;
        params.filter['file_size'] = ( file_size !== '' ) ? file_size : undefined;
        params.filter['core_status'] = ( core_status !== '' ) ? core_status : undefined;
        params.filter['lms_status'] = ( lms_status !== '' ) ? lms_status : undefined;
        params.filter['fees_status'] = ( fees_status !== '' ) ? fees_status : undefined;
        params.filter['crm_status'] = ( crm_status !== '' ) ? crm_status : undefined;
        params.filter['finance_status'] = ( finance_status !== '' ) ? finance_status : undefined;
        params.filter['alumni_status'] = ( alumni_status !== '' ) ? alumni_status : undefined;
        params.filter['ecommerce_status'] = ( ecommerce_status !== '' ) ? ecommerce_status : undefined;
        params.filter['whitelabel_status'] = ( whitelabel_status !== '' ) ? whitelabel_status : undefined;
        params.filter['fundraising_status'] = ( fundraising_status !== '' ) ? fundraising_status : undefined;
        params.filter['degreeaudit_status'] = ( degreeaudit_status !== '' ) ? degreeaudit_status : undefined;
        params.filter['country'] = ( country != '') ? country : undefined;
        this.loadTenant(params, true);
        this.loadAllTenant(params,true);
    }
    resetFilters = () => {
        let params = this.state.params;
        params.filter = {};
        this.searchtitleInputRef.current.value=null;
        this.instituteNameInputRef.current.value=null;
        this.emailInputRef.current.value=null;
        this.phoneInputRef.current.value=null;
        this.subDomainInputRef.current.value=null;
        this.instStudentSizeInputRef.current.value=null;
        this.contactPersonInputRef.current.value=null;  
        this.registerFromInputRef.current.value=null; 
        this.registerToInputRef.current.value=null;
        this.subscriptionRenewFromInputRef.current.value=null;
        this.subscriptionRenewToInputRef.current.value=null;
        this.subscriptionCancelFromInputRef.current.value=null;
        this.subscriptionCancelToInputRef.current.value=null;
        this.paidSubscriptionStartFromInputRef.current.value=null;
        this.paidSubscriptionStartToInputRef.current.value=null;
        this.classCountInputRef.current.value=null;
        this.sectionCountInputRef.current.value=null;
        this.subjectCountInputRef.current.value=null;
        this.adminsCountInputRef.current.value=null;
        this.studentsCountInputRef.current.value=null;
        this.alumniCountInputRef.current.value=null;
        this.teacherCountInputRef.current.value=null;
        this.attendanceCountInputRef.current.value=null;
        this.certificatesCountInputRef.current.value=null;
        this.transcriptsCountInputRef.current.value=null;
        this.flipClassContentsCountInputRef.current.value=null;
        this.discussionPostsCountInputRef.current.value=null;
        this.assessmentsCountInputRef.current.value=null;
        this.quizzesCountInputRef.current.value=null;
        this.liveLecturesCountInputRef.current.value=null;
        this.resourcesCountInputRef.current.value=null;
        this.eventsCountInputRef.current.value=null;
        this.schedulesCountInputRef.current.value=null;
        this.noticesCountInputRef.current.value=null;
        this.feesCountInputRef.current.value=null;
        this.invoicesCountInputRef.current.value=null;
        this.eComOrdersCountInputRef.current.value=null;
        this.formsCountInputRef.current.value=null;
        this.submissionsCountInputRef.current.value=null;
        this.dbSizeInputRef.current.value=null;
        this.fileSizeInputRef.current.value=null;
        this.setState({
            selectedInstituteType:"",
            selectedBillingCycle:"",
            selectedCoreStatus:"",
            selectedLmsStatus:"",
            selectedFeesStatus:"",
            selectedCRMStatus:"",
            selectedFinanceStatus:"",
            selectedAlumniStatus:"",
            selectedECommerceStatus:"",
            selectedWhiteLabelStatus:"",
            selectedFundRaisingStatus:"",
            selectedDegreeAuditStatus:"",
            selectedcountry:"",
        });
        this.loadTenant(params, true);
        this.loadAllTenant(params,true);
    }
    modelOpen(id){
        this.setState({
            isSaveInProgress:true,
        });
        let self=this;
        axios.get( Config.apiBaseURL + '/tenant/view/'+id)
        .then( response => {
            this.setState({
                userDetails:response.data,
                //open:true,
            });
        })
        .catch(function (error) {
            if(error.response && error.response.status === 500) {                   
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }
            else { // any other error 
                    self.props.enqueueSnackbar('Error! While loading tenants data.', {
                    variant: 'error',
                });
            }
        })
        .then(function () {
            self.setState({
               //
            })
        });

        axios.get( Config.apiBaseURL + '/tenant/credentials/'+id)
        .then( response => {
            this.setState({
                userData:response.data,
                open:true,
            });
        })
        .catch(function (error) {
            if(error.response && error.response.status === 500) {                   
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }
            else { // any other error 
                    self.props.enqueueSnackbar('Error! While loading tenants data.', {
                    variant: 'error',
                });
            }
        })
        .then(function () {
            self.setState({
                isLoading:false,
                isSaveInProgress:false
            })
        });
    }
    ModelhandleClose = () => {
        this.setState({
            open:false,
            pcopied:false,
            ucopied:false,
        })  
    };
    domainUrlClick(url){
        window.open("https://"+url+".classe365.com");
    }
    instituteTypeHandle = (event) => {
        this.setState({
            selectedInstituteType:event.target.value
        })
    }
    billingCycleHandle = (event) => {
        this.setState({
            selectedBillingCycle:event.target.value
        })
    }
    coreStatusHandle = (event) => {
        this.setState({
            selectedCoreStatus:event.target.value
        })
    }
    lmsStatusHandle = (event) => {
        this.setState({
            selectedLmsStatus:event.target.value
        })
    }
    feesStatusHandle = (event) => {
        this.setState({
            selectedFeesStatus:event.target.value
        })
    }
    crmStatusHandle = (event) => {
        this.setState({
            selectedCRMStatus:event.target.value
        })
    }
    financeStatusHandle = (event) => {
        this.setState({
            selectedFinanceStatus:event.target.value
        })
    }
    alumniStatusHandle = (event) => {
        this.setState({
            selectedAlumniStatus:event.target.value
        })
    }
    eCommerceStatusHandle = (event) => {
        this.setState({
            selectedECommerceStatus:event.target.value
        })
    }
    whiteLabelStatusHandle = (event) => {
        this.setState({
            selectedWhiteLabelStatus:event.target.value
        })
    }
    fundRaisingStatusHandle = (event) => {
        this.setState({
            selectedFundRaisingStatus:event.target.value
        })
    }
    degreeAuditStatusHandle = (event) => {
        this.setState({
            selectedDegreeAuditStatus:event.target.value
        })
    }
    countryHandle = (event) => {
        this.setState({
            selectedcountry:event.target.value
        })
    }
    deleteCustomer = () => {
        let deleteParams = {}
        let id = this.state.wantToDelete;
        console.log(id)
        deleteParams['password'] = this.deletePasswordRef.current.value;
        deleteParams['keyword'] = this.deleteKeywordRef.current.value;
        
        if (id != "") {  
            const self = this;
            axios.delete(Config.apiBaseURL +'/tenant/delete/'+ id,{params:deleteParams})
            .then( response => {
                self.props.enqueueSnackbar('Customer deleted successfully.', {
                    variant: 'success',
                });
                this.loadTenant(this.state.params);
                this.loadAllTenant(this.state.params);
            })
            .catch(function (error) {
                self.props.enqueueSnackbar('Error! While deleting this customer.', {
                    variant: 'error',
                });
            })
            .then(function () {
                // always executed
            });
        }
    }
    handleDeleteOpen = (id)=>{
        this.setState({
            wantToDelete : id,
            deleteOpen:true
        })              
    }
    handleDeleteClose = ()=>{
        this.setState({
            wantToDelete:"",
            deleteOpen:false
        })
    }
    render(){
        const { selectedInstituteType } = this.state;
        const { selectedBillingCycle } = this.state;
        const { selectedCoreStatus } = this.state;
        const { selectedLmsStatus } = this.state;
        const { selectedFeesStatus } = this.state;
        const { selectedCRMStatus } = this.state;
        const { selectedFinanceStatus } = this.state;
        const { selectedAlumniStatus } = this.state;
        const { selectedECommerceStatus } = this.state;
        const { selectedWhiteLabelStatus } = this.state;
        const { selectedFundRaisingStatus } = this.state;
        const { selectedDegreeAuditStatus } = this.state;
        const { selectedcountry } = this.state;

        const infoItems = [
            "- FreeTrial (Customer is on FreeTrial plan and can access the module)",
            "- Unpaid (Customer never subscribed for Paid Plan. And Free Trial plan is over. Restrict the access to module)",
            "- Active (Customer is on an active Paid plan and can access the module)",
            "- PaymentDue (Customer was on a Paid plan but renewal Payment Failed. Customer can access the module for few more days with the assumption that he will fix the payment issue and get back to Active state. If payment is not processed in that timeframe then will update the subscription status to Cancelled)",
            "- ToBeCanceled (Currently customer is on an active paid plan. But customer has initiated the cancellation. So the subscription is about to be canceled at the end of the active period)",
            "- Canceled (Customer's subscription is canceled. Restrict the access to module)",
        ];
        const tip = infoItems.join('\n')

        let size_in = "GB";
        let passwordCopyColor="",usernameCopyColor="";
        if(this.state.pcopied == true){   
            passwordCopyColor="green";
        }
        if(this.state.ucopied == true){   
            usernameCopyColor="green";
        }
        let basicFilter;
        let more_filters =  <Drawer
                                anchor="right"
                                open={this.state.more_filters_open}
                                width={500}>
                                <div>
                                    <div className="moreFilterTitle">Filters</div>
                                    <CancelIcon onClick={this.handleFiltersToggle} className="cancelIcon"/>
                                </div>
                                <div style={{minWidth:400}}>
                                    <form method="POST" onSubmit={this.triggerMoreFilters} 
                                        style={{margin:30,marginTop:20,maxWidth:1000}}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.instituteNameInputRef
                                                        }}
                                                        id="title"
                                                        label="Institute Name"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['institute_name']) ? this.state.params.filter['institute_name'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.subDomainInputRef
                                                        }}
                                                        id="subDomain"
                                                        label="Sub Domain"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['domain_url']) ? this.state.params.filter['domain_url'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.registerFromInputRef
                                                        }}
                                                        id="date"
                                                        label="Register Date (From)"
                                                        margin="normal"
                                                        type="date"
                                                        fullWidth
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        defaultValue={(this.state.params.filter['activated_date_from']) ? this.state.params.filter['activated_date_from'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.registerToInputRef
                                                        }}
                                                        id="date"
                                                        label="Register Date (To)"
                                                        margin="normal"
                                                        type="date"
                                                        fullWidth
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        defaultValue={(this.state.params.filter['activated_date_to']) ? this.state.params.filter['activated_date_to'] : null}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} >
                                                <Grid item xs={2}>
                                                    <div style={{marginTop:16}}></div>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">Institute Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Institute Type"
                                                            value={selectedInstituteType}
                                                            onChange={this.instituteTypeHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="Academy">Academy</MenuItem>
                                                            <MenuItem value="Schools / K-12">Schools / K-12</MenuItem>
                                                            <MenuItem value="Higher Education / Colleges">Higher Education / Colleges</MenuItem>
                                                            <MenuItem value="University">University</MenuItem>
                                                            <MenuItem value="Other">Other</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.instStudentSizeInputRef
                                                        }}
                                                        id="instStudentSize"
                                                        label="Student Size"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['inst_student_size']) ? this.state.params.filter['inst_student_size'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.contactPersonInputRef
                                                        }}
                                                        id="contactPerson"
                                                        label="Contact Person"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['username']) ? this.state.params.filter['username'] : null}
                                                    />            
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.phoneInputRef
                                                        }}
                                                        id="Phone"
                                                        label="Phone"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['contact_no']) ? this.state.params.filter['contact_no'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.emailInputRef
                                                        }}
                                                        id="email"
                                                        label="Email"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['user_email_id']) ? this.state.params.filter['user_email_id'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div style={{marginTop:16}}></div>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">Countries</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Countries"
                                                            value={selectedcountry}
                                                            onChange={this.countryHandle}                                                            
                                                            >
                                                            {Object.keys(countries).map(country=>{
                                                                return <MenuItem key={country} value={country}>{countries[country]}</MenuItem>                                                                
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <div style={{fontSize:16,marginTop:20,marginBottom:-22}}>Billing Type & Volume</div>
                                            <Grid container spacing={3}>
                                                <Grid item xs={3}>
                                                    <div style={{marginTop:16}}></div>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">Billing Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Core"
                                                            value={selectedBillingCycle}
                                                            onChange={this.billingCycleHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="free">Free</MenuItem>
                                                            <MenuItem value="auto">Auto</MenuItem>
                                                            <MenuItem value="manual">Manual</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.dbSizeInputRef
                                                        }}
                                                        id="DBSize"
                                                        label="DB Size"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['db_size']) ? this.state.params.filter['db_size'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.fileSizeInputRef
                                                        }}
                                                        id="FileSize"
                                                        label="File Size"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['file_size']) ? this.state.params.filter['file_size'] : null}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.subscriptionRenewFromInputRef
                                                        }}
                                                        id="date"
                                                        label="Subscription Renew Date (From)"
                                                        margin="normal"
                                                        type="date"
                                                        fullWidth
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        defaultValue={(this.state.params.filter['subscription_renew_from']) ? this.state.params.filter['subscription_renew_from'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.subscriptionRenewToInputRef
                                                        }}
                                                        id="date"
                                                        label="Subscription Renew Date (To)"
                                                        margin="normal"
                                                        type="date"
                                                        fullWidth
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        defaultValue={(this.state.params.filter['subscription_renew_to']) ? this.state.params.filter['subscription_renew_to'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.paidSubscriptionStartFromInputRef
                                                        }}
                                                        id="date"
                                                        label="Initial Subscription Date (From)"
                                                        margin="normal"
                                                        type="date"
                                                        fullWidth
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        defaultValue={(this.state.params.filter['paid_sub_start_from']) ? this.state.params.filter['paid_sub_start_from'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.paidSubscriptionStartToInputRef
                                                        }}
                                                        id="date"
                                                        label="Initial Subscription Date (To)"
                                                        margin="normal"
                                                        type="date"
                                                        fullWidth
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        defaultValue={(this.state.params.filter['paid_sub_start_to']) ? this.state.params.filter['paid_sub_start_to'] : null}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.subscriptionCancelFromInputRef
                                                        }}
                                                        id="date"
                                                        label="Subscription Cancel Date (From)"
                                                        margin="normal"
                                                        type="date"
                                                        fullWidth
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        defaultValue={(this.state.params.filter['sub_cancellation_from']) ? this.state.params.filter['sub_cancellation_from'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.subscriptionCancelToInputRef
                                                        }}
                                                        id="date"
                                                        label="Subscription Cancel Date (To)"
                                                        margin="normal"
                                                        type="date"
                                                        fullWidth
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        defaultValue={(this.state.params.filter['sub_cancellation_to']) ? this.state.params.filter['sub_cancellation_to'] : null}
                                                    />
                                                </Grid>                                                
                                            </Grid>
                                            <div style={{fontSize:16,marginTop:20,marginBottom:5,display:'flex',alignItems:'center',flexWrap:'wrap'}}>
                                                Modules Status 
                                                <Tooltip
                                                    data-html="true"
                                                    title={<div style={{ whiteSpace: 'pre-line' }}>{tip}</div>}
                                                    style={{marginLeft:7,marginBottom:3,whiteSpace: 'pre-line'}}
                                                >
                                                    <InfoIcon fontSize="small"></InfoIcon>
                                                </Tooltip>
                                            </div>
                                            <Grid container spacing={3}>
                                                <Grid item xs={2}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">Core</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Core"
                                                            value={selectedCoreStatus}
                                                            onChange={this.coreStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">LMS</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="LMS"
                                                            value={selectedLmsStatus}
                                                            onChange={this.lmsStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">Fees</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Fees"
                                                            value={selectedFeesStatus}
                                                            onChange={this.feesStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">CRM</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="CRM"
                                                            value={selectedCRMStatus}
                                                            onChange={this.crmStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">Finance</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Finance"
                                                            value={selectedFinanceStatus}
                                                            onChange={this.financeStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">Alumni</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Alumni"
                                                            value={selectedAlumniStatus}
                                                            onChange={this.alumniStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">E-commerce</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="E-commerce"
                                                            value={selectedECommerceStatus}
                                                            onChange={this.eCommerceStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">Whitelabel</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Whitelabel"
                                                            value={selectedWhiteLabelStatus}
                                                            onChange={this.whiteLabelStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">Fundraising</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Fundraising"
                                                            value={selectedFundRaisingStatus}
                                                            onChange={this.fundRaisingStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">Degree Audit</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Degree Audit"
                                                            value={selectedDegreeAuditStatus}
                                                            onChange={this.degreeAuditStatusHandle}
                                                            >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value="FreeTrial">FreeTrial</MenuItem>
                                                            <MenuItem value="UnPaid">Unpaid</MenuItem>
                                                            <MenuItem value="Active">Active</MenuItem>
                                                            <MenuItem value="PaymentDue">Payment Due</MenuItem>
                                                            <MenuItem value="ToBeCancelled">To Be Canceled</MenuItem>
                                                            <MenuItem value="Canceled">Canceled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <div style={{fontSize:16,marginTop:20,marginBottom:-22}}>Academics</div>
                                            <Grid container spacing={3}>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.classCountInputRef
                                                        }}
                                                        id="Class"
                                                        label="Class"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['classes_count']) ? this.state.params.filter['classes_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.sectionCountInputRef
                                                        }}
                                                        id="Section"
                                                        label="Section"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['sections_count']) ? this.state.params.filter['sections_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.subjectCountInputRef
                                                        }}
                                                        id="Subject"
                                                        label="Subject"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['subjects_count']) ? this.state.params.filter['subjects_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.adminsCountInputRef
                                                        }}
                                                        id="Admin"
                                                        label="Admin"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['admins_count']) ? this.state.params.filter['admins_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.studentsCountInputRef
                                                        }}
                                                        id="Student"
                                                        label="Student"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['students_count']) ? this.state.params.filter['students_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.alumniCountInputRef
                                                        }}
                                                        id="Alumni"
                                                        label="Alumni"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['alumni_count']) ? this.state.params.filter['alumni_count'] : null}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.teacherCountInputRef
                                                        }}
                                                        id="Teacher"
                                                        label="Teacher"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['teachers_count']) ? this.state.params.filter['teachers_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.attendanceCountInputRef
                                                        }}
                                                        id="Attendance"
                                                        label="Attendance"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['attendance_count']) ? this.state.params.filter['attendance_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.certificatesCountInputRef
                                                        }}
                                                        id="Certificate"
                                                        label="Certificate"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['certificates_count']) ? this.state.params.filter['certificates_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.transcriptsCountInputRef
                                                        }}
                                                        id="Transcript"
                                                        label="Transcript"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['transcripts_count']) ? this.state.params.filter['transcripts_count'] : null}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <div style={{fontSize:16,marginTop:20,marginBottom:-22}}>LMS</div>
                                            <Grid container spacing={3}>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.flipClassContentsCountInputRef
                                                        }}
                                                        id="FlipClassContents"
                                                        label="Contents"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['flip_class_contents_count']) ? this.state.params.filter['flip_class_contents_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.discussionPostsCountInputRef
                                                        }}
                                                        id="DiscussionPosts"
                                                        label="Discussion Posts"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['discussion_posts_count']) ? this.state.params.filter['discussion_posts_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.assessmentsCountInputRef
                                                        }}      
                                                        id="Assessments"
                                                        label="Assessments"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['assessments_count']) ? this.state.params.filter['assessments_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.quizzesCountInputRef
                                                        }}
                                                        id="Quizzes"
                                                        label="Quizzes"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['quizzes_count']) ? this.state.params.filter['quizzes_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.liveLecturesCountInputRef
                                                        }}
                                                        id="LiveLectures"
                                                        label="Live Lectures"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['live_lectures_count']) ? this.state.params.filter['live_lectures_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.resourcesCountInputRef
                                                        }}
                                                        id="Resources"
                                                        label="Resources"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['resources_count']) ? this.state.params.filter['resources_count'] : null}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <div style={{fontSize:16,marginTop:20,marginBottom:-22}}>Communication</div>
                                            <Grid container spacing={3}>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.eventsCountInputRef
                                                        }}
                                                        id="Events"
                                                        label="Events"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['events_count']) ? this.state.params.filter['events_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.schedulesCountInputRef
                                                        }}
                                                        id="Schedules"
                                                        label="Schedules"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['schedules_count']) ? this.state.params.filter['schedules_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.noticesCountInputRef
                                                        }}
                                                        id="Notices"
                                                        label="Notices"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['notices_count']) ? this.state.params.filter['notices_count'] : null}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <div style={{fontSize:16,marginTop:20,marginBottom:-22}}>Fees & CRM</div>
                                            <Grid container spacing={3}>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.feesCountInputRef
                                                        }}
                                                        id="Fees"
                                                        label="Fees"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['fees_count']) ? this.state.params.filter['fees_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.invoicesCountInputRef
                                                        }}
                                                        id="Invoices"
                                                        label="Invoices"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['invoices_count']) ? this.state.params.filter['invoices_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.eComOrdersCountInputRef
                                                        }}
                                                        id="Orders"
                                                        label="Orders"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['e_com_orders_count']) ? this.state.params.filter['e_com_orders_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.formsCountInputRef
                                                        }}
                                                        id="Forms"
                                                        label="Forms"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['forms_count']) ? this.state.params.filter['forms_count'] : null}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        inputProps={{
                                                            ref: this.submissionsCountInputRef
                                                        }}
                                                        id="Submissions"
                                                        label="Submissions"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        defaultValue={(this.state.params.filter['submissions_count']) ? this.state.params.filter['submissions_count'] : null}
                                                    />
                                                </Grid>
                                            </Grid>

                                        <div style={{marginTop:20,marginBottom:20}} >
                                            <Button variant="contained" size="medium" color="primary" type="submit">
                                                Apply
                                            </Button>&nbsp;&nbsp;
                                            <Button variant="contained" size="medium" onClick={this.resetFilters} type="submit" >
                                                Reset
                                            </Button>
                                        </div> 
                                    </form>  
                                </div> 
                            </Drawer>; 
            var tenantUrl="https://"+this.state.userDetails.domain_url+".classe365.com";
            let passwordDisplay="",userNameDisplay ="";
            if(this.state.userData.password !== null){
                passwordDisplay=<div style={{display:"inline-flex"}}>
                                    <div>
                                        <input type="password" style={{border:"none"}} value={this.state.userData.password} id="myInput"/>
                                    </div>
                                    <div>
                                        <CopyToClipboard text={this.state.userData.password} onCopy={() => this.setState({pcopied: true})}>
                                            <Link to="#"><FileCopyIcon style={{fontSize:"18px",marginTop:5,color:passwordCopyColor}}></FileCopyIcon></Link>
                                        </CopyToClipboard>
                                    </div>
                                </div>
            }
            if(this.state.userData.user_name !== null){
            userNameDisplay=<div style={{display:"inline-flex"}}>
                        <div style={{width:"180px"}}>
                        {this.state.userData.user_name}
                        </div>
                        <div>
                            <CopyToClipboard text={this.state.userData.user_name} onCopy={() => this.setState({ucopied: true})}>
                                <Link to="#"><FileCopyIcon style={{fontSize:"18px",color:usernameCopyColor}}></FileCopyIcon></Link>
                            </CopyToClipboard>
                        </div>
                    </div>
            }
            let OpenDialog=<Dialog className="model" onClose={this.ModelhandleClose} aria-labelledby="customized-dialog-title" open={this.state.open}>
                                <DialogTitle id="customized-dialog-title">
                                    Admin Login Details      
                                    <IconButton edge="start" color="inherit" className="dialogCloseIcon" onClick={this.ModelhandleClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton> 
                                </DialogTitle>
                                    <DialogContent dividers>
                                        <div style={{fontSize:14}}>
                                            <Grid container spacing={3}  alignItems="center" style={{color:"#707070",lineHeight:"30px"}}>
                                                <Grid item >
                                                    <div style={{display:"flex"}}>
                                                        <div style={{minWidth:150}}>Tenant Url </div>
                                                        <div><div><Link to="#" className="Link" onClick={this.domainUrlClick.bind(this,this.state.userDetails.domain_url)}>{tenantUrl}</Link></div></div>
                                                    </div>
                                                    <div style={{display:"flex"}}>
                                                        <div style={{minWidth:150}}>Admin Name</div>
                                                        <div>{this.state.userDetails.admin_name}</div>
                                                    </div>
                                                    <div style={{display:"flex"}}>
                                                        <div style={{minWidth:150}}>Username </div>
                                                        {userNameDisplay}
                                                    </div>
                                                    <div style={{display:"flex"}}>
                                                        <div style={{minWidth:150}}>Password </div>
                                                        {passwordDisplay}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>  
                                    </DialogContent>
                                </Dialog> 
        let overlayLoader;
        if(this.state.isSaveInProgress == true){
             overlayLoader=  <div className="loading"> 
                                 <Loader type='block' /> 
                             </div>
         }
        let data_table;                                     
        if ( this.state.isLoading ) {
            data_table = <Loader type='block'/>;
        }else{
            let deleteCustomer = '';
            if((config.allowUser.indexOf(role) > -1)){
                deleteCustomer = <Tooltip title="Delete Customer"><Link to="#" onClick={this.deleteCustomer.bind(10)} className="actionIcon"><DeleteIcon></DeleteIcon></Link></Tooltip>;
            }
            let tenantData = [];
                for(const i in this.state.tenants){ 
                        var created = this.state.tenants[i].created.split('T')
                        let date = created[0];
                        var Date1 = moment(date).format("DD MMM YYYY");
                        var file_size = parseFloat(this.state.tenants[i].file_size/1024).toFixed(2);    //in GB
                        var db_size = parseFloat(this.state.tenants[i].db_size/1024).toFixed(2);    //in GB
                        var tenant_size = parseFloat(this.state.tenants[i].tenant_size/1024).toFixed(2);    //in GB
                        var billingCycle = "";
                        if(this.state.tenants[i].billing_cycle == "auto"){
                            billingCycle = "Auto";
                        }else if(this.state.tenants[i].billing_cycle == "free"){
                            billingCycle = "Free";
                        }else if(this.state.tenants[i].billing_cycle == "manual"){
                            billingCycle = "Manual";
                        }

                        let last_visit_on = "";
                        let renewal_date = "";
                        
                        if(this.state.tenants[i].last_visit_on != undefined){
                            const isoStr = this.state.tenants[i].last_visit_on;
                            const dateTmp = new Date(isoStr);
                            let timestamp = dateTmp.getTime();
                            var dt = new Date(timestamp);
                            var year = dt.getFullYear();
                            var month = dt.getMonth() + 1;
                            var day = dt.getDate();
                            var hours = dt.getHours();
                            var minutes = dt.getMinutes();
                            var seconds = dt.getSeconds();
                            if(month < 10){
                                month = '0' + month;
                            }
                            if(day < 10){
                                day = '0' + day;
                            }
                            if(hours < 10){
                                hours = '0' + hours;
                            }
                            if(minutes < 10){
                                minutes = '0' + minutes;
                            }
                            if(seconds < 10){
                                seconds = '0' + seconds;
                            }
                            last_visit_on = year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
                        }
                        
                        if(this.state.tenants[i].sub_renew_date != undefined){
                            var uDate = new Date(this.state.tenants[i].sub_renew_date);
                            var uYear = uDate.getFullYear();
                            var uMonth = uDate.getMonth() + 1;
                            var uDay = uDate.getDate();
                            if(uMonth < 10){
                                uMonth = '0' + uMonth;
                            }
                            if(uDay < 10){
                                uDay = '0' + uDay;
                            }
                            renewal_date = uYear+"-"+uMonth+"-"+uDay;
                        }

                    tenantData.push(
                        <TableRow>
                            <TableCell><Link to={"/admin/profile/" + this.state.tenants[i].id} className="Link" >{this.state.tenants[i].institute_name}</Link></TableCell>
                            <TableCell>
                                <div className="TableWordBreak"><Link to="#" onClick={this.domainUrlClick.bind(this,this.state.tenants[i].domain_url)} className="Link">{this.state.tenants[i].domain_url}</Link></div>        
                            </TableCell>
                            <TableCell><div className="TableWordBreak">{this.state.tenants[i].students_count}</div></TableCell>
                            <TableCell><div className="TableWordBreak">{this.state.tenants[i].alumni_count}</div></TableCell>
                            <TableCell><div className="TableWordBreak">{this.state.tenants[i].teachers_count}</div></TableCell>
                            <TableCell><div className="TableWordBreak">{tenant_size} {size_in}</div><div className="TableWordBreak">(DB - {db_size} {size_in} & Files - {file_size} {size_in})</div></TableCell>
                            <TableCell>{billingCycle}</TableCell>
                            <TableCell>{renewal_date}</TableCell>
                            <TableCell>{last_visit_on}</TableCell>
                            <TableCell style={{maxWidth:70}}>{Date1}</TableCell>
                            <TableCell style={{minWidth:130,fontSize:15}}>
                                <Tooltip title="Admin Login Details"><Link to="#" onClick={this.modelOpen.bind(this,this.state.tenants[i].id)}  className="actionIcon"><VpnKeyIcon></VpnKeyIcon></Link></Tooltip>
                                <Tooltip title="Customer Profile"><Link  to={"/admin/profile/" + this.state.tenants[i].id} className="actionIcon"><InfoIcon></InfoIcon></Link></Tooltip>
                                {/*(localStorage.getItem('role') == 'admin') ? <Tooltip title="Delete Customer"><Link to="#" onClick={this.handleDeleteOpen.bind(this,this.state.tenants[i].id)} className="actionIcon"><DeleteIcon></DeleteIcon></Link></Tooltip> : ''*/}
                            </TableCell>
                        </TableRow>  
                    );
                } 
                basicFilter= <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginBottom:"20px"}}>
                            <div className="adminSearchBox">
                                <div>
                                    <SearchIcon className="searchIcon" onClick={this.triggerBasicFilters}/>
                                </div>   
                                <div>
                                    <InputBase
                                        style={{width: "calc(100% - 10px)"}}
                                        placeholder="Search Customers"
                                        inputProps={{
                                            ref: this.searchtitleInputRef
                                        }}
                                        onKeyUp={this.detectEnter}
                                        defaultValue={(this.state.params.filter['search_keyword']) ? this.state.params.filter['search_keyword'] : null} 
                                    />
                                </div>
                                <div style={{width:100}}>
                                    <div onClick={this.handleFiltersToggle} className="moreFilterLabel" >More Filters</div>
                                </div>                            
                            </div>
                            <div>
                                <CSVLink
                                data={allTenants}
                                onClick={event => {
                                    return (allTenants.length > 0)? true : false;
                                }}
                                headers={exportheaders}
                                filename={"Classe365-Customers.csv"}
                                style={{ textDecoration: 'none' }}
                                ><Button variant='contained' color='primary'><DownloadIcon></DownloadIcon> Export</Button></CSVLink>
                            </div>
                        </div>
                data_table=<div> 
                                <Paper>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <TableSortLabel 
                                                        active={ (this.state.params.sort.institute_name) ? true : false} 
                                                        direction={ (this.state.params.sort.institute_name) ? this.state.params.sort.institute_name.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('institute_name', (this.state.params.sort.institute_name && this.state.params.sort.institute_name.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        Institute Name
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={ (this.state.params.sort.domain_url) ? true : false} 
                                                        direction={ (this.state.params.sort.domain_url) ? this.state.params.sort.domain_url.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('domain_url', (this.state.params.sort.domain_url && this.state.params.sort.domain_url.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        Sub Domain
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={ (this.state.params.sort.students_count) ? true : false} 
                                                        direction={ (this.state.params.sort.students_count) ? this.state.params.sort.students_count.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('students_count', (this.state.params.sort.students_count && this.state.params.sort.students_count.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        No. Of Students
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={ (this.state.params.sort.alumni_count) ? true : false} 
                                                        direction={ (this.state.params.sort.alumni_count) ? this.state.params.sort.alumni_count.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('alumni_count', (this.state.params.sort.alumni_count && this.state.params.sort.alumni_count.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        No. Of Alumni
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={ (this.state.params.sort.teachers_count) ? true : false} 
                                                        direction={ (this.state.params.sort.teachers_count) ? this.state.params.sort.teachers_count.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('teachers_count', (this.state.params.sort.teachers_count && this.state.params.sort.teachers_count.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        No. Of Teachers
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={ (this.state.params.sort.tenant_size) ? true : false} 
                                                        direction={ (this.state.params.sort.tenant_size) ? this.state.params.sort.tenant_size.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('tenant_size', (this.state.params.sort.tenant_size && this.state.params.sort.tenant_size.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        Tenant Size
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={ (this.state.params.sort.billing_cycle) ? true : false} 
                                                        direction={ (this.state.params.sort.billing_cycle) ? this.state.params.sort.billing_cycle.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('billing_cycle', (this.state.params.sort.billing_cycle && this.state.params.sort.billing_cycle.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        Billing Type
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={ (this.state.params.sort.sub_renew_date) ? true : false} 
                                                        direction={ (this.state.params.sort.sub_renew_date) ? this.state.params.sort.sub_renew_date.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('sub_renew_date', (this.state.params.sort.sub_renew_date && this.state.params.sort.sub_renew_date.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        Renewal Date
                                                    </TableSortLabel>    
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={ (this.state.params.sort.last_visit_on) ? true : false} 
                                                        direction={ (this.state.params.sort.last_visit_on) ? this.state.params.sort.last_visit_on.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('last_visit_on', (this.state.params.sort.last_visit_on && this.state.params.sort.last_visit_on.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        Last Visit
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={ (this.state.params.sort.activated_date) ? true : false} 
                                                        direction={ (this.state.params.sort.activated_date) ? this.state.params.sort.activated_date.toLowerCase() : "asc" } 
                                                        onClick={this.handleSortChange('activated_date', (this.state.params.sort.activated_date && this.state.params.sort.activated_date.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                                        Registered On
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell style={{minWidth:"100px"}}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tenantData}    
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[]}
                                                    count={this.state.total_tenants}
                                                    rowsPerPage={this.state.records_per_page}
                                                    page={this.state.params.page ? this.state.params.page : 0}
                                                    onPageChange={this.handleChangePage}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </Paper>
                            </div>
                           
            }
            let deleteDialog = <div><Dialog open={this.state.deleteOpen} onClose={this.handleDeleteClose}>
                <DialogTitle>Delete Customer</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure, you wish to delete this customer? 
                    </DialogContentText>
                    <Grid container>                        
                        <Grid item xs={12}>
                            <TextField 
                                inputProps={{
                                    ref: this.deletePasswordRef
                                }}
                                id="deletePass"
                                fullWidth 
                                margin='normal'
                                variant="outlined"
                                label="Password"
                                type={'password'}                                
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                inputProps={{
                                    ref: this.deleteKeywordRef
                                }}                               
                                id="deleteKeyword"
                                fullWidth
                                margin='normal' 
                                variant="outlined"                                
                                label="Type DELETE keyword" 
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{padding:"24px"}}>
                    <Button variant='contained' color='primary' onClick={this.deleteCustomer}>Delete</Button>
                    <Button variant='contained' onClick={this.handleDeleteClose}>Cancel</Button>
                </DialogActions>
            </Dialog></div>
            return (
                <div>
                    <Grid container spacing={3} >
                        <Grid item xs={6}>
                            <Typography variant="h5" gutterBottom >
                                Customers 
                            </Typography>       
                        </Grid>
                    </Grid>
                    {overlayLoader}
                    {OpenDialog}
                    {more_filters}
                    {basicFilter}
                    {data_table}
                    {deleteDialog}
                </div>
            );
		}
	}
				
export default withSnackbar(Customers);