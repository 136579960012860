import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withSnackbar  } from 'notistack';
import Loader from '../../elements/loader';
import Config from '../../config/index';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {withStyles } from '@material-ui/core/styles';

const axios = require('axios');
axios.defaults.headers.common['Authorization'] = localStorage.getItem('user_token');

function TabPanel(props) {
    const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
                >
                {value === index && <Box p={3}>{children}</Box>}
            </Typography>
        );
}
const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#3f51b5',
    },
})(Tabs);
const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#3f51b5',
            opacity: 1,
        },
        '&$selected': {
            color: '#3f51b5',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#3f51b5',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

class deletedCustomerView extends Component {
    constructor(props) {
        super(props); 
        this.state={ 
            isLoading:false,
            userDetails:[],
            value:0,
        };  
    }
    componentDidMount(){
        this.loadDeleteUserDetails();        
    }
    loadDeleteUserDetails= () => {
        let self=this;
        self.setState({
            isLoading:true
        });
        axios.get( Config.apiBaseURL + '/deleted_tenant/view/'+this.props.match.params.id)
        .then( response => {
            this.setState({
                isLoading:false,
                userDetails:response.data
            })
        })
        .catch(function (error) {
            if(error.response && error.response.status === 500) {                   
                self.props.enqueueSnackbar(error.response.data.errors.message, {
                    variant: 'error',
                });
            }else if (error.response && error.response.status === 401){
                localStorage.setItem('logout', true);
                window.location = '/';
            }
            else { // any other error 
                    self.props.enqueueSnackbar('Error! While loading tenants data.', {
                    variant: 'error',
                });
            }
        })
        .then(function () {
            self.setState({
                //
            })
        });
    }
    statusVal = (val)=>{
        let Status = '-'; // Status value display for chipEffect in subscription dataTable
        if(val == 'FreeTrial'){
            Status= <Chip label='Free Trial' className="chipEffect" style={{backgroundColor:"#1976d2"}}></Chip>
        }else if(val == 'Unpaid'){
            Status= <Chip label='Unpaid' className="chipEffect" style={{backgroundColor:"#f44336"}}></Chip>
        }else if(val == 'Active'){
            Status= <Chip label='Active' className="chipEffect" style={{backgroundColor:"#34ebb4"}}></Chip>
        }else if(val == 'PaymentDue'){
            Status= <Chip label='Payment Due' className="chipEffect" style={{backgroundColor:"#1976d2"}}></Chip>
        }else if(val == 'Canceled'){
            Status= <Chip label='Canceled' className="chipEffect" style={{backgroundColor:"#f44336"}}></Chip>
        }else if(val == 'ToBeCancelled'){
            Status= <Chip label='To Be Cancelled' className="chipEffect" style={{backgroundColor:"#f44336"}}></Chip>
        } 
        return Status
    }
    handleChange = (event, newValue) => {
        this.setState({
            value:newValue,
        });  
    };
    render(){
        const {value} = this.state;
        let data_table=<div style={{paddingTop:20}}> 
                                <Typography variant="h6" gutterBottom >
                                    Subscription
                                </Typography>
                            <Paper style={{marginTop:10}}>
                                <Table stickyHeader={false} style={{width:"auto"}}>
                                    <TableHead className="root">
                                        <TableRow style={{fontWeight:400}}>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Type</TableCell>                                            
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow style={{fontWeight:400}}>
                                            <TableCell>Core</TableCell>
                                            <TableCell>{this.statusVal(this.state.userDetails.core_status)}</TableCell>                                            
                                        </TableRow>
                                        <TableRow style={{fontWeight:400}}>
                                            <TableCell>Fees & Invoicing</TableCell>
                                            <TableCell>{this.statusVal(this.state.userDetails.fees_status)}</TableCell>                                            
                                        </TableRow>
                                        <TableRow style={{fontWeight:400}}>
                                            <TableCell>Finance</TableCell>
                                            <TableCell>{this.statusVal(this.state.userDetails.finance_status)}</TableCell>                                            
                                        </TableRow>
                                        <TableRow style={{fontWeight:400}}>
                                            <TableCell>Alumni</TableCell>
                                            <TableCell>{this.statusVal(this.state.userDetails.alumni_status)}</TableCell>                                            
                                        </TableRow>
                                        <TableRow style={{fontWeight:400}}>
                                            <TableCell>E-Commerce</TableCell>
                                            <TableCell>{this.statusVal(this.state.userDetails.ecommerce_status)}</TableCell>                                            
                                        </TableRow>
                                        <TableRow style={{fontWeight:400}}>
                                            <TableCell>Learning Management System</TableCell>
                                            <TableCell>{this.statusVal(this.state.userDetails.lms_status)}</TableCell>                                            
                                        </TableRow>
                                        <TableRow style={{fontWeight:400}}>
                                            <TableCell>CRM-Enroll & Forms</TableCell>
                                            <TableCell>{this.statusVal(this.state.userDetails.crm_status)}</TableCell>                                            
                                        </TableRow>
                                        <TableRow style={{fontWeight:400}}>
                                            <TableCell>White Label</TableCell>
                                            <TableCell>{this.statusVal(this.state.userDetails.whitelabel_status)}</TableCell>                                            
                                        </TableRow>
                                        <TableRow style={{fontWeight:400}}>
                                            <TableCell>Fund Raising</TableCell>
                                            <TableCell>{this.statusVal(this.state.userDetails.fundraising_status)}</TableCell>                                            
                                        </TableRow> 
                                        <TableRow style={{fontWeight:400}}>
                                            <TableCell>Degree Audit</TableCell>
                                            <TableCell>{this.statusVal(this.state.userDetails.degreeaudit_status)}</TableCell>                                            
                                        </TableRow>    
                                    </TableBody>
                                </Table>
                            </Paper>
                        </div>
        const storage=[];
        const academic=[];
        const user=[];
        const learning=[];
        const feesAndCRM = [];
        const communication = [];
        const db_standard = 0.5;
        const file_standard = 10.24;
        const size_in = "GB";
        let userDetails = this.state.userDetails;
        if(userDetails != undefined){
            let fileProgressColor = "primary",dbProgressColor = "primary",fileColor = "#3f51b5";
            let tmp_db_size = ((userDetails.db_size)/1024).toFixed(2);    // DB data display in GB
            let db_percentage = ((tmp_db_size*100)/db_standard).toFixed(2);
            let db = db_percentage;
            
            let tmp_file_size = ((userDetails.file_size)/1024).toFixed(2);    // file data display in GB
            let file_percentage = ((userDetails.file_size*100)/file_standard).toFixed(2);
            let file = file_percentage;
            
            if(tmp_file_size > file_standard){    //100 up data storage so color change
                file = 100;
                fileProgressColor = "secondary";
                fileColor = "#f50057";
            }else if(tmp_db_size > db_standard){
                db = 100;
                dbProgressColor = "secondary";
            }            
            storage.push(
                <Grid container spacing={3}>
                    <Grid item  md={4} sm={6} lg={4} style={{fontSize:14,paddingTop:10,marginBottom:13}}>
                        <Paper className="Paper">
                            <div className="storageTitle">DB Consumption</div>
                            <div className="circulerProgress"><CircularProgress thickness="4.6" size="7rem" variant="determinate" color={dbProgressColor} value={db} /></div>
                            <Grid container spacing={3}>    
                                <Grid item  md={6} sm={6} lg={6} className="storageValueBlock">
                                    <div className="storageValue">{tmp_db_size} {size_in}</div>
                                    <div>{db_percentage}% used</div>
                                </Grid>
                                <Grid item md={6} sm={6} lg={6} className="storageValueBlock">
                                    <div className="totalStorageValue">{db_standard} {size_in}</div>
                                    <div>Total Storage</div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item  md={4} sm={6} lg={4} style={{fontSize:14}}>
                        <Paper className="Paper">
                            <div className="storageTitle">File Storage</div>
                            <div className="circulerProgress"><CircularProgress thickness="4.6" size="7rem" variant="determinate" color={fileProgressColor} value={file} /></div>
                            <Grid container spacing={3}>
                                <Grid item  md={6} sm={6} lg={6} className="storageValueBlock">
                                    <div className="storageValue" style={{color:fileColor}}>{tmp_file_size} {size_in}</div>
                                    <div>{file_percentage}% used</div>
                                </Grid>
                                <Grid item md={6} sm={6} lg={6} style={{fontSize:14,textAlignLast:"center"}}>
                                    <div className="totalStorageValue">{file_standard} {size_in}</div>
                                    <div>Total Storage</div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>                    
                </Grid>
            );
            academic.push(
                <Grid item  md={4} sm={6} lg={4} style={{fontSize:14}}>
                    <Paper className="Paper">
                        <div className="usageTitle">Academics</div>
                        <Grid container spacing={3}>
                            <Grid item md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.classes_count}</div>
                                <div>Classes</div>
                            </Grid>
                            <Grid item md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.sections_count}</div>
                                <div>Sections</div>
                            </Grid>
                            <Grid item md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.subjects_count}</div>
                                <div>Subjects</div>
                            </Grid>
                            <Grid item md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.attendance_count}</div>
                                <div>Attendance Records</div>
                            </Grid>
                            <Grid item md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.certificates_count}</div>
                                <div>Certificates</div>
                            </Grid>
                            <Grid item md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.transcripts_count}</div>
                                <div>Transcripts</div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            );    
            user.push(
                <Grid item  md={4} sm={6} lg={4} style={{fontSize:14}}>
                    <Paper className="Paper">
                        <div className="usageTitle">Users</div>
                        <Grid container spacing={3}>
                            <Grid item  md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.admins_count}</div>
                                <div>Admins</div>
                            </Grid>
                            <Grid item  md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.teachers_count}</div>
                                <div>Teachers</div>
                            </Grid>
                            <Grid item  md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.students_count}</div>
                                <div>Students</div>
                            </Grid>
                            <Grid item  md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.alumni_count}</div>
                                <div>Alumni</div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            );
            learning.push(
                <Grid item  md={4} sm={6} lg={4} style={{fontSize:14}}>
                    <Paper className="Paper">
                        <div className="usageTitle">LMS</div>
                        <Grid container spacing={3}>
                            <Grid item md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.flip_class_contents_count}</div>
                                <div>Flip Class Contents</div>
                            </Grid>
                            <Grid item md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.discussion_posts_count}</div>
                                <div>Discussion Posts</div>
                            </Grid>
                            <Grid item md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.live_lectures_count}</div>
                                <div>Live Lectures</div>
                            </Grid>
                            <Grid item md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.resources_count}</div>
                                <div>Resources</div>
                            </Grid>
                            <Grid item md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.assessments_count}</div>
                                <div>Assessments</div>
                            </Grid>
                            <Grid item md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.quizzes_count}</div>
                                <div>Quizzes</div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            );
            feesAndCRM.push(
                <Grid item  md={4} sm={6} lg={4} style={{fontSize:14}}>
                    <Paper className="Paper">
                        <div className="usageTitle">Fees & CRM</div>
                        <Grid container spacing={3}>
                            <Grid item md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.fees_count}</div>
                                <div>Fees</div>
                            </Grid>
                            <Grid item md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.invoices_count}</div>
                                <div>Invoices</div>
                            </Grid>
                            <Grid item md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.e_com_orders_count}</div>
                                <div>Orders</div>
                            </Grid>
                            <Grid item md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.forms_count}</div>
                                <div>Forms</div>
                            </Grid>
                            <Grid item md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.submissions_count}</div>
                                <div>Submissions</div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            );
            communication.push(
                <Grid item  md={4} sm={6} lg={4} style={{fontSize:14}}>
                    <Paper className="Paper">
                        <div className="usageTitle">Communication</div>
                        <Grid container spacing={3}>
                            <Grid item  md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.events_count}</div>
                                <div>Events</div>
                            </Grid>
                            <Grid item  md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.schedules_count}</div>
                                <div>Schedules</div>
                            </Grid>
                            <Grid item  md={4} sm={4} lg={4}>
                                <div className="usageBlock">{userDetails.notices_count}</div>
                                <div>Notices</div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            );
        }
        var tenantUrl="";
        let UserData;                                     
        if ( this.state.isLoading ) {
            UserData = <Loader type='block'/>;
        }else{
            tenantUrl = "https://"+this.state.userDetails.domain_name+".classe365.com";
            UserData= <div>
                        <Grid container spacing={3} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    {this.state.userDetails.inst_name}
                                </Typography>        
                            </Grid>                            
                        </Grid>
                        <div style={{background:'#fff',marginTop:15}}>
                            <AntTabs value={value} onChange={this.handleChange} aria-label="ant example">
                            <AntTab label="GENERAL" />
                            <AntTab label="USAGE" />
                            </AntTabs>
                            <Typography />
                        </div>
                        <div style={{background:'#fff'}}>
                            <TabPanel value={value} index={0}>
                                <div style={{fontSize:14}}>
                                    <Grid container spacing={3} lg={12}  >
                                        <Grid item xs={4} lg={2} md={2} sm={3} >
                                            <div>Tenant URL</div>
                                            <div>Institute Name</div> 
                                            <div>Institute Type</div> 
                                            <div>Student Size</div> 
                                            <div>Contact Person</div>
                                            <div>Email </div>
                                            <div>Phone</div>   
                                        </Grid>
                                        <Grid item xs={4} lg={4} md={4} sm={5}>
                                            <div>{tenantUrl}</div>
                                            <div>{(this.state.userDetails.inst_name == null) ? "Not Set":this.state.userDetails.inst_name}</div>
                                            <div>{(this.state.userDetails.inst_type == null || this.state.userDetails.inst_type == "" ) ? "Not Set":this.state.userDetails.inst_type}</div>
                                            <div>{(this.state.userDetails.inst_student_size == null) ? "Not Set":this.state.userDetails.inst_student_size}</div>
                                            <div>{this.state.userDetails.admin_name}</div>
                                            <div>{(this.state.userDetails.email == null) ? "Not Set":this.state.userDetails.email}</div>
                                            <div>{(this.state.userDetails.contact == null) ? "Not Set" : this.state.userDetails.contact}</div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div>
                                    {data_table}
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                {storage}
                                <Grid container spacing={3}>
                                    {academic}
                                    {user}
                                    {learning}
                                    {feesAndCRM}
                                    {communication}
                                </Grid>
                            </TabPanel>                            
                        </div>
                    </div>
        }
        return(
            <div>
               {UserData}
            </div>
        )
    }
}
export default withSnackbar(deletedCustomerView)