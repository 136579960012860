import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Login from './login';
import Admin from './admin_template';
import { SnackbarProvider} from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function App() {
	const notistackRef = React.createRef();
    const onClickDismiss = key => () => { 
		notistackRef.current.closeSnackbar(key);
    }
  return (
	<div>
    <SnackbarProvider
			ref={notistackRef}
			action={(key) => (
				<IconButton  onClick={onClickDismiss(key)} >
				<CloseIcon style={{color:'white',width:20,height:20}}></CloseIcon></IconButton>
			)}
			maxSnack={3}
			anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
			<Router>
				<Switch>
					<Route exact path="/">
						<Login/>
					</Route>
					<AdminRoute path="/admin/">
						<Admin />
					</AdminRoute>
				</Switch>
			</Router>	
		</SnackbarProvider>				
	</div>
  );
}

//A wrapper for <Route> that checks for private route
function AdminRoute({ children, ...rest }) {
  return (
	<Route
      {...rest}
      render={ ({ location }) =>
	  	// any authenticated user can access front end 
        ( localStorage.getItem('authenticated') === 'true' ) ? ( children ) : ( <Redirect to={{pathname: "/"}} /> )
      }
    />
  );
}
export default App;
