import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Loader from '../elements/loader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import { InputBase, Drawer, Dialog, DialogTitle, IconButton, DialogContent, Tooltip } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Config from '../config/index';
import { Link } from "react-router-dom";
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import CopyToClipboard from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon  from '@material-ui/icons/DeleteOutline';
import SetupIcon from '@material-ui/icons/SettingsApplicationsOutlined';


const axios = require('axios');
axios.defaults.headers.common['Authorization'] = localStorage.getItem('user_token');
const role = localStorage.getItem('role');
class new_users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Users: [],
            isLoading: true,
            isSaveInProgress: false,
            userDetails: "",
            params: {
                sort: {'created':'desc'},
                filter: {},
                page: 0
            },
            open: false,
            more_filters_open: false,
            total_Users: 50,
            records_per_page: 10,
            mOpen : false,
            tokenInstitute:'',
            vToken:'',
            tokenCreated:'',
            pcopied:false,
        };
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.loadUser = this.loadUser.bind(this);
        this.searchtitleInputRef = React.createRef();
        this.nameInputRef = React.createRef();
        this.organisationInputRef = React.createRef();
        this.emailInputRef = React.createRef();
        this.registerFromInputRef = React.createRef();
        this.registerToInputRef = React.createRef();
        this.contactInputRef = React.createRef();
        this.countryInputRef = React.createRef();
        this.subdomainInputRef = React.createRef();
    }
    componentDidMount() {
        this.loadUser(this.state.params);
    }
    loadUser = (params, preReset = false) => {
        if (preReset === true) {
            params.page = 0;
            this.setState({
                isLoading: true,
                Users: [],
                more_filters_open: false
            });
        }
        let self = this;
        axios.get(Config.apiBaseURL + '/new_users', { params: params })
            .then(response => {
                this.setState({
                    Users: response.data.new_users,
                    records_per_page: response.data.records_per_page,
                    total_Users: response.data.total_new_users,
                    params: params,
                });
            })
            .catch(function (error) {
                if (error.response && error.response.status === 500) {
                    self.props.enqueueSnackbar(error.response.data.errors.message, {
                        variant: 'error',
                    });
                } else if (error.response && error.response.status === 401) {
                    localStorage.setItem('logout', true);
                    window.location = '/';
                }
                else { // any other error 
                    self.props.enqueueSnackbar(error.message,{
                        variant: 'error',
                    });
                }
            })
            .then(function () {
                self.setState({
                    isLoading: false
                })
            });
    }
    detectEnter = (e) => {
        if (e.keyCode == 13) {
            this.triggerBasicFilters();
        }
    }
    triggerBasicFilters = (e) => {
        const title = this.searchtitleInputRef.current.value;
        let params = this.state.params;
        params.filter['search_keyword'] = (title !== '') ? title : undefined;
        params.filter['subdomain'] = (title !== '') ? title : undefined;
        this.loadUser(params, true);
    }
    handleSortChange = (sortAttr, sortDir) => event => {
        let params = this.state.params;
        params.sort = {};
        params.sort[sortAttr] = sortDir;
        this.loadUser(params);
    };
    handleFiltersToggle = () => this.setState({ more_filters_open: !this.state.more_filters_open });
    triggerMoreFilters = (e) => {
        e.preventDefault();

        let params = this.state.params;
        // const search_keyword = this.searchtitleInputRef.current.value;
        const name = this.nameInputRef.current.value;
        const email = this.emailInputRef.current.value;
        const organisation = this.organisationInputRef.current.value;
        const registerFrom = this.registerFromInputRef.current.value;
        const registerTo = this.registerToInputRef.current.value;
        const contact_no = this.contactInputRef.current.value
        const subDomain = this.subdomainInputRef.current.value;
        //   params.filter['search_keyword'] = ( search_keyword !== '' ) ? search_keyword : undefined;
        params.filter['search_keyword'] = (subDomain !== '') ? subDomain : undefined;
        params.filter['name'] = (name !== '') ? name : undefined;
        params.filter['organisation'] = (organisation !== '') ? organisation : undefined;
        params.filter['subdomain'] = (subDomain !== '') ? subDomain : undefined;
        params.filter['contact'] = (contact_no !== '') ? contact_no : undefined;
        params.filter['email'] = (email !== '') ? email : undefined;
        params.filter['created_from'] = (registerFrom !== '') ? registerFrom : undefined;
        params.filter['created_to'] = (registerTo !== '') ? registerTo : undefined;
        this.loadUser(params, true);
    }
    resetFilters = () => {
        let params = this.state.params;
        params.filter = {};
        this.searchtitleInputRef.current.value = null;
        this.nameInputRef.current.value = null;
        this.organisationInputRef.current.value = null;
        this.emailInputRef.current.value = null;
        this.contactInputRef.current.value = null;
        this.countryInputRef.current.value = null;
        this.subdomainInputRef.current.value = null;
        this.registerFromInputRef.current.value = null;
        this.registerToInputRef.current.value = null;
        this.loadUser(params, true);
    }
    handleChangePage = (event, newPage) => {
        let params = this.state.params;
        params.page = newPage;
        this.loadUser(params);
    };   
    handleDeleteAccount = (id)=>{
        if(window.confirm('Are you sure you wish to delete it?')){
            let self = this;
            self.setState({
                isLoading: true
            })
           axios.delete(Config.apiBaseURL + '/incomplete_reg/delete/'+id)
            .then(response => {
                self.props.enqueueSnackbar(response.data.message, {
                    variant: 'success',
                });
                this.loadUser(this.state.params);                
            })
            .catch(function (error) {
                if (error.response && error.response.status === 400) {
                    self.props.enqueueSnackbar(error.response.data.message, {
                        variant: 'error',
                    });
                } else if (error.response && error.response.status === 401) {
                    localStorage.setItem('logout', true);
                    window.location = '/';
                }
                else { // any other error 
                    self.props.enqueueSnackbar('Error! While deleting data.', {
                        variant: 'error',
                    });
                }
            })
            .then(function () {
                self.setState({
                    isLoading: false
                })
            });
        }
    }
    handleSetupAccount = (id)=>{
        if(window.confirm('Are you sure you wish to activate this account?')){
            let self = this;
            self.setState({
                isLoading: true
            })
           axios.post(Config.apiBaseURL + '/setup_account/',{'id':id})
            .then(response => {
                if(response.data.success){
                    self.props.enqueueSnackbar(response.data.message, {
                        variant: 'success',
                    });
                    this.loadUser(this.state.params);
                }else{
                    self.props.enqueueSnackbar(response.data.message, {
                        variant: 'error',
                    });
                }                
            })
            .catch(function (error) {
                if (error.response && error.response.status === 400) {
                    self.props.enqueueSnackbar(error.response.data.message, {
                        variant: 'error',
                    });
                } else if (error.response && error.response.status === 401) {
                    localStorage.setItem('logout', true);
                    window.location = '/';
                }
                else { // any other error 
                    self.props.enqueueSnackbar('Error! While activating this account.', {
                        variant: 'error',
                    });
                }
            })
            .then(function () {
                self.setState({
                    isLoading: false
                })
            });
        }
    }
    modelOpen(tenant){
        const options = {
            dateStyle: "long",
             timeStyle: "short", 
          };
        const date = new Date(tenant.token_created * 1000);
        this.setState({
            mOpen:true,
            vToken:tenant.token,
            tokenCreated:date.toLocaleString('en-US',options),
            tokenInstitute:tenant.organisation
        });
    }    
    ModelhandleClose = () => {
        this.setState({
            mOpen:false,
            pcopied:false, 
            vToken:'',
            tokenCreated:'',
            tokenInstitute:''          
        })  
    };
    render() {
        let data_table, basicFilter;
        if (this.state.isLoading) {
            data_table = <Loader type='block' />;
        } else {
            let userData = [];
            for (const i in this.state.Users) {
                var Date = moment(this.state.Users[i].created).format("DD MMM YYYY");
                userData.push(
                    <TableRow>
                        <TableCell><div className="TableWordBreak">{this.state.Users[i].organisation}</div></TableCell>
                        <TableCell><div className="TableWordBreak">{this.state.Users[i].subdomain}</div></TableCell>
                        <TableCell>{this.state.Users[i].name}</TableCell>
                        <TableCell><div className="TableWordBreak">{this.state.Users[i].email}</div></TableCell>
                        <TableCell>{this.state.Users[i].contact}</TableCell>
                        <TableCell>{this.state.Users[i].country_name}</TableCell>
                        <TableCell>{this.state.Users[i].token_created ? <Tooltip title="View Verification Token"><Link to="#" onClick={this.modelOpen.bind(this, this.state.Users[i])} className="actionIcon">XXX-XXX</Link></Tooltip> : '-' }</TableCell>
                        <TableCell>{Date}</TableCell>
                        {(Config.allowUser.indexOf(role) > -1) ? <TableCell align='center'>
                        <Tooltip title="Account Setup"><Link to="#" className=" Link actionIcon" onClick={()=>{this.handleSetupAccount(this.state.Users[i].id)}} style={{marginRight:"5px"}}><SetupIcon/></Link></Tooltip>
                            <Tooltip title="Delete User"><Link to="#" className=" Link actionIcon" onClick={()=>{this.handleDeleteAccount(this.state.Users[i].id)}}><DeleteIcon/></Link></Tooltip>
                        </TableCell> : ''}
                    </TableRow>
                );
            }
            basicFilter = <div className="adminSearchBox" style={{marginBottom:"25px"}}>
                <div>
                    <SearchIcon className="searchIcon" onClick={this.triggerBasicFilters} />
                </div>
                <div>
                    <InputBase
                        style={{ width: "calc(100% - 10px)" }}
                        placeholder="Search Users"
                        inputProps={{
                            ref: this.searchtitleInputRef
                        }}
                        onKeyUp={this.detectEnter}
                        defaultValue={(this.state.params.filter['search_keyword']) ? this.state.params.filter['search_keyword'] : null}
                    />
                </div>
                <div style={{ width: 100 }}>
                    <div onClick={this.handleFiltersToggle} className="moreFilterLabel" >More Filters</div>
                </div>
            </div>
            data_table = <div>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={(this.state.params.sort.organisation) ? true : false}
                                        direction={(this.state.params.sort.organisation) ? this.state.params.sort.organisation.toLowerCase() : "asc"}
                                        onClick={this.handleSortChange('organisation', (this.state.params.sort.organisation && this.state.params.sort.organisation.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                        Institute Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={(this.state.params.sort.subdomain) ? true : false}
                                        direction={(this.state.params.sort.subdomain) ? this.state.params.sort.subdomain.toLowerCase() : "asc"}
                                        onClick={this.handleSortChange('subdomain', (this.state.params.sort.subdomain && this.state.params.sort.subdomain.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                        Subdomain
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={(this.state.params.sort.name) ? true : false}
                                        direction={(this.state.params.sort.name) ? this.state.params.sort.name.toLowerCase() : "asc"}
                                        onClick={this.handleSortChange('name', (this.state.params.sort.name) === "asc" ? "desc" : "asc")}>
                                        Contact Person
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={(this.state.params.sort.email) ? true : false}
                                        direction={(this.state.params.sort.email) ? this.state.params.sort.email.toLowerCase() : "asc"}
                                        onClick={this.handleSortChange('email', (this.state.params.sort.email && this.state.params.sort.email.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                        Email
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={(this.state.params.sort.contact) ? true : false}
                                        direction={(this.state.params.sort.contact) ? this.state.params.sort.contact.toLowerCase() : "asc"}
                                        onClick={this.handleSortChange('contact', (this.state.params.sort.contact) === "asc" ? "desc" : "asc")}>
                                        Phone
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    Country
                                </TableCell>
                                <TableCell>
                                    Token
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={(this.state.params.sort.created) ? true : false}
                                        direction={(this.state.params.sort.created) ? this.state.params.sort.created.toLowerCase() : "asc"}
                                        onClick={this.handleSortChange('created', (this.state.params.sort.created && this.state.params.sort.created.toLowerCase()) === "asc" ? "desc" : "asc")}>
                                        Registered On
                                    </TableSortLabel>
                                </TableCell>
                                {(Config.allowUser.indexOf(role) > -1)?
                                    <TableCell align='center'>Action</TableCell>
                                :''}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userData}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    count={this.state.total_Users}
                                    rowsPerPage={this.state.records_per_page}
                                    page={this.state.params.page ? this.state.params.page : 0}
                                    onChangePage={this.handleChangePage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </Paper>
            </div>
        }
        let more_filters = <Drawer
            anchor="right"
            open={this.state.more_filters_open}
            width={500}>
            <div>
                <div className="moreFilterTitle">Filters</div>
                <CancelIcon onClick={this.handleFiltersToggle} className="cancelIcon" />
            </div>
            <div style={{ minWidth: 400 }}>
                <form method="POST" onSubmit={this.triggerMoreFilters}
                    style={{ margin: 30, marginTop: 20, maxWidth: 400 }}>
                    <TextField style={{ marginTop: 20 }}
                        inputProps={{
                            ref: this.nameInputRef
                        }}
                        id="name"
                        label="Name"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        defaultValue={(this.state.params.filter['name']) ? this.state.params.filter['name'] : null}
                    />
                    <TextField style={{ marginTop: 20 }}
                        inputProps={{
                            ref: this.subdomainInputRef
                        }}
                        id="subdomain"
                        label="Subdomain"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        defaultValue={(this.state.params.filter['subdomain']) ? this.state.params.filter['subdomain'] : null}
                    />
                    <TextField style={{ marginTop: 20 }}
                        inputProps={{
                            ref: this.organisationInputRef
                        }}
                        id="organisation"
                        label="Organisation"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        defaultValue={(this.state.params.filter['organisation']) ? this.state.params.filter['organisation'] : null}
                    />
                    <TextField style={{ marginTop: 20 }}
                        inputProps={{
                            ref: this.emailInputRef
                        }}
                        id="email"
                        label="Email"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        defaultValue={(this.state.params.filter['email']) ? this.state.params.filter['email'] : null}
                    />
                    <TextField style={{ marginTop: 20 }}
                        inputProps={{
                            ref: this.contactInputRef
                        }}
                        id="phone"
                        label="Phone"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        defaultValue={(this.state.params.filter['contact']) ? this.state.params.filter['contact'] : null}
                    />
                    <TextField style={{ marginTop: 20 }}
                        inputProps={{
                            ref: this.countryInputRef
                        }}
                        id="country"
                        label="Country"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        defaultValue={(this.state.params.filter['country_name']) ? this.state.params.filter['country_name'] : null}
                    />
                    <Grid container spacing={3} lg={12}>
                        <Grid item xs={6}>
                            <TextField
                                inputProps={{
                                    ref: this.registerFromInputRef
                                }}
                                style={{ marginTop: 20 }}
                                id="date"
                                label="Register Date (From)"
                                type="date"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                defaultValue={(this.state.params.filter['created_from']) ? this.state.params.filter['created_from'] : null}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                inputProps={{
                                    ref: this.registerToInputRef
                                }}
                                style={{ marginTop: 20 }}
                                id="date"
                                label="Register Date (To)"
                                type="date"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                defaultValue={(this.state.params.filter['created_to']) ? this.state.params.filter['created_to'] : null}
                            />
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: 20, marginBottom: 20 }} >
                        <Button variant="contained" size="medium" color="primary" type="submit">
                            Apply
                        </Button>&nbsp;&nbsp;
                        <Button variant="contained" size="medium" onClick={this.resetFilters} type="submit" >
                            Reset
                        </Button>
                    </div>
                </form>
            </div>
        </Drawer>;
        let tokenCopyColor="";
        if(this.state.pcopied == true){   
            tokenCopyColor="green";
        }
        let OpenDialog = <Dialog className="model" onClose={this.ModelhandleClose} aria-labelledby="customized-dialog-title" open={this.state.mOpen}>
            <DialogTitle id="customized-dialog-title">
                Verification Token
                <IconButton edge="start" color="inherit" className="dialogCloseIcon" onClick={this.ModelhandleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <div style={{ fontSize: 14 }}>
                    <Grid container spacing={3} alignItems="center" style={{ color: "#707070", lineHeight: "30px" }}>
                        <Grid item >                            
                            <div style={{ display: "flex" }}>
                                <div style={{ minWidth: 150 }}>Institute Name</div>
                                <div>{this.state.tokenInstitute}</div>
                            </div>
                            <div style={{ display: "flex" }}>
                                <div style={{ minWidth: 150 }}>Verification Token</div>
                                <div style={{display:"inline-flex"}}>
                                    <div style={{marginRight:"10px"}}>
                                        {this.state.vToken}
                                    </div>
                                    <div>
                                        <CopyToClipboard text={this.state.vToken} onCopy={() => this.setState({pcopied: true})}>
                                            <Link to="#"><FileCopyIcon style={{fontSize:"18px",marginTop:5,color:tokenCopyColor}}></FileCopyIcon></Link>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex" }}>
                                <div style={{ minWidth: 150 }}>Created At</div>
                                <div>{this.state.tokenCreated}</div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
        </Dialog>
        return (
            <div>
                <Grid container spacing={3} lg={12}>
                    <Grid item xs={6}>
                        <Typography variant="h5" gutterBottom >
                            Verification Pending
                        </Typography>
                    </Grid>
                </Grid>
                {more_filters}
                {basicFilter}
                {data_table}
                {OpenDialog}
            </div>
        );
    }
}

export default withSnackbar(new_users);
